<template>
  <el-select  v-bind="$attrs" v-on="$listeners" clearable filterable>
    <el-option v-for="item in items" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>


</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  inheritAttrs: false,
  name: "SchoolSelect",
  async beforeMount() {

   await this.list()


  },

  data() {
    return {
      items: [],
    }
  },
  methods: {
    async list() {
      let tmp = this.$store.state.userInfo.user?.organizationVO?.schools
      if(tmp.length){
        let res = (await organizationApi.getById({id:this.$store.state.userInfo.user.organizationId}))
        this.items = (await organizationApi.list({id: this._.chain(res.schools).join(",").value()})).data
      }
    },

    getItems(){
      return this.items
    }

  },

}
</script>

<style scoped>

</style>
