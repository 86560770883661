import request from '@/utils/request'

export function create(data) {

  return request({
    url: '/cronJob/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/cronJob/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/cronJob/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/cronJob/deleteByIds',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/cronJob/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/cronJob/getByIds',
    method: 'GET',
    params})
}

   

export function list(params) {

  return request({
    url: '/cronJob/list',
    method: 'GET',
    params})
}

   

export function update(data) {

  return request({
    url: '/cronJob/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/cronJob/update/batch',
    method: 'POST',
    data})
}

   