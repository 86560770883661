<template>

  <el-dialog @close="close" :visible="status === 'add' || status==='edit'" :title="title">

    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="机构">
        <organization-select v-model="form.organizationId"></organization-select>
      </el-form-item>
      <el-form-item prop="username" label="账号">
        <el-input v-model="form.username"></el-input>
      </el-form-item>

      <el-form-item prop="name" label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item prop="password" label="密码">
        <el-input v-model="form.password"></el-input>
      </el-form-item>


      <el-form-item prop="phone" label="手机号">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>


      <el-form-item prop="email" label="邮箱">
        <el-input v-model="form.email"></el-input>
      </el-form-item>


    </el-form>


    <template slot="footer">

      <div>

        <el-button type="primary" @click="submit">确定</el-button>
        <el-button type="primary" @click="close">取消</el-button>

      </div>


    </template>


  </el-dialog>


</template>

<script>
import * as userApi from "@/api/gen/userApi"
import OrganizationSelect from "@/components/OrganizationSelect";

export default {
  name: "Edit",
  components: {OrganizationSelect},
  props: {
    status: {
      type: String
    },

    row: {

      type: Object
    }
  },
  beforeMount() {
    if (this.row.id) {


      this.form = {...this.row};
    }

  },

  methods: {
    close() {
      this.$emit("close");
    },
    async submit() {
      let result = await this.$refs['form'].validate();
      console.log(result)


      if (this.status === 'add') {
        let resp = await userApi.create({...this.form});
        this.close();
      } else if (this.status === 'edit') {
        let resp = await userApi.update({...this.form});
        this.close();
      }


    }
  },
  computed: {
    title() {
      if (this.status === 'add') {
        return "添加";
      } else if (this.status === 'edit') {
        return "编辑";
      }
    }
  },
  data() {
    return {
      form: {
        "id": "",
        "name": "",
        "phone": "",
        "email": "",
        "username": "",
        "organizationId": this.$store.getters['user/organizationId']

      },
      rules: {

        "name": [this.$ruleBulder().required().build()],
        "password": [this.$ruleBulder().required().build()],
        "phone": [this.$ruleBulder().phone().build()],
        "email": [this.$ruleBulder().email().build()],
        "username": [this.$ruleBulder().required().build()],


      }


    }
  }
}
</script>

<style scoped>

</style>
