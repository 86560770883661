<template>
  <el-select v-model="value" v-on="$listeners" v-bind="$attrs" clearable style="width: 100%">
    <el-option v-for="(item,index) in items" :key="index" :label="item" :value="item"></el-option>
  </el-select>


</template>

<script>

import * as dataMenu from "@/assets/js/data"
export default {
  inheritAttrs: false,
  name: "classRoomNoSelect",
  async beforeMount() {
    this.items = Object.values(dataMenu.GenderEnum)
  },

  data() {
    return {
      items: [],
      value:''
    }
  },
};
</script>

<style scoped>

</style>
