let context = require.context("/src/components/myUI", true, /.+\.vue$/)
import Vue from 'vue'
context.keys().forEach(k => {

    let module = context(k).default

    console.log(module)

    Vue.component(`${module.name}`,module)
    // Vue.use(module.default)
})

export default {}
