<template>
  <div>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add'? '添加':'修改'"
        :visible="true"
        center
        width="40%"
    >

      <el-form ref="form" align="center" label-position="left" label-width="6em">
        <div v-for="(item,index) in form" :key="index">
          <el-form-item label="年级">
            <el-input v-model="item.name"></el-input>
          </el-form-item>
          <el-form-item label="班级">
            <el-input v-model="item.classNames"></el-input>
          </el-form-item>
          <div style="display: flex; justify-content: flex-end">

            <el-button type="text" @click="deleteGrage(index)" style="color: red">删除</el-button>
          </div>
          <hr>
        </div>

        <template>
          <div style="display: flex; justify-content: flex-end">
            <el-button type="primary" @click="addGrage">+</el-button>
          </div>
        </template>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update('form')">确 认</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi";


export default {
  name: "gradeClass",
  props: {
    row: {}
  },
  data() {
    return {
      form: [],
    }
  },

  mounted() {
   if(this.row.gradeInfos){
     for (let item of this.row.gradeInfos) {
       let tmp = {
         name: item.name,
         classNames: item.classNames.join(","),
       };
       this.form.push(tmp);
     }
   }


  },
  computed: {
    status() {
      return this.row.id ? "add" : "edit"
    },


  },

  methods: {
    close() {
      this.$emit("close")
    },
    async update() {
      if (this.status === 'add') {
        //添加
        await this.add()
      } else {
        //修改
        await this.edit()
      }
    },

    async add() {
      let datas = this._.cloneDeep(this.form)
      for (let item of datas) {
        item.classNames = item.classNames.split(',')
      }
      await organizationApi.update({gradeInfos: datas, id: this.row.id})
      this.$message.success("添加成功")
      this.$emit("update")
      this.$emit("close");

    },

    addGrage() {
      this.form.push({
        name: "",
        classNames: ""
      })
    },

    async deleteGrage(index) {
      let datas = this._.cloneDeep(this.form)
      datas.splice(index, 1)
      await organizationApi.update({gradeInfos: datas, id: this.row.id})
    }


  }
}
</script>

<style scoped>

/deep/ .el-dialog--center .el-dialog__body {
  padding: 25px 45px 25px 30px !important;
}


/deep/ .el-form-item__label {

  text-align: justify !important;

}
</style>