import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
/**
 * @param {*} fileName
 * @param {string} id
 */


export default {


    install(Vue) {

        Vue.use(ElementUI);

    }

}