import request from '@/utils/request'

export function create(data) {

  return request({
    url: '/organization/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/organization/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/organization/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/organization/deleteByIds',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/organization/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/organization/getByIds',
    method: 'GET',
    params})
}

   

export function list(params) {
  if(!params){
    params ={pageSize:10000}
  }
  return request({
    url: '/organization/list2',
    method: 'POST',
    data:params})
  // return request({
  //   url: '/organization/list',
  //   method: 'GET',
  //   params})
}

   

export function update(data) {

  return request({
    url: '/organization/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/organization/update/batch',
    method: 'POST',
    data})
}

   