<!--常见病汇总-->
<template>
  <div>
    <el-card style="margin: 20px;">
      <el-form ref="form" size="small" label-width="70px">
        <el-form-item label="学校：">
          <ZxxSchool-select ref="schools" v-model="form.schoolId"></ZxxSchool-select>
        </el-form-item>
        <el-form-item label="年度：">
          <year-select v-model="form.year"></year-select>
        </el-form-item>
        <el-form-item label="统计类型：" label-width="100px">
          <statistical-type-select v-model="form.groupBy" :clearable="false"  @updateValue="search"></statistical-type-select>
        </el-form-item>



         <div style="margin-left:auto ">
           <el-button size="small" v-show="$hasPermission('commonDisease.list')" type="primary" @click="search">查 询</el-button>
           <el-button size="small" v-show="$hasPermission('commonDisease.export')" type="primary" @click="exportExcel">导 出</el-button>
           <el-button size="small" v-show="$hasPermission('commonDisease.print')" type="primary" @click="printTable">打印当前页</el-button>
         </div>

      </el-form>

    </el-card>

    <el-card>
      <div style="display: flex; flex-wrap: wrap; justify-content: space-between; ">
        <echarts :option="option1" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
        <echarts :option="option2" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
        <echarts :option="option3" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
        <echarts :option="option4" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
      </div>
    </el-card>

    <el-card style=" margin: 20px">
      <el-table
          id="exportTab"
          :data="formList2"
          v-loading="loading"
          border
          style="width: 100%"
      >
        <el-table-column
            :key="type"
            :label="type"
            prop="_id"
        >
          <template slot-scope="scope">
            <template v-if="form.groupBy === '$sex'">
              <div v-if="scope.row._id === 0">男</div>
              <div v-if="scope.row._id === 1">女</div>
              <div v-if="scope.row._id === '合计'">合计</div>
            </template>
            <template v-else>
              <div>{{ scope.row._id }}</div>
            </template>
          </template>

        </el-table-column>
        <el-table-column
            label="视力不良"
        >
          <el-table-column
              label="受检人数"
              prop="r073Count"
          >
          </el-table-column>
          <el-table-column
              label="检出人数"
              prop="r073Sum"
          >
          </el-table-column>
          <el-table-column
              label="检出率(%)"
              prop="r073Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r073Rate |percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="恒牙龋患"
        >
          <el-table-column
              label="受检人数"
              prop="r015Count"
          >
          </el-table-column>
          <el-table-column
              label="患病人数"
              prop="r015Sum"
          >
          </el-table-column>
          <el-table-column
              label="龋患率(%)"
              prop="r015Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r015Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="龋均"
          >
            <template slot-scope="scope">
              <div>{{ (scope.row.r047Sum / scope.row.total) | percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="营养状况"
        >
          <el-table-column
              label="受检人数"
              prop="r003Count"

          >
          </el-table-column>
          <el-table-column
              label="生长迟缓人数"
              prop="r020Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r020Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r020Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="轻度消瘦人数"
              prop="r021Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r021Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r021Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="中重度消瘦人数"
              prop="r022Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r022Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r022Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="超重人数"
              prop="r004Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r004Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r004Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="肥胖人数"
              prop="r005Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r005Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r005Rate |percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="血压偏高"

        >
          <el-table-column
              label="受检人数"
              prop="r028Count"
          >
          </el-table-column>
          <el-table-column
              label="患病人数"
              prop="r028Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r028Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r028Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>


      </el-table>


    </el-card>
  </div>
</template>

<script>

import ZxxSchoolSelect from "@/components/ZxxSchoolSelect"
import gradeSelect from "@/components/gradeSelect"
import classRoomNoSelect from "@/components/classRoomNoSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"

import echarts from "@/components/Echarts";
import * as scriptApi from "@/api/gen/scriptApi"

export default {
  name: "commonDisease",
  components: {
    ZxxSchoolSelect,
    statisticalTypeSelect,
    gradeSelect,
    classRoomNoSelect,
    sexSelect,
    yearSelect,
    echarts

  },
  data() {
    return {
      form: {
        id: "",
        schoolId: "",
        schoolType: "",
        idNo: "",
        grade: "",
        classRoomNo: "",
        name: "",
        birthDate: "",
        age: "",
        sex: "",
        studentId: "",
        contact: "",
        nationalityCode: "",
        cxType: "",
        checkDate: "",
        year: "",
        result: "",
        advice: "",
        groupBy: ""
      },

      name: '',
      scriptName: '',
      formList: [],


      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      model: "",
      options: "",
      loading:false,

    }
  },
  computed: {
    type() {
      if (this.form.groupBy === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupBy === '$sex') {
        return '性别'
      }
      if (this.form.groupBy === '$grade') {
        return '年级'
      }

    },
    formList2(){
      this.formList.push({
        "_id":'合计',
        r073Count:this._.sumBy(this.formList,'r073Count'),
        r073Sum:this._.sumBy(this.formList,'r073Sum'),
        r015Count:this._.sumBy(this.formList,'r015Count'),
        r015Sum:this._.sumBy(this.formList,'r015Sum'),
        r003Sum:this._.sumBy(this.formList,'r003Sum'),
        r003Count:this._.sumBy(this.formList,'r003Count'),
        r020Sum:this._.sumBy(this.formList,'r020Sum'),
        r021Sum:this._.sumBy(this.formList,'r021Sum'),
        r022Sum:this._.sumBy(this.formList,'r022Sum'),
        r004Sum:this._.sumBy(this.formList,'r004Sum'),
        r005Sum:this._.sumBy(this.formList,'r005Sum'),
        r028Count:this._.sumBy(this.formList,'r028Count'),
        r028Sum:this._.sumBy(this.formList,'r028Sum'),
      })
      return this.formList
    },
    option1() {

      let x = this._.chain(this.formList).map(x => x._id).value();
      let y = this._.chain(this.formList).map(x => x.r073Sum).value();

      return {
        title: {
          show: true,
          text: "视力不良"
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    },

    option2() {
      let x = this._.chain(this.formList).map(x => x._id).value();
      let y = this._.chain(this.formList).map(x => x.r015Sum).value();
      return {
        title: {
          show: true,
          text: '恒牙龋患'
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    },

    option3() {
      let x = this._.chain(this.formList).map(x => x._id).value();
      let y = this._.chain(this.formList).map(x => x.r003Sum).value();
      return {

        title: {
          show: true,
          text: '营养状况'
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    },

    option4() {

      let x = this._.chain(this.formList).map(x => x._id).value();
      let y = this._.chain(this.formList).map(x => x.r028Sum).value();
      return {
        title: {
          show: true,
          text: '血压偏高'
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    }

  },
  mounted() {


  },
  methods: {

    async search() {
      if(!this.form.schoolId){
        this.$message.warning('请选择学校')
        return
      }
      await this.scriptList()

    },


    async scriptList() {
      this.loading = true
      let clone = this._.cloneDeep(this.form)
      if (!clone.schoolId) {
        clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
      }

      this.formList = await scriptApi.execute({name: '全部统计.groovy', params: clone})

      this.formList = this._.chain(this.formList).sortBy(x => parseFloat(x._id)).value()
      console.log(this.formList);
      this.$sortByGrade(this.formList)
      console.log(this.formList);

      this.loading = false


    },


    exportExcel() {
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    },

    printTable() {

      this.$print('#exportTab')
    },

  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
  flex-wrap: wrap;

}

/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
