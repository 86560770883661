export default {


    methods:{


        setDefaultValue(value){

            if (!this.$attrs.value) {
                this.$attrs.value = value;
                this.$emit("input", this.$attrs.value)


            }

        }


    }

}