<template>
  <div>
    <el-card style=" margin: 20px 20px">
      <el-form ref="form" label-width="auto" inline>
        <el-form-item label="学校名称">
          <ZxxSchoolSelect v-model="form.schoolId" clearable></ZxxSchoolSelect>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contact" clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
<!--          <el-button type="primary" @click="list">刷新</el-button>-->
          <el-button v-show="$hasPermission('schoolManagement.create')" type="primary" @click="editDialog">+ 新增
          </el-button>

        </el-form-item>
      </el-form>

      <div style="position: relative">
        <el-table
            :data="formList"
            :header-cell-style="{background:'#fafafa'}"
            size="mini"
        >
          <el-table-column
              label="学校名称"
              prop="name"

          >
          </el-table-column>
          <el-table-column
              label="学校地址"
              prop="address"

              width="300px"
          >
          </el-table-column>
          <el-table-column
              label="学校类型"
              prop="type"

          >
            <template slot-scope="scope">
              <div>{{ menu.OrganizationTypeEnum[scope.row.type] }}</div>
            </template>
          </el-table-column>


          <el-table-column
              label="联系电话"
              prop="phone"

          >
          </el-table-column>
          <el-table-column
              label="联系人"
              prop="contact"

          >
          </el-table-column>

          <el-table-column
              width="250px"

              label="操作">
            <template slot-scope="scope">

              <el-button v-if="scope.row.type===3 || scope.row.type===4"
                         v-show="$hasPermission('schoolManagement.gradeClass')"
                         size="mini"
                         type="text" @click="gradeClassDialog(scope.row)">关联年级班级
              </el-button>
              <el-button v-show="$hasPermission('schoolManagement.update')" size="mini" type="text"
                         @click="updateDialog(scope.row)">修改
              </el-button>
              <el-button v-show="$hasPermission('schoolManagement.delete')" size="mini" type="text" style="color: red"
                         @click="remove(scope.row.id)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-card>
    <edit v-if="isEditShow" :orgList="formList" :row="current" @close="() => this.isEditShow = !this.isEditShow"
          @update="list()"></edit>

    <grade-class v-if="isGradeClass" :row="gradeClassCurrent"
                 @close="() => this.isGradeClass = !this.isGradeClass" @update="list()"></grade-class>
  </div>
</template>

<script>
import edit from "./edit"
import Pagination from '@/components/Pagination'
import gradeClass from "./gradeClass"
import ZxxSchoolSelect from '@/components/ZxxSchoolSelect'
import * as organizationApi from "@/api/gen/organizationApi"
import * as dataMenu from "@/assets/js/data"

export default {
  components: {
    edit,
    Pagination,
    gradeClass,
    ZxxSchoolSelect
  },
  name: "schoolManagement",
  data() {
    return {
      organizationApi,
      menu: dataMenu,
      form: {
        name: "",
        id: '',
        schoolId: '',
        contact: '',
        pageNo: 1,
        pageSize: 10
      },
      formList: [],
      schoolName: [],
      total: 0,
      isEditShow: false,
      isRelevance: false,
      isGradeClass: false,
      current: {},
      gradeClassCurrent: {}
    }
  },

  watch: {},

  computed: {},
  created() {
    this.list()
    this.handList = this._.debounce(this.list, 1000)
  },
  methods: {
    editDialog() {
      this.isEditShow = true
      this.current = []
    },
    updateDialog(data) {
      this.isEditShow = true
      this.current = data
    },
    schloolDialogShow(data) {
      this.isRelevance = !this.isRelevance
      this.current = data
    },
    gradeClassDialog(data) {
      console.log(data)
      this.isGradeClass = true
      this.gradeClassCurrent = data
    },


    //列表 搜索
    async list() {
      let tmp = await organizationApi.getById({id: this.$store.getters.organizationId})
      this.form.id = tmp?.schools?.join(',')
      if (this.form.id) {
        if (this.$store.getters.isSuperAdmin) {
          const res = (await organizationApi.list({pageSize: 100000}));
          this.formList = res.data.filter(item => item.type === 3)
        } else {

          const res = (await organizationApi.list({
            organizationId: this.$store.getters.organizationId,
            pageSize: 100000
          }));
          this.formList = res.data.filter(item => item.type === 3)
        }


      } else {

        this.formList = [];


      }
    },
    //删除
    async remove(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await organizationApi.deleteById({id: id})
        this.$message.success('删除成功')
        await this.list()
      }).catch(() => {
      })


    },
    search() {
      if (!this.form.schoolId && !this.form.contact) {
        this.list()
      } else {
        this.formList = this.formList.filter(item => {
          if (this.form.schoolId) {
            if (item.id === this.form.schoolId) return true
          } else if (this.form.contact) {
            if (item.contact === this.form.contact) return true
          } else {
            return  []
          }

        })
      }


    }

  },


}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
}

/*/deep/ .el-table .cell{*/
/*  display: flex;*/
/*}*/


</style>
