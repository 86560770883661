<template>



  <el-menu-item v-if="children===undefined" :index=path >
    <template>
      <i :class="icon"></i>
      <span>{{name}}</span>
    </template>
  </el-menu-item>


  <el-submenu v-else-if="children.length>0" :index="path">
    <template slot="title">
      <i :class="icon"></i>
      <span slot="title">{{name}}</span>
    </template>
    <menu-item v-for="item in children"  :key="item.path" :path="item.path" :icon="item.icon" :name="item.name" :children="item.children"></menu-item>
  </el-submenu>

  <div v-else></div>


</template>

<script>
export default {
  name: "MenuItem",

  props:[
    'path',
    'name',
    'icon',
    'children',
  ],
  data() {

    return {}
  }
}
</script>

<style scoped>
i{
  margin-right: 10px;
}
</style>
