<template>
  <el-select :value="myValue"  v-on="$listeners" v-bind="$attrs"  style="width: 100%">
    <el-option  v-for="(item,index) in items" :key="index" :label="item.label" :value="item.value"></el-option>
  </el-select>


</template>

<script>
import SetDefaultValue from "@/mixins/SetDefaultValue";
export default {
  inheritAttrs: false,
  name: "statisticalTypeSelect",
  mixins: [SetDefaultValue],
  async beforeMount() {


    this.items = [{'label':'按年龄统计', "value":"$age"},{"label":"按年级统计", "value":"$grade"},{"label":"按性别统计","value":"$sex"}]
    this.setDefaultValue("$age")

  },

  data() {
    return {
      items: [],

    }
  },


  props:{
    value:{}
  },
  computed: {
    myValue() {
      return this.value
    }
  },

  // watch:{
  //   myValue:{
  //     deep:true,
  //     handler(n){
  //       if(n){
  //         this.$emit('updateValue')
  //       }
  //     }
  //   }
  //
  // }

}
</script>

<style scoped>

</style>
