import Vue from "vue";
import RuleBuilder from "@/rules";
export default {

    install(Vue) {

        Vue.prototype.$ruleBulder = RuleBuilder
    }

}
