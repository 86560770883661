export default  {
    schoolId: '',
    checkItemStatus: "",
    schoolType: '',
    idNo: '',
    grade: "",
    classRoomNo: "",
    name: "",
    birthDate: "",
    age: "",
    sex: '0',
    studentId: "",
    contact: "",
    nationalityCode: '01',
    cxType: '0',
    checkDate: "",
    year: "",
    result: "",
    advice: "",
};
