import _ from 'lodash'

export function buildTree(list, config) {

    let {parentIdFn, isRootFn, idFn, setChildrenFn, setHasChildrenFn} = config || {}


    if (!idFn) {
        idFn = v => v.id

    }

    if (!setChildrenFn) {
        setChildrenFn = (v, children) => v.children = children

    }

    if (!setHasChildrenFn) {
        setHasChildrenFn = (v, hasChildren) => {
        }

    }

    if (!parentIdFn) {

        parentIdFn = (v) => v.parentId

    }

    if (!isRootFn) {
        isRootFn = (v) => _.isEmpty(parentIdFn(v))
    }
    let clone = JSON.parse(JSON.stringify(list));

    function doBuildTree(root) {
        if (!root) {
            return root
        }
        let children = clone.filter(x => parentIdFn(x) === idFn(root)).map(doBuildTree)

        setChildrenFn(root, children)
        setHasChildrenFn(root, !(_.isEmpty(children)))
        return root

    }

    return _.chain(clone).filter(isRootFn).map(doBuildTree).value()

}

export function dfs(data, fn) {

    // data 是数组的情况
    if (_.isArray(data)) {

        data.forEach(x => dfs(x, fn));
        // data 是节点的情况
    } else {
        fn(data)

        dfs(data.children || [], fn);

    }


}


export function dfs2(data, fn) {

    // data 是数组的情况
    if (_.isArray(data)) {

        return data.map(x => dfs2(x, fn)).filter(item => !!item);
        // data 是节点的情况
    } else {
        let newData = fn(data);

        if (newData && newData.children) {

           newData.children =  dfs2(newData.children || [], fn);
        }
        return newData;

    }


}

export default {

    install(Vue) {

        Vue.prototype.$buildTree = buildTree
        Vue.prototype.$dfs = dfs

    }

}
