<template>
  <div>

    <el-card style=" margin: 20px 20px">

      <el-form :model="form"  label-width="100px">

        <el-form-item label="单位：">
          <base-select v-model="form.organizationId" :search-fn="organizationApi.list"  disabled style="width: 300px;"></base-select>
        </el-form-item>


        <el-form-item label="公章：">
            <el-upload
                class="avatar-uploader"
                :action="apiPath"
                ref="upload" 
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </el-form-item>

        <el-form-item label="提示：">
            <span style="color:red">请上传透明底色PNG格式的公章图案</span>
        </el-form-item>

        <el-button type="primary" style="margin-left: 50px;" @click="handleSave">保存</el-button>
        <el-button type="danger" style="margin-left: 50px;" @click="handleDelete">删除</el-button>
      </el-form>
      

    </el-card>
  </div>
</template>

<script>
import pagination from "@/components/Pagination"
import BaseSelect from "@/components/BaseSelect";
import * as userApi from "@/api/gen/userApi"
import * as organizationApi from "@/api/gen/organizationApi"
import * as roleApi from "@/api/gen/roleApi"

export default {
  components: {
    BaseSelect,
   
    pagination,
  },
  name: "user",
  data() {
    return {
      roleApi,
      organizationApi,
      form: {
        organizationId: this.$store.getters.organizationId,
        username: "",
        name: "",
        user: '',
        roleIds: '',

      },
      apiPath:`${process.env.VUE_APP_API}` + "/oss/upload/stampFile",
      total: 0,
      List: [],
      isEditShow: false,
      isUpdateDialog: false,
      isRoleAdmin: false,
      current: {},
      roleList: [],
      imageUrl: "",
      organizationInfo:{}
    }
  },

  watch: {

    form: {
      deep: true,
      handler() {
        this.handList()
      }
    },


  },
  created() {
    this.list()
    //防抖
    this.handList = this._.debounce(this.list, 600)
    this.getOrgInfo()
  },
  computed: {},
  methods: {
    async getOrgInfo(){
      const res = (await organizationApi.getById({id:this.form.organizationId}))
      console.log(res.stampFile)
      if(res.stampFile != null && res.stampFile != ""){
        this.imageUrl = res.stampFile
      }
      this.organizationInfo = res
    },
    handleAvatarSuccess(res, file) {
      console.log(res,file)
      this.imageUrl = URL.createObjectURL(file.raw);
      this.organizationInfo.stampFile = res.data.name
    },

    async handleDelete(){
      this.organizationInfo.stampFile = ""
      this.imageUrl = ""
      const res = (await organizationApi.update({...this.organizationInfo}))
      this.$message({
        type: "success",
        message: "删除成功"
      })
    },

    async handleSave(){
      console.log(this.organizationInfo)
      const res = (await organizationApi.update({...this.organizationInfo}))
      this.$message({
        type: "success",
        message: "保存成功"
      })
    },

    beforeAvatarUpload(file) {
      console.log(file.type)
      const isJPG = (file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传公章图片只能是 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传公章图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    async addDialogShow() {
      this.isEditShow = true
      this.current = {};
    },
    updateDialog(data) {
      this.isEditShow = true
      this.current = data;
    },
    roleAdminDialog(data) {
      this.current = data;
      this.isRoleAdmin = true
    },


    //删除
    remove(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {

        await userApi.deleteById({id: id})
        this.$message({
          type: "success",
          message: "删除成功"
        })

        await this.list()

      }).catch((err) => {

      })


    },


    //禁用

    async change(data, e) {
      console.log(data, e)
      let params = {
        id: data.id,
        status: e
      }
      await userApi.update({...params})

    },


    //列表 搜索
    async list() {
      let clone = {...this.form}
      if (this.form.roleIds) {
        clone.roleIds = clone.roleIds + ','
      }
      const res = (await userApi.list({...clone,pageSize:100000}));


      for (let index in res.data) {
        if (res.data[index].username === 'adminuser') {
          res.data.splice(index, 1)
        }

      }

      this.List = res.data

      console.log(res)

    },


    async role() {
      let res = await roleApi.list()
      this.roleList = res.data
    },


    findRole(arr) {
      let array = []
      this.roleList.forEach(item => {
        arr.find((val) => {
          if (val === item.id) {
            return array.push(item.name)
          }
        })

      })

      array = array.join('、')
      return array
    },


  },
  mounted() {
    // this.role()
  }

}
</script>

<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
