<template>
  <div>

    <el-card style=" margin: 20px 20px">

      <el-form :model="form" inline label-width="100px">

        <el-form-item label="年份" prop="year">
          <el-input v-model="form.year"></el-input>
        </el-form-item>


        <el-form-item label="学校ID" prop="schoolId">
          <el-input v-model="form.schoolId"></el-input>
        </el-form-item>

        <el-form-item label="单位名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
        </el-form-item>
        <el-form-item>
          <el-button v-show="$hasPermission('user.list')" type="primary">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-button v-show="$hasPermission('user.create')" type="primary" @click="addDialogShow">+ 新增</el-button>
      <el-table
          :data="schoolStatisticList"
          :header-cell-style="{background:'#fafafa'}"
          border
      >
        <el-table-column
            label="年度">
          <template slot-scope="scope">

            <span>{{ scope.row.year }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="体检总人数">
          <template slot-scope="scope">

            <span>{{ scope.row.count }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="单位名称">
          <template slot-scope="scope">

            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>


        <!--        <el-table-column-->
        <!--            label="单位名称">-->
        <!--          <template slot-scope="scope">-->

        <!--            <span>{{ scope.row.code }}</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->


        <el-table-column
            label="省">
          <template slot-scope="scope">

            <span>{{findCode(scope.row.province) ? findCode(scope.row.province).name : '' }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="市">
          <template slot-scope="scope">

            <span>{{findCode(scope.row.city) ? findCode(scope.row.city).name : ''}}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="县">
          <template slot-scope="scope">

            <span>{{ findCode(scope.row.county) ? findCode(scope.row.county).name : '' }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="详细地址">
          <template slot-scope="scope">

            <span>{{ scope.row.address }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="单位类型">
          <template slot-scope="scope">

            <span>{{ menu.OrganizationTypeEnum[scope.row.type] }}</span>
          </template>
        </el-table-column>

        <el-table-column
            label="联系人">
          <template slot-scope="scope">

            <span>{{ scope.row.contact }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="联系电话">
          <template slot-scope="scope">

            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column
            align="left"
            label="操作"
            prop="address">
          <template slot-scope="scope">
            <el-button v-show="$hasPermission('user.update')" type="text" @click="updateDialog(scope.row)">修改
            </el-button>
            <el-button v-show="$hasPermission('user.delete')" type="text" @click="remove(scope.row.id) " style="color: red">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination :pageNo.sync="form.pageNo" :pageSize.sync="form.pageSize" :total.sync="total"></pagination>
    </el-card>
    <edit v-if="isEditShow" :row="current" @close="()=>this.isEditShow = !this.isEditShow"
          @update="loadSchoolStatisticList"></edit>
  </div>
</template>

<script>
import edit from "./edit"
import * as dataMenu from "@/assets/js/data"
import pagination from "@/components/Pagination"
import BaseSelect from "@/components/BaseSelect";
import * as schoolStatisticApi from "@/api/gen/schoolStatisticApi"
import * as areaCodeApi from "@/api/gen/areaCodeApi";

export default {
  components: {
    BaseSelect,
    edit,
    pagination
  },
  name: "schoolStatistic",
  data() {
    return {
      menu: dataMenu,
      form: {
        organizationId: "",
        username: "",
        name: "",
        user: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      schoolList: [],
      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      schoolStatisticList: [],
      provinceList: []

    }
  },

  watch: {

    form: {

      deep: true,
      handler() {
        this.handList()
      }
    }

  },
  async created() {
    await this.loadSchoolStatisticList()
    //防抖
    this.handList = this._.debounce(this.loadSchoolStatisticList, 600)

  },
  async mounted() {
    await this.loadCity()
  },
  computed: {},
  methods: {
    async addDialogShow() {
      this.isEditShow = true
      this.current = {};
    },
    updateDialog(data) {
      this.isEditShow = true
      this.current = data;
    },


    async loadCity() {
      const res = (await areaCodeApi.list({pageSize: 10000}))
      this.provinceList = res.data
    },


    //删除
    async remove(id) {

      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await schoolStatisticApi.deleteById({id: id})
        this.$message.success('删除成功')
        await this.loadSchoolStatisticList()
      }).catch(() => {
      })


    },
    //列表 搜索
    async loadSchoolStatisticList() {
      const res = (await schoolStatisticApi.list(this.form));
      console.log(res)
      this.schoolStatisticList = res.data
    },


    findCodeInTree(tree, code) {
      // console.log("在 " + tree.name + "中查找 " + code);
      if (tree.code === code) {
        return tree;
      } else {

        for (const c of (tree.children || [])) {

          let ans = this.findCodeInTree(c, code);


          if (ans) {
            return ans;
          }
        }

      }

      return null;

    },


    findCode(e) {
      for (const item of this.provinceList) {

        let res = this.findCodeInTree(item, e);

        if (res) {
          return res
        }

      }

      return null;


    },

  },


}
</script>

<style scoped>
/deep/ .el-form--inline .el-form-item__label {
  display: unset;
}

</style>
