<template>
  <div id="container" style="margin: 1em">
    <div style="margin-bottom: 1em;" class="printIgnore">

      <el-button type="primary" @click="print">打印</el-button>
      <el-button type="primary" @click="toImage">生成图片</el-button>
      <el-button type="primary" @click="close">关闭</el-button>

    </div>
    <div class="table" v-html="table">

    </div>
  </div>


</template>

<script>
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver'

export default {
  name: "printTable",
  data() {
    return {
      table: '',
      isShow: true
    }
  },
  mounted() {
    this.table = localStorage.getItem('table')
  },
  methods: {
    close(){

      window.close()

    },

    //打印
    print() {

      window.print();

    },
    //生成图片
    toImage() {
      html2canvas(document.querySelector('table')).then(async function (canvas) {
        // document.querySelector("#container").appendChild(canvas);

        let dataURL = canvas.toDataURL();

        // let blob = await canvas.toBlob();
        const a = document.createElement('a')

        a.href = dataURL
        a.setAttribute('download', 'chart-download')
        a.click()
      });


    }
  }
}
</script>

<style scoped>

@media print {


  .printIgnore {

    display: none;

  }

}


.table {

}

/deep/ table {
  border-collapse: collapse;

}

/deep/ td {
  white-space: nowrap;
  padding: 10px;
  text-align: center;
  border: 1px solid black;
}

/deep/ th {
  white-space: nowrap;
  padding: 10px;
  text-align: center;
  border: 1px solid black;
}

</style>
