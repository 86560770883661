<template>
  <el-select v-model="value" v-on="$listeners" v-bind="$attrs" clearable style="width: 100%">
    <el-option v-for="item in items" :key="item.id"  :label="item.grade" :value="item.grade"></el-option>
  </el-select>


</template>

<script>
import * as medicalInformationApi from "@/api/gen/medicalInformationApi"

export default {
  inheritAttrs: false,
  name: "gradeSelect",
  async beforeMount() {
    this.items = (await medicalInformationApi.list({pageSize: 10000})).data
  },

  data() {
    return {
      items: [],
      value:''
    }
  },
};
</script>

<style scoped>

</style>
