<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="true"
        :show-close=true
        :title=title
        :visible="true"
        center
        width="90%"
        @close="close"

    >
      <!--骨架-->
      <div v-for="item in 10" v-show="!canShow">
        <div style="display:flex; margin: 8px 0;">
          <template v-for="item in 3">
            <el-skeleton style="width: 100%" animated>
              <template slot="template">
                <div>
                  <div>
                    <el-skeleton-item variant="h1" style="width:15%; margin: 0 1em 0 4em;"/>
                    <el-skeleton-item variant="h1" style="width: 50%;"/>
                  </div>
                </div>
              </template>
            </el-skeleton>
          </template>
        </div>
      </div>

      <el-form
          ref="form"
          v-if="canShow"
          :rules="rules"
          :model="form"
          align="center"
          label-position="right"
          label-width="100px"
          size="medium"
          style="display: flex; flex-wrap: wrap; padding: 0 1px; ">
        <el-form-item label="学校" prop="schoolId">
          <YeySchool-select v-model="form.schoolId" ref="schoolId" @change="changeSchool"
                            style="width: 100%;"></YeySchool-select>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNo">
          <el-input v-model="form.idNo"></el-input>
        </el-form-item>
        <div style="display: flex; width: 30%;">
          <el-form-item label="年级" style="width: 100%;">
            <el-select v-model="form.grade" @change="changeGgrade" filterable clearable placeholder="请选择">
              <el-option
                  v-for="(item,index) in grade"
                  :key="index"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级" style="width: 100%">
            <el-select v-model="form.classRoomNo" filterable clearable placeholder="请选择">
              <el-option
                  v-for="(item,index) in classNames"
                  :key="index"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>

          </el-form-item>
        </div>

        <el-form-item label="姓名" prop="name">
          <el-input v-model.trim="form.name" ref="name"></el-input>
        </el-form-item>
        <el-form-item label="出生日期" prop="birthDate">
          <el-date-picker
              ref="birthDate"
              v-date-format
              v-model="form.birthDate"
              placeholder="选择日期"
              style="width: 100%"
              type="date"
          >
          </el-date-picker>
        </el-form-item>
        <div style="display: flex; width: 30%;">
          <el-form-item label="年龄" prop="age" style="width: 100%;">
            <el-input v-model.trim="form.age" ref="age" :class="{'red':showRedColor_Age}"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex" style="width: 100%;">
            <data-select v-model="form.sex" ref="sex" parent-code="Gender"></data-select>
          </el-form-item>
        </div>
        <el-form-item label="学号">
          <el-input v-model.trim="form.studentId" :class="{'red':showRedColor_StudentId}"></el-input>
        </el-form-item>
        <el-form-item label="家长姓名">
          <el-input v-model.trim="form.parentName"></el-input>
        </el-form-item>
        <div style="display: flex; width: 30%;">
          <el-form-item label="民族" style="width: 100%;">
            <nationality-select v-model="form.nationalityCode" ref="nationalityCode" filterable></nationality-select>
          </el-form-item>
          <el-form-item label="城乡" prop="cxType" style="width: 100%;">
            <data-select v-model.trim="form.cxType" ref="cxType" parent-code="CityType"></data-select>
          </el-form-item>
        </div>

        <el-form-item label="家长号码" prop="parentPhone">
          <el-input v-model.trim="form.parentPhone" :class="{'red':showRedColor_ParentPhone}"></el-input>
        </el-form-item>
        <el-form-item label="检测日期">
          <el-date-picker
              v-model="form.checkDate"
              placeholder="选择日期"
              style="width: 100%;"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="体检年度">
          <year-select v-model="form.year" style="width: 100%;"></year-select>
        </el-form-item>
        <el-form-item style="width: 90%!important;" label="家庭住址">
          <el-input style="width: 100%;" v-model.trim="form.parentAddress"></el-input>
        </el-form-item>
        <el-form-item label="营养状况" v-show="status !== 'add'">
          <el-input v-model="yyInfo" disabled></el-input>
        </el-form-item>


        <el-form-item v-for="item in OptionsList" :key="item.id" class="checkItem">
          <div style="display: flex;">

            <check-items class="w-100"
                         :config="checkItems[item.checkItemVO.code]" :readOnly="readOnly" :row="rowCurrent"
                         :class="[
                               {'red':item.checkItemVO.code === 'p002' && showRedColor_P002},
                               {'red':item.checkItemVO.code === 'p003' && showRedColor_P003},
                               {'red':item.checkItemVO.code === 'p004' && showRedColor_P004},
                               {'red':item.checkItemVO.code === 'p005' && showRedColor_P005},
                               {'red':item.checkItemVO.subCode === 'p005Sub' && showRedColor_P005Sub},
                               {'red':item.checkItemVO.code === 'p009' && showRedColor_P009},
                                {'red':item.checkItemVO.subCode === 'p009Sub' && showRedColor_P009Sub},
                                 {'red':item.checkItemVO.code === 'p025' && showRedColor_P025},
                                 {'red':item.checkItemVO.code === 'p044' && showRedColor_P044},
                                 {'red':item.checkItemVO.code === 'p052' && showRedColor_P052},
                                 {'red':item.checkItemVO.code === 'p053' && showRedColor_P053},
                                 {'red':item.checkItemVO.code === 'p055' && showRedColor_P055},
                                 {'red':item.checkItemVO.code === 'p059' && showRedColor_P059},
                               ]"
                         @change="handleChange"/>
            <el-input v-model="sgInfo" v-if="item.checkItemVO.code === 'p002'" style="width: 50%;" disabled></el-input>
            <el-input v-model="tzInfo" v-if="item.checkItemVO.code === 'p003'" style="width: 50%;" disabled></el-input>
          </div>

        </el-form-item>


        <el-col v-show="status !== 'check'">
          <div style="display: flex; padding-left:5em; margin: 10px 0">
            <el-button size="mini" type="primary" @click="createResultAdvice">生成结果建议</el-button>
          </div>
        </el-col>
        <el-form-item class="result" label="结果" style="width: 100%;">
          <div style="width: 90%; display:flex;">
            <el-input v-model.trim="form.result" style="width: 100%;" type="textarea" :rows="5"></el-input>
          </div>
        </el-form-item>

        <el-form-item class="suggest" label="建议" style="width: 100%;">
          <div style="width: 90%; display: flex">
            <el-input v-model.trim="form.advice" style="width: 100%;" type="textarea" :rows="5"></el-input>
          </div>
        </el-form-item>

      </el-form>
      <div v-show="canShow" style="display: flex; justify-content: center; margin-top: 20px">
        <span slot="footer" class="dialog-footer" v-if="status !== 'check'">
          <my-button type="primary" :auto-loading="true" @click="update"
                     v-show="$hasPermission('yeyPhysicalInfomation.update')">确 定</my-button>
          <el-button @click="close">取 消</el-button>
          <my-button v-if="status === 'add'" :auto-loading="true" @click="save">保存并继续</my-button>
        </span>

        <span slot="footer" class="dialog-footer" v-else>
           <el-button @click="close">确 定</el-button>
         </span>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import YeySchoolSelect from "@/components/YeySchoolSelect";
import YearSelect from "@/components/yearSelect"
import NationalitySelect from "@/components/nationalitySelect"
import checkItems from "@/components/CheckItems"
import DataSelect from "@/components/DataSelect"
import Autocomplete from '@/components/Autocomplete'
import * as yEYMedicalInformationApi from "@/api/gen/yEYMedicalInformationApi"
import * as checkItemApi from "@/api/gen/checkItemApi"
import * as dataMenu from "@/assets/js/data"
import * as organizationApi from "@/api/gen/organizationApi";
import * as organizationYEYCheckItemStatusApi from "@/api/gen/organizationYEYCheckItemStatusApi"

import defaultForm from "@/views/yeyPhysicalInfomation/defaultForm";
import dateFormat from "@/directive/dateFormat"
import {yeySGInfo, yeyTZInfo, yeyYYInfo} from "@/plugin/dataInfo";


export default {
  components: {
    checkItems,
    YeySchoolSelect,
    YearSelect,
    NationalitySelect,
    DataSelect,
    Autocomplete
  },
  directive: {
    dateFormat
  },
  name: "edit",
  props: {
    row: {},
    rowCurrent: {}
  },

  provide() {

    return {

      formKey: this.formKey

    }

  },

  data() {
    return {
      formKey: new Date().getTime().toString(),
      changeId: new Date().getTime().toString(),

      checkItemList: [],
      form: {
        ...defaultForm,
        organizationId: this.$store.getters.organizationId,
        parentName: "",
        parentPhone: "",
        parentAddress: ""

      },

      rules: {
        schoolId: {required: true, message: '请输入学校', trigger: 'blur'},
        name: {required: true, message: '请输入姓名', trigger: 'blur'},
        birthDate: {required: true, message: '请输入出生日期', trigger: 'blur'},
        age: {required: true, message: '请输入年龄', trigger: 'blur'},
        sex: {required: true, message: '请输入性别', trigger: 'blur'},
        nationalityCode: {required: true, message: '请输入名族', trigger: 'blur'},
        cxType: {required: true, message: '请输入城乡', trigger: 'blur'},
      },

      menu: dataMenu,
      gradeInfos: [],
      optionsList: [],

      restaurants: [],


      sgInfo: yeySGInfo(this.rowCurrent) ? yeySGInfo(this.rowCurrent) : '',
      tzInfo: yeyTZInfo(this.rowCurrent) ? yeyTZInfo(this.rowCurrent) : '',
      yyInfo: yeyYYInfo(this.row) ? yeyYYInfo(this.row) : '',


      checkItemStatus: "",

      showRedColor_StudentId: false,
      showRedColor_ParentPhone: false,
      showRedColor_Age: false,
      showRedColor_P002: false,
      showRedColor_P003: false,
      showRedColor_P004: false,
      showRedColor_P005: false,
      showRedColor_P005Sub: false,
      showRedColor_P009: false,
      showRedColor_P009Sub: false,
      showRedColor_P025: false,
      showRedColor_P044: false,
      showRedColor_P052: false,
      showRedColor_P053: false,
      showRedColor_P055: false,
      showRedColor_P059: false,
    }
  },

  watch: {
    "form.idNo": {
      handler(n) {
        if (n.length === 18) {
          let all = n.slice(6, 14)
          this.form.birthDate = this.$dateString(all)
        }
      }
    },
    'form.birthDate': {
      handler(n) {
        // if (!n) {
        //   this.form.age = ""
        //   return
        // }
        this.form.age = Math.abs(parseInt(this.$diff(n, this.$now(), 'year')?.years));

      }
    },
    'form.age': {
      handler(n) {
        this.showRedColor_Age = n.length > 3
      }
    },
    'form.studentId': {
      handler(n) {
        this.showRedColor_StudentId = n.length > 15
      }
    },
    'form.parentPhone': {
      handler(n) {
        this.showRedColor_ParentPhone = n.length > 11
      }
    },


    'changeId': {
      handler(n) {

        if (this.form.p002) {
          this.showRedColor_P002 = this.form.p002[0] === '2'
        }
        if (this.form.p003) {

          this.showRedColor_P003 = this.form.p003 > 200

        }
        if (this.form.p004) {

          this.showRedColor_P004 = this.form.p004 > 5000

        }
        if (this.form.p005) {

          this.showRedColor_P005 = this.form.p005.length > 5

        }
        if (this.form.p005Sub) {
          this.showRedColor_P005Sub = this.form.p005Sub.length > 5

        }
        if (this.form.p009) {

          this.showRedColor_P009 = this.form.p009 > 5.0

        }
        if (this.form.p009Sub) {

          this.showRedColor_P009Sub = this.form.p009Sub > 5.0

        }
        if (this.form.p025) {

          this.showRedColor_P025 = this.form.p025.length > 5

        }
        if (this.form.p044) {

          this.showRedColor_P044 = this.form.p044 > 200

        }
        if (this.form.p052) {

          this.showRedColor_P052 = this.form.p052.length > 4

        }
        if (this.form.p053) {

          this.showRedColor_P053 = this.form.p053.length > 4

        }
        if (this.form.p055) {

          this.showRedColor_P055 = this.form.p055.length > 4

        }
        if (this.form.p059) {

          this.showRedColor_P059 = this.form.p059.length > 4

        }

      }
    },
  },

  computed: {
    status() {
      return this.row.status
    },
    title() {
      if (this.status === 'add') return '体检信息—新增'
      if (this.status === 'edit') return '体检信息—编辑'
      if (this.status === 'check') return '体检信息—查看'
    },
    checkItems() {
      return this._.chain(this.checkItemList).groupBy(item => item.code).mapValues(x => x[0]).value();
    }
    ,
    canShow() {

      return Object.keys(this.checkItems)?.length > 0;
    }
    ,

    CityEnum() {
      return Object.values(this.menu.CityEnum)
    }
    ,
    OrganizationTypeEnum() {
      return Object.values(this.menu.OrganizationTypeEnum)
    }
    ,
    GenderEnum() {
      return Object.values(this.menu.GenderEnum)
    }
    ,
    OptionsList() {
      if (this.status === 'add') {
        return this.optionsList.filter(item => item.status === 1)
      }
      if (this.status === 'edit') {
        return this.optionsList.filter(item => item.status === 1)
      }
      if (this.status === 'check') {
        return this.optionsList.filter(item => item.status === 1)
      }
    }
    ,
    grade() {
      if (this.form.schoolId) {
        return this.gradeInfos
      }


    }
    ,

    classNames() {
      return this.grade?.filter(item => item.name === this.form.grade)[0]?.classNames
    }
    ,


    readOnly() {
      if (this.status === 'check') {
        return true
      }
    }
  },
  async mounted() {
    Object.assign(this.form, this.row)
    await this.checkItem()
    await this.tableList()
    await this.getUserInfo()
    if (!this.form.checkDate) {
      this.form.checkDate = this.$dateTimeString(this.$now())
    }
    await this.changeSchool()
  },
  methods: {
    //获取个人体检信息
    async getUserInfo() {
      if (this.row.id) {

        this.form = await yEYMedicalInformationApi.getById({id: this.row.id})
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

    // {"p001": '正常', 'p001Sub': ""}
    handleChange(data) {

      // // 写法1
      Object.keys(data).forEach(
          k => this.form[k] = data[k]
      );
      this.changeId = this.uuidv4();
      //
      // // 写法2
      // this.form = {...this.form, ...data};


    }
    ,
    //取消
    close() {
      this.$emit("close")
    }
    ,
    //确定
    async update(done) {
      try {
        let status;
        if (this.status === 'add') {
          status = await this.add()
        } else {
          status = await this.edit()
        }
        if (status) {
          this.$emit("close");
          this.$emit("update")
        }
      } finally {
        done()

      }


    }
    ,

    async add() {
      return new Promise(resolve => {
        this.$refs.form.validate(async (valid, field) => {
          if (valid) {

            let clone = {...this.form}
            clone.birthDate = this.$dateTimeString(clone.birthDate)


            if (this._.isEmpty(clone.result) && this._.isEmpty(clone.advice)) {
              await this.createResultAdvice();
              clone.result = this.form.result;
              clone.advice = this.form.advice;
            }


            await yEYMedicalInformationApi.create({...clone});
            this.$message.success('添加成功')
          } else {
            this.$message.error('请输入必填项');
            let keys = Object.keys(field);
            for (const key of keys) {
              try {
                this.$refs[key].focus();
                break;
              } catch (e) {
                console.log(e);
              }
            }
          }
          resolve(valid)
        })
      })


    }
    ,
    async edit() {
      return new Promise(resolve => {
        this.$refs.form.validate(async (valid, field) => {
          if (valid) {

            let clone = {...this.form}

            clone.birthDate = this.$dateTimeString(clone.birthDate)

            if (this._.isEmpty(clone.result) && this._.isEmpty(clone.advice)) {
              await this.createResultAdvice();
              clone.result = this.form.result;
              clone.advice = this.form.advice;
            }


            await yEYMedicalInformationApi.update({...clone});
            this.$message.success('修改成功')

          } else {
            this.$message.error('请输入必填项');
            let keys = Object.keys(field);
            for (const key of keys) {
              try {
                this.$refs[key].focus();
                break;
              } catch (e) {
                console.log(e);
              }
            }
          }
          resolve(valid)
        })
      })
    },

    //保存并继续
    async save(done) {
      await this.add();
      this.form.name = ""
      this.form.idNo = ""
      this.form.studentId = ""
      this.form.birthDate = ""
      this.form.contact = ""
      this.form.result = ""
      this.form.advice = ""
      this.form.age = ""
      this.$store.commit("updateFromKey")
      this.$emit("update")
      done()
      this.$nextTick(() => {
        this.$refs.name.focus()
      })
    }
    ,

    async checkItem() {
      const res = (await checkItemApi.list({pageSize: 10000000}));
      // res.data.forEach((item => item.status = 0));
      this.checkItemListTmp = res.data.filter((item) => item?.types?.includes(0));


    }
    ,


    async tableList() {
      const res = (await organizationYEYCheckItemStatusApi.list({
        organizationId: this.$store.getters.organizationId,
        pageSize: 1000000
      }));
      this.optionsList = this._.chain(res.data).filter(item => item.checkItemVO?.types?.includes(0)).sortBy(x => parseFloat(x.sortKey)).value()

      let status = "";

      for (let i = 0; i < 100; i++) {

        let code = ("p" + (i + "").padStart(3, "0"));
        // console.log(code);

        if (this.optionsList.filter(x => x.checkItemVO.code === code)?.pop()?.status === 1) {

          status += "1";
        } else {
          status += "0";
        }

      }

      this.form.checkItemStatus = status


      for (let item of this.checkItemListTmp) {
        for (let k of this.optionsList) {

          if (item.id === k.checkItemId) {

            item.status = k.status
            // this.$set(item, 'status', k.status);

          }
        }


      }
      this.checkItemList = [...this.checkItemListTmp];


    },

    async createResultAdvice() {
      let clone = {...this.form}
      clone.birthDate = this.$dateTimeString(clone.birthDate)
      const resp = await yEYMedicalInformationApi.getResultAndAdvice({...clone})
      var result = resp.map((item, index) => `${index + 1} ` + item.result).join('\n')
      var advice = resp.flatMap(item => item.advice).join(`\n`)
      if(!advice && result){
        result = resp.map((item) =>  item.result).join('\n')
      }
      this.form.result =result
      this.form.advice = advice
    },

//更换年级
    changeGgrade() {
      this.form.classRoomNo = ''
    },

    //更换学校
    async changeSchool() {
      if (this.form.schoolId) {
        const res = await organizationApi.getById({id: this.form.schoolId})
        this.gradeInfos = res.gradeInfos
        this.form.cxType = res.cxType
      }else{
        this.form.grade = ""
        this.form.classRoomNo = ''
      }

    },


  }
}
</script>

<style scoped>

.w-40p {
  width: 40% !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.el-input {
  width: 100%;
}


.el-form-item {
  font-size: 12px;
  width: 30%;
  margin-bottom: 0;

}

.el-form-item__label {
  font-size: 12px;
}

/deep/ .el-input--medium .el-input__inner {
  height: 28px !important;
}


.el-select {
  width: 100%;
}

/deep/ .result .el-input__inner {
  height: 73px !important;
}

/deep/ .suggest .el-input__inner {
  height: 47px !important;
}

/deep/ .result .el-form-item__content,
/deep/ .suggest .el-form-item__content {
  margin-left: 0 !important;
}

/deep/ .checkItem .el-form-item__content,
/deep/ .checkItem .el-form-item__content {
  margin-left: 0 !important;
}


/deep/ .el-form-item__error {
  display: none;

}


.check-items {
  justify-content: flex-end;
}

.check-items + .check-items {

}

/deep/ .el-dialog__header {
  padding: 5px 20px 5px;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 10px 25px 30px;
}

/deep/ .el-dialog__title {
  font-size: 16px;
}

/*/deep/.el-form-item__content{*/
/*  margin-right: 80px !important;*/
/*}*/
.red >>> input {
  color: red!important;
}
</style>
