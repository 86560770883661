import Vue from "vue"

let storage = {
    setStorage(key,value){
        localStorage.setItem(key,JSON.stringify(value))
    },
    getStorage(key){
        if(!key) {
            return null
        }
      return JSON.parse(localStorage.getItem(key))
    },
    removeStorage(){
        localStorage.removeItem(key);
    }
}
export default {
    install(Vue){
        Vue.prototype.$storage = storage
    }
}