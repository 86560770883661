<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :visible="true"
        center
        title="上传文件"
        width="35%"
        @close="close"
    >
      <el-form ref="form" label-width="80px" style="margin-top: 30px">
        <el-form-item label="机构:">
          <organization-select v-model="form.organizationId"></organization-select>
        </el-form-item>
        <el-form-item label="存储引擎:">
          <el-select v-model="form.storageType" placeholder="请选择">
            <el-option
                v-for="(item,index) in storageTypeMenu"
                :key="index"
                :label="item"
                :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-upload
              ref="abc"
              :action="actionPath"
              :before-upload="beforeUpload"
              :data="{'organizationId':$store.getters.organizationId,'filePath': fileName,'storageType':form.storageType}"
              :on-success="handSuccess"
              class="upload-demo"
              multiple
              name='file'>
            <el-button size="small" type="primary">点击上传</el-button>

          </el-upload>
        </el-form-item>

      </el-form>


      <span slot="footer" class="dialog-footer">
    <el-button @click="close">完 成</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import * as dataMenu from "@/assets/js/data"
import OrganizationSelect from "@/components/OrganizationSelect";


export default {
  name: "edit",


  components: {OrganizationSelect},

  data() {
    return {
      menu: dataMenu,
      form: {
        organizationId: this.$store.getters.organizationId,
        storageType: "0"
      },

      fileName: '',

    }
  },

  beforeMount() {

  },

  computed: {
    actionPath() {


      return process.env.VUE_APP_API + "/file/upload";
    },

    storageTypeMenu() {
      return (this.menu.StorageType)
    }

  },
  async mounted() {

  },
  methods: {
    close() {
      this.$emit("close")
    },


    handSuccess(res, file) {
      this.$emit('getFileId',res.data)
      this.$emit('update')

    },

    beforeUpload(file) {
      this.fileName = file.name;

      return new Promise((resolve, reject) => {

        this.$nextTick(x => {


          resolve(true);

        })
      })


    },



  }
}
</script>

<style scoped>


</style>
