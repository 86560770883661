<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {

}
</script>

<style>
html, body,#app {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

@media print {

  html, body, #app {

    width: auto;

    height: auto;

  }
}

.el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
  border-right: 0 !important;
}


.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li{
  background-color: transparent !important;
  border: 1px solid #cccccc !important;
  color: #505050;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color:#fff !important;
  color:rgba(24, 144, 255, 1) !important;
  border: 1px solid rgba(24, 144, 255, 1) !important;
}


body .el-table th.gutter {
  display: table-cell !important;
}


.width2 .active{
  width: 4em !important;
  flex-basis: 0 !important;
}
.width1 .active {
  width: 160px!important;
}

.width2 .active {

}

</style>
