<!--龋齿患病情况统计表-->
<template>
  <div>

    <el-card style="margin: 20px;">
      <el-form ref="form" size="small" label-width="70px">
        <el-form-item label="学校：">
          <ZxxSchool-select ref="schools" v-model="form.schoolId"></ZxxSchool-select>
        </el-form-item>
        <el-form-item label="年级：">
          <el-input v-model="form.grade"></el-input>
        </el-form-item>
        <el-form-item label="班级：">
          <el-input v-model="form.classRoomNo"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <sex-select v-model="form.sex"></sex-select>
        </el-form-item>
        <el-form-item label="年度：">
          <year-select v-model="form.year"></year-select>
        </el-form-item>
        <el-col :xl="4" :lg="5">
          <el-form-item style="width: 100%;" label="统计类型：" label-width="100px">
            <statistical-type-select v-model="form.groupBy" :clearable="false"  @updateValue="search"></statistical-type-select>
          </el-form-item>
        </el-col>
        <div style="display:flex; margin-left: auto">
          <div>
            <el-button type="primary" size="small" @click="search" v-show="$hasPermission('shortSightedness.list')">查 询</el-button>
            <el-button type="primary" size="small" @click="exportExcel" v-show="$hasPermission('shortSightedness.export')">导 出</el-button>
            <el-button type="primary" size="small" @click="printTable" v-show="$hasPermission('shortSightedness.print')">打印当前页</el-button>
          </div>
        </div>
      </el-form>

    </el-card>
    <el-card style=" margin: 20px">
      <el-table
          id="exportTab"
          :data="formList2"
          v-loading="loading"
          style="width: 100%"
          border

      >
        <el-table-column
            :label="type"
            prop="_id"
            :key="type"
        >
          <template slot-scope="scope">
            <template v-if="form.groupBy === '$sex'">
              <div v-if="scope.row._id === 0">男</div>
              <div v-if="scope.row._id === 1">女</div>
              <div v-if="scope.row._id === '合计'">合计</div>
            </template>
            <template v-else>
              <div>{{ scope.row._id }}</div>
            </template>
          </template>

        </el-table-column>
        <el-table-column
            align="left"
            prop="total"
            label="受检人数"
        >
        </el-table-column>
        <el-table-column
            label="轻度近视">
          <el-table-column
              label="人数"
              prop="r070Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
              prop="r070Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r070Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="中度近视">
          <el-table-column
              label="人数"
              prop="r071Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
              prop="r071Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r071Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="重度近视">
          <el-table-column
              label="人数"
              prop="r072Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
              prop="r072Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r072Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="合计">
          <el-table-column
              label="视力不良人数"
              prop="r059Sum"
          >
          </el-table-column>
          <el-table-column
              label="视力不良率(%)"
              prop="r059Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r059Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>


      </el-table>


      <div style="color: rgba(80, 155, 248, 1);font-size: 11px; margin-top: 20px">
        近视判断标准：近视判定标准为裸眼视力＜5.0且等效球镜度数（等效球镜度数=球镜度数+1/2柱镜度数）＜-0.50D，凡单眼判定为近视者即计入近视人数；近视轻中重度判断：1、轻度近视：一眼等效球镜、
        度数-0.50D～-3.00D为轻度，另一眼轻度或正常。2、中度近视：一眼等效球镜度数-3.00D～-6.00D（含-6.0D），另一眼中度或轻度或正常。3、重度近视：一眼等效球镜度数＜-6.00D。4、配戴角膜塑形
        镜的学生不进行近视轻中重度分析。
      </div>
    </el-card>
  </div>
</template>

<script>

import ZxxSchoolSelect from "@/components/ZxxSchoolSelect"
import gradeSelect from "@/components/gradeSelect"
import classRoomNoSelect from "@/components/classRoomNoSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"


import * as scriptApi from "@/api/gen/scriptApi"

export default {
  name: "shortSightedness",
  components: {
    ZxxSchoolSelect,
    statisticalTypeSelect,
    gradeSelect,
    classRoomNoSelect,
    sexSelect,
    yearSelect,

  },
  data() {
    return {
      form: {
        id: "",
        schoolId: "",
        schoolType: "",
        idNo: "",
        grade: "",
        classRoomNo: "",
        name: "",
        birthDate: "",
        age: "",
        sex: "",
        studentId: "",
        contact: "",
        nationalityCode: "",
        cxType: "",
        checkDate: "",
        year: "",
        result: "",
        advice: "",
        groupBy: ""
      },

      name: '',
      scriptName: '',
      formList: [],


      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      model: "",
      options: "",
      loading: false
    }
  },
  computed: {
    type() {
      if (this.form.groupBy === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupBy === '$sex') {
        return '性别'
      }
      if (this.form.groupBy === '$grade') {
        return '年级'
      }
    },

    formList2(){
      this.formList.push({
        "_id":'合计',
        total: this._.sumBy(this.formList,'total'),
        r070Sum:this._.sumBy(this.formList,'r070Sum'),
        r071Sum:this._.sumBy(this.formList,'r071Sum'),
        r072Sum:this._.sumBy(this.formList,'r072Sum'),
        r059Sum:this._.sumBy(this.formList,'r059Sum'),
      })
      return this.formList
    }

  },
  mounted() {


  },
  methods: {

    async search() {
      if(!this.form.schoolId){
        this.$message.warning('请选择学校')
        return
      }
      await this.scriptList()

    },


    async scriptList() {
      this.loading = true
      let clone = this._.cloneDeep(this.form)
      if (!clone.schoolId) {
        clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
      }

      if(clone.sex){
        if(clone.sex=='男'){
          clone.sex =0;
        }else{
          clone.sex =1;
        }
      }

      this.formList = await scriptApi.execute({name: '全部统计.groovy', params: clone})

      this.formList = this._.chain(this.formList).sortBy(x => parseFloat(x._id)).value()
      this.$sortByGrade(this.formList)
      this.loading = false

    },

    exportExcel() {
      var sex =this.form.sex
      var grade =this.form.grade
      var classRoomNo =this.form.classRoomNo
      var query =[]
      var queryStr =""
      if(sex){
        query.push(sex)
      }
      if(grade){
        query.push(grade)
      }
      if(classRoomNo){
        query.push(classRoomNo)
      }
      if(query.length>0){
        queryStr = query.join("-")
      }
      if(queryStr){
        queryStr=queryStr+"-"
      }
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${queryStr}${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab',queryStr)
    },
    // exportExcel() {
    //   this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    // },

    printTable() {

      this.$print('#exportTab')
    },

  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
  flex-wrap: wrap;

}

/deep/ .el-form-item {
  width: 13%;
}

/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  /*border-right: 1px solid #EBEEF5 !important;*/
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border-right: 1px solid #EBEEF5 !important;
}
</style>
