<!--常见病汇总-->
<template>
  <div>
    <el-card style="margin: 20px;">
      <el-form ref="form" size="small" label-width="70px">
        <el-form-item label="学校：">
          <YeySchool-select ref="schools" v-model="form.schoolId"></YeySchool-select>
        </el-form-item>
        <el-form-item label="年度：">
          <year-select v-model="form.year"></year-select>
        </el-form-item>
        <el-form-item label="统计类型：" label-width="100px">
          <statistical-type-select v-model="form.groupBy" :clearable="false"  @updateValue="search"></statistical-type-select>
        </el-form-item>

       <div style="margin-left: auto">
         <el-button size="small" v-show="$hasPermission('yeyCommonDisease.list')" type="primary" @click="search">查 询</el-button>
         <el-button size="small" v-show="$hasPermission('yeyCommonDisease.export')" type="primary" @click="exportExcel">导 出</el-button>
         <el-button size="small" v-show="$hasPermission('yeyCommonDisease.print')" type="primary" @click="printTable">打印当前页</el-button>
       </div>
      </el-form>

    </el-card>

    <el-card>
      <div style="display: flex; flex-wrap: wrap; justify-content: space-between; ">
        <echarts :option="option1" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
        <echarts :option="option2" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
        <echarts :option="option3" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
        <echarts :option="option4" style="width: 48%; height: 300px; margin-top: 10px"></echarts>
      </div>
    </el-card>

    <el-card style=" margin: 20px">
      <el-table
          id="exportTab"
          :data="formList2"
          v-loading="loading"
          border
          style="width: 100%"
      >
        <el-table-column
            :key="type"
            :label="type"
            prop="_id"
        >
          <template slot-scope="scope">
            <template v-if="form.groupBy === '$sex'">
              <div v-if="scope.row._id === 0">男</div>
              <div v-if="scope.row._id === 1">男</div>
              <div v-if="scope.row._id === '合计'">合计</div>
            </template>
            <template v-else>
              <div>{{ scope.row._id }}</div>
            </template>
          </template>

        </el-table-column>
        <el-table-column
            label="视力不良"
        >
          <el-table-column
              label="受检人数"
              prop="r073Count"
          >
          </el-table-column>
          <el-table-column
              label="检出人数"
              prop="r073Sum"
          >
          </el-table-column>
          <el-table-column
              label="检出率(%)"
              prop="r073Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r073Rate |percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="乳牙龋患"
        >
          <el-table-column
              label="受检人数"
              prop="r069Count"
          >
          </el-table-column>
          <el-table-column
              label="患病人数"
              prop="r069Sum"
          >
          </el-table-column>
          <el-table-column
              label="龋患率(%)"
              prop="r069Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r069Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="龋均"
          >
            <template slot-scope="scope">
              <div>{{ (scope.row.r046Sum / scope.row.total) | percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="营养状况"
        >
          <el-table-column
              label="受检人数"
              prop="r069Count"

          >
          </el-table-column>
          <el-table-column
              label="低体重人数"
              prop="r040Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r040Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r040Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="生长发育迟缓人数"
              prop="r020Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r020Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r020Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="消瘦人数"
              prop="r029Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r029Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r029Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="超重人数"
              prop="r004Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r004Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r004Rate |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="肥胖人数"
              prop="r005Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r005Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r005Rate |percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="贫血"

        >
          <el-table-column
              label="受检人数"
              prop="r030Count"
          >
          </el-table-column>
          <el-table-column
              label="患病人数"
              prop="r030Sum"
          >
          </el-table-column>
          <el-table-column
              label="患病率(%)"
              prop="r030Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r030Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>


      </el-table>


    </el-card>
  </div>
</template>

<script>

import YeySchoolSelect from "@/components/YeySchoolSelect"
import gradeSelect from "@/components/gradeSelect"
import classRoomNoSelect from "@/components/classRoomNoSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"

import echarts from "@/components/Echarts";
import * as scriptApi from "@/api/gen/scriptApi"

export default {
  name: "yeyCommonDisease",
  components: {
    YeySchoolSelect,
    statisticalTypeSelect,
    gradeSelect,
    classRoomNoSelect,
    sexSelect,
    yearSelect,
    echarts

  },
  data() {
    return {
      form: {
        id: "",
        schoolId: "",
        schoolType: "",
        idNo: "",
        grade: "",
        classRoomNo: "",
        name: "",
        birthDate: "",
        age: "",
        sex: "",
        studentId: "",
        contact: "",
        nationalityCode: "",
        cxType: "",
        checkDate: "",
        year: "",
        result: "",
        advice: "",
        groupBy: ""
      },

      name: '',
      scriptName: '',
      formList: [],


      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      model: "",
      options: "",
      loading:false,

    }
  },
  computed: {
    type() {
      if (this.form.groupBy === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupBy === '$sex') {
        return '性别'
      }
      if (this.form.groupBy === '$grade') {
        return '年级'
      }
    },
    formList2(){
      this.formList.push({
        "_id":'合计',
        r073Count:this._.sumBy(this.formList,'r073Count'),
        r073Sum:this._.sumBy(this.formList,'r073Sum'),
        r069Count:this._.sumBy(this.formList,'r069Count'),
        r069Sum:this._.sumBy(this.formList,'r069Sum'),
        r001Sum:this._.sumBy(this.formList,'r001Sum'),
        r040Sum:this._.sumBy(this.formList,'r040Sum'),
        r020Sum:this._.sumBy(this.formList,'r020Sum'),
        r029Sum:this._.sumBy(this.formList,'r029Sum'),
        r004Sum:this._.sumBy(this.formList,'r004Sum'),
        r005Sum:this._.sumBy(this.formList,'r005Sum'),
        r030Count:this._.sumBy(this.formList,'r030Count'),
        r030Sum:this._.sumBy(this.formList,'r030Sum'),
      })
      return this.formList
    },
    option1() {

      let x = this._.chain(this.formList).sortBy(x => x._id).map(x => x._id).value();
      let y = this._.chain(this.formList).sortBy(x => x._id).map(x => x.r073Sum).value();

      return {
        title: {
          show: true,
          text: "视力不良"
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    },

    option2() {
      let x = this._.chain(this.formList).sortBy(x => x._id).map(x => x._id).value();
      let y = this._.chain(this.formList).sortBy(x => x._id).map(x => x.r069Sum).value();
      return {
        title: {
          show: true,
          text: '乳牙龋患'
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    },

    option3() {
      let x = this._.chain(this.formList).sortBy(x => x._id).map(x => x._id).value();
      let y = this._.chain(this.formList).sortBy(x => x._id).map(x => x.r003Sum).value();
      return {

        title: {
          show: true,
          text: '营养状况'
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    },

    option4() {

      let x = this._.chain(this.formList).sortBy(x => x._id).map(x => x._id).value();
      let y = this._.chain(this.formList).sortBy(x => x._id).map(x => x.r030Sum).value();
      return {
        title: {
          show: true,
          text: '贫血'
        },
        toolbox: {
          show: true,
          feature: {

            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: y,
          type: 'bar'
        }]
      }

    }

  },
  mounted() {


  },
  methods: {

    async search() {
      if(!this.form.schoolId){
        this.$message.warning('请选择学校')
        return
      }
      await this.scriptList()

    },


    async scriptList() {
      this.loading = true
      let clone = this._.cloneDeep(this.form)
      if (!clone.schoolId) {
        clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
      }
      clone.queryType = 0;
      this.formList = await scriptApi.execute({name: '全部统计.groovy', params: clone})

      this.formList = this._.chain(this.formList).sortBy(x => parseFloat(x._id)).value()
      this.$sortByGrade(this.formList)
      this.loading = false


    },


    exportExcel() {
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    },

    printTable() {

      this.$print('#exportTab')
    },

  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
  flex-wrap: wrap;

}

/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
