import Vue from 'vue';
import axios from "axios";
import store from '@/store'
import _ from "lodash"
import router from "@/router";


axios.defaults.baseURL = process.env.VUE_APP_API

Vue.prototype.$http = axios

axios.interceptors.request.use(
    config => {
        // do something before request is sent
        if (config.url.endsWith("list") && _.isEmpty(config.params)) {
            config.params = {pageSize: 100000000}

        }
        let token = store.state.token
        if (token) {


            config.headers['X-Token'] = token
        }


        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
);

// Add a response interceptor
axios.interceptors.response.use(async function (response) {


    let resp = response.data;

    if (resp.code === 'success') {
        return resp.data;
    } else if (resp.code === 'token.invalid') {

        await store.commit("logout")
        router.push({name: "login"});
        localStorage.removeItem("vuex");

        return Promise.reject(resp.message || resp.code);
    } else {

        return Promise.reject(resp.message || resp.code);

    }


}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});


export default axios
