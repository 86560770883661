import request from '@/utils/request'

export function create(data) {

  return request({
    url: '/areaCode/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/areaCode/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/areaCode/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/areaCode/deleteByIds',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/areaCode/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/areaCode/getByIds',
    method: 'GET',
    params})
}

   

export function list(params) {

  return request({
    url: '/areaCode/list',
    method: 'GET',
    params})
}

   

export function update(data) {

  return request({
    url: '/areaCode/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/areaCode/update/batch',
    method: 'POST',
    data})
}

   