import axios from "axios";
import _ from "lodash";
import {MessageBox} from 'element-ui';

let URL = process.env.VUE_APP_API


function toJson(current) {
    if (_.isString(current)) {
        return JSON.stringify(JSON.parse(current));
    }

    return JSON.stringify(current);
}

async function checkVersion() {
    // console.log(process.env.NODE_ENV)
    if(process.env.NODE_ENV === 'development') return
    console.log("checkVersion run")
    let prev = localStorage.getItem("x-version");

    let locala = axios.create({

        baseURL: "",
        headers:{Pragma: "no-cache"}


    })


    let current = (await locala.get('/version.json')).data;


    if (!prev) {

        localStorage.setItem("x-version", toJson(current))
        return
    }


    if (toJson(prev) !== toJson(current)) {
        localStorage.removeItem("x-version")
        MessageBox.confirm('版本更新, 将自动刷新页面', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            window.location.reload(true);
        }).catch((err) => {
            console.log(err)
        })

    }


}

export default {

    install(Vue) {

        let id = setInterval(checkVersion, 30 * 1000);


    }

}
