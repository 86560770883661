import request from '@/utils/request'

export function admin(params) {

  return request({
    url: '/statistic/admin',
    method: 'GET',
    params})
}

   

export function org(params) {

  return request({
    url: '/statistic/org',
    method: 'GET',
    params})
}

   