import * as baseDataApi from "@/api/gen/baseDataApi";

async function baseData(parentCode) {
    let all = (await baseDataApi.list()).data;
    let parentId = all.filter(item => item.code === parentCode).pop().id

    return all.filter(item => item.parentId === parentId).map(item =>{ return {name:item.name,code:item.code}})

}

function findCode(arr,code) {
   let result =  Array.isArray(arr)
       if(result){
           return  arr.filter(item=>{
               if(item.code === String(code)) {
                   return item
               }
           })[0]?.name
       }else{
           return []
       }


}


export default {

  install(Vue){
      Vue.prototype.$baseData= baseData
      Vue.prototype.$findCode= findCode
  }

}
