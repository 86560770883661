<template>
  <div>
    <el-pagination
        :current-page="pageNo"
        :page-size="pageSize"
        :page-sizes="[10, 50, 100,200]"
        :total="total"
        align="right"
        background
        layout=" total,prev,pager,next,sizes,jumper"
        style="margin-top: 20px"
        @current-change="currentChanged"
        @size-change="sizeChanged">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: [Number],
    pageNo: [Number],
    pageSize: [Number],


  },


  beforeMount() {
  },

  methods: {

    currentChanged(e) {
      this.$emit("update:pageNo", e);


    },


    sizeChanged(e) {
      this.$emit("update:pageSize", e);


    }
  }
}
</script>

<style scoped>


</style>