
import voca from 'voca';
export default {

  install(Vue) {

    Vue.prototype.voca = voca


  }

}
