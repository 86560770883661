<template>
  <div style="margin: 0 auto; font-size: 12px; line-height: 35px">
    <div class="none" style="text-align: center;margin-top: 20px">
      <el-button type="primary" @click="printClick">打印</el-button>
      <el-button type="primary" @click="closePage">关闭</el-button>
      <el-checkbox v-model="selectStamp" style="margin-left:20px">打印公章</el-checkbox>
    </div>

    <div class="none" style=" text-align: center; color:#d43030; margin-top: 20px">
      本次打印的结果是根据体检机构/学校上传的数据自动生成，不承担体检的相关法律责任，仅供相关单位参考！
    </div>
    <div class="none" style=" text-align: center; color:#d43030; margin-bottom: 20px">
      各相关单位如使用，请对学生体检结果进行核对和确认，并加盖体检机构公章方可有效。
    </div>


    <div v-for="(item, index) in infoList" :key="item.id" style="margin-bottom:60px" :class="{page: true, lastPage: index===infoList.length - 1}">

   
      <div style="position:relative">
        <span style="position:absolute;top:8px;right:70px;color:#d43030;">扫码获取体检结果</span>
        <img style="width:62px;height:62px;position:absolute;top:-8px;right:5px"  src="https://tuan-app01.oss-cn-shenzhen.aliyuncs.com/qrcode_for_gh_f60b356ab9db_258.jpg"  alt="">
        <img v-if="selectStamp && stampFile != ''" style="width:190px;height:190px;position:absolute;bottom:5px;right:25px"  :src="stampFile"  alt="">
 
      <div style="text-align: center; color:#505050; font-size: 30px; font-weight: bold; margin-bottom: 20px; ">
          幼儿园健康体检报告单
        </div>
        <div style="width: 100%; display: flex;flex-wrap: wrap; margin-bottom: 20px">
          <div class="item">学校：{{ item.schoolVO.name }}</div>
          <div class="item">身份证号：{{ item.idNo }}</div>
          <div class="item">
            <div style="display: flex;justify-content: space-between">
              <div class="item_item"
                   style="width: 50%; height: 100%;  border-right: 1px solid #888">
                年级：{{ item.grade }}
              </div>
              <div class="item_item" style="width: 43%; height: 100%; padding-left: 10px;">
                班级：{{ item.classRoomNo }}
              </div>
            </div>

          </div>
          <div class="item">姓名：{{ item.name }}</div>
          <div class="item">出生日期：{{ $dateString(item.birthDate) }}</div>
          <div class="item">
            <div style="display: flex; justify-content: space-between">
              <div class="item_item" style="width: 50%; height: 100%;">
                年龄：{{ item.age }}
              </div>
              <div class="item_item" style="width: 43%; height: 100%; padding-left: 10px;">
                性别：{{ $findCode(Gender, item.sex) }}
              </div>
            </div>


          </div>
          <div class="item">学号：{{ item.studentId }}</div>
          <div class="item">检查日期：{{ $dateString(item.checkDate) }}</div>
          <div class="item">
            <div style="display: flex; justify-content: space-between">
              <div style="width: 50%; height: 100%;  border-right: 1px solid #888">
                民族：{{ findNationalityCode($store.state.nationalityList, item.nationalityCode) }}
              </div>
              <div style="width: 43%; height: 100%; padding-left: 10px;">
                城乡：{{ $findCode(CityType, item.cxType) }}
              </div>
            </div>

          </div>

          <div class="item">家长姓名：{{ item.parentName }}</div>
          <div class="item">家长电话：{{ item.parentPhone }}</div>
          <div class="item">家庭住址：{{ item.parentAddress }}</div>
          <div class="item">体检年度：{{ item.year }}</div>
          <div class="item">
            体检单位：{{ getOrgName(item.schoolVO.organizationId) }}
          </div>
          <div class="item"></div>

        </div>

        <div style="width: 100%; display: flex;flex-wrap: wrap">
          <div class="item"> 营养状况：{{ yeyYYInfo(item) }}</div>
          <span style="display: none">{{ item.i = 0 }}</span>
          <template v-for="x in checkItemList">
            <div v-if="isEnabled(item,x.checkItemVO.code)" class="item">
              <span style="display: none">{{ item.i++ }}</span>
              <span style="margin-right: 6px">{{ x.checkItemVO.name }}</span>
              <span>{{ item[x.checkItemVO.code] }}</span>
              <template v-if="x.checkItemVO.hasSub">
                <span style="margin-left: 2em">{{ x.checkItemVO.subItemDescription }}</span>
                <span>{{ item[x.checkItemVO.subCode] }}</span>
              </template>


              <template v-if="x.checkItemVO.code === 'p002'">
                <div
                    style="width: 43%; height: 100%; padding-left: 10px;  display: inline-block; text-align: center; border-left: 1px solid #888; margin-left: 10px">
                  {{ yeySGInfo(item) }}
                </div>
              </template>
              <template v-if="x.checkItemVO.code === 'p003'">
                <div
                    style="width: 43%; height: 100%; padding-left: 10px;  display: inline-block; text-align: center; border-left: 1px solid #888; margin-left: 20px">
                  {{ yeyTZInfo(item) }}
                </div>
              </template>
            </div>
          </template>


          <div v-for="x in (3 - (item.i + 1) %3)" class="item"></div>

        </div>


        <div class="result" style="width: 99.2%; border: 2px solid #888; height: 100px;">
          结果：{{ item.result }}
        </div>
        <div class="result" style="width: 99.2%; border: 2px solid #888; border-top: transparent; height: 100px;">
          建议：{{ item.advice }}
        </div>

        <!--        <div class="result" style="width: 99.2%; border: 2px solid #888; border-top: transparent; height: 100px;">-->
        <!--          体检单位：{{ getOrgName(item.organizationId) }}-->
        <!--        </div>-->
      </div>

    </div>


  </div>
  <!--  <div class="item"> 胸透：{{ isEnabled(item, "p058") ? item.p058 : 未做 }}</div>-->
</template>

<script>
import * as yey from "@/api/gen/yEYMedicalInformationApi"
import * as organizationYEYCheckItemStatusApi from "@/api/gen/organizationYEYCheckItemStatusApi"
import {yeySGInfo, yeyTZInfo, yeyYYInfo} from "@/plugin/dataInfo";
import {isEnabled} from "@/views/physicalInfomation/util";
import * as organizationApi from "@/api/gen/organizationApi";

export default {
  name: "YeyHealthForm",
  data() {
    return {
      ids: [],
      infoList: [],
      Gender: '',
      CityType: '',
      checkItemList: [],
      orgId:"",
      stampFile:"",
      selectStamp:true
    }
  },

  computed: {},
  async mounted() {
    this.ids.push(this.$route.query.id)
    await this.list()
    await this.getCheckItemList()
    await this.getOrgList()
    this.Gender = await this.$baseData('Gender')
    this.CityType = await this.$baseData('CityType')

  },
  methods: {
    isEnabled,
    yeySGInfo,
    yeyTZInfo,
    yeyYYInfo,
    printClick() {
      window.print()
    },

    async list() {

      let ids = this.ids.join(',')

      const res = await yey.getByIdc({ids: ids.split(",")})
      this.infoList = res
      this.orgId = res[0].organizationId
    },

    async getCheckItemList() {

      let res = (await organizationYEYCheckItemStatusApi.list({
        pageSize: 500,
        organizationId: this.$store.getters.organizationId
      })).data;
      this.checkItemList = this._.chain(res).sortBy(item => item.sortKey).value()

    },

    findNationalityCode(arr, code) {
      return arr.filter(item => item.code === code)[0]?.name
    },

    checkItemStatus(code) {
      return this.checkItemList?.filter(item => {
        if (item.code === code) {
          return item
        }
      }).pop()?.status === 1
    },
    closePage() {
      window.close();

    },
    async getOrgList() {
      let {data} = await organizationApi.list()
      this.orgList = data

      let org = data.find(a=>a.id == this.orgId)
      if(org.stampFile != undefined && org.stampFile != null){
        this.stampFile = org.stampFile
      }
    },
    getOrgName(d) {
      return this.orgList?.filter(item => item.id === d)[0]?.name || ''

    }


  }
}
</script>

<style scoped>
.break {
  /*visibility: hidden;*/
}

.item {
  width: 33%;
  padding-left: 10px;
  box-sizing: border-box;
  box-shadow: 2px 0 0 0 #888,
  0 2px 0 0 #888,
  2px 2px 0 0 #888, /* Just to fix the corner */ 2px 0 0 0 #888 inset,
  0 2px 0 0 #888 inset;
}


.result {
  padding-left: 10px;
  box-sizing: border-box;
}


@media print {
  .none {
    display: none;
  }


  @page {
    size: A4;
    margin: 0;

  }

  .page {

    margin: 0 auto !important;
    margin-top: 30px !important;
    page-break-after: always;

    width: 85% !important;
  }

  .lastPage {
    page-break-after: avoid !important;
  }


}


.page {
  box-sizing: border-box;
  width: 220mm;
  margin: 0 auto;
  page-break-after: always;
}


</style>
















