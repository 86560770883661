<template>

  <el-dialog @close="close" :visible='true' :title="title">

    <el-form ref="form" :model="form" label-width="80px" :rules="rules">

      <el-form-item prop="name" label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item prop="cron" label="cron表达式">
        <el-input v-model="form.cron"></el-input>
      </el-form-item>

      <el-form-item prop="scriptId" label="脚本ID">

        <el-select v-model="form.scriptId" placeholder="请选择" style="width: 100%">
          <el-option
              v-for="item in scriptList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item prop="arguments" label="脚本参数">
        <el-input v-model="form.arguments" type="textarea" :rows="10"></el-input>
      </el-form-item>


      <el-form-item prop="status" label="状态">
        <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>


    </el-form>


    <template slot="footer">

      <div>

        <el-button type="primary" @click="submit">确定</el-button>
        <el-button type="primary" @click="close">取消</el-button>

      </div>


    </template>


  </el-dialog>


</template>

<script>
import * as cronJobApi from "@/api/gen/cronJobApi"
import * as scriptApi from "@/api/gen/scriptApi"
import OrganizationSelect from "@/components/OrganizationSelect";

export default {
  name: "Edit",
  components: {OrganizationSelect},
  props: {
    status: {
      type: String
    },

    row: {

      type: Object
    }
  },

  data() {
    return {
      form: {
        "id": "",
        "name": "",
        "cron": "",
        "scriptId": "",
        "arguments": "",
        "status": 1,

      },
      rules: {
        "name": [this.$ruleBulder().required().build()],
        "cron": [this.$ruleBulder().required().build()],
        "scriptId": [this.$ruleBulder().required().build()],
      },
      scriptList:[]


    }
  },
  beforeMount() {
    if (this.row.id) {


      this.form = {...this.row};
    }

    this.getScriptList()

  },


  computed: {
    title() {
      if (this.status === 'add') {
        return "添加";
      } else if (this.status === 'edit') {
        return "编辑";
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async submit() {
      let result = await this.$refs['form'].validate();
      console.log(result)
      if (this.status === 'add') {
        let resp = await cronJobApi.create({...this.form});
        this.close();
      } else if (this.status === 'edit') {
        let resp = await cronJobApi.update({...this.form});
        this.close();
      }
    },

    async getScriptList(){
    const {data:res} = await scriptApi.list()
      this.scriptList = res
    }

  },

}
</script>

<style scoped>

</style>
