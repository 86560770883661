<template>
  <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close=true


      :visible="true"
      center
      width="45%"
      @close="close"
  >
    <div class="container">
      <div class="title">喜讯来学生体检评价管理系统</div>
      <el-form ref="form" :model="form" :rules="rules" label-position="top" label-width="80px">
        <el-form-item prop="oldPassword">
          <el-input v-model="form.oldPassword" placeholder="旧密码" show-password></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input v-model="form.newPassword" placeholder="新密码" show-password></el-input>
        </el-form-item>
        <el-form-item prop="newPassword2">
          <el-input v-model="form.newPassword2" placeholder="再次输入新密码" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close">取消</el-button>
          <el-button style="margin-left: 26px" type="primary" @click="updatePassword">确认</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import * as userApi from "@/api/gen/userApi"

export default {
  name: "updatePassword",
  data() {

    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.newPassword2 !== '') {
          this.$refs.form.validateField('newPassword2');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      form: {
        userId: this.$store.getters.userId,
        oldPassword: '',
        newPassword: "",
        newPassword2: '',
      },
      rules: {
        oldPassword: [
          {required: true, messages: "请输入旧密码", trigger: 'blur'}
        ],
        newPassword: [
          {validator: validatePass, trigger: 'blur'}
        ],
        newPassword2: [
          {validator: validatePass2, trigger: 'blur'}

        ]
      }
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close')
    },

    async updatePassword() {
      let result = this.$refs['form'].validate
      if (!result) return
     await userApi.changeMyPassword({...this.form})
      this.$message.success('修改成功')
      this.$emit('close')
    }

  },
}

</script>

<style scoped>
.container {
  height: 70vh;
  background: url("../../assets/images/update-bg.png") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 33px;
  line-height: 150%;
  font-weight: bold;
  margin-bottom: 30px;
}

.el-input {
  width: 380px;
}

.el-button {
  width: 176px;
}

/deep/ .el-dialog__header,
/deep/ .el-dialog--center .el-dialog__body,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
</style>