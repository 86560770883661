import Vue from "vue";

export default {

    install(Vue) {

        Vue.config.errorHandler = function (err, vm, info) {
            console.error(err);
            console.error(info);

            if (err === false) {

                return;
            }
            // console.log(err, vm, info)
            vm.$message.warning(err);

        }    }

}
