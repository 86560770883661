<template>
  <el-menu
      :collapse="isCollapse"
      :collapse-transition="false"
      :default-active=this.path
      active-text-color="#1890FF"
      background-color="#001529"
      class="el-menu-vertical-demo"
      unique-opened
      router
      text-color="#fff"
      @select="select"
  >
    <el-menu-item :style="{paddingLeft:(isCollapse? '10px' : '10px')}" class="logo">
      <div :style="{fontSize:(isCollapse? '14px':'17px')}">
        <img v-show="!isCollapse" style="width: 80px; height: 23px" src="../assets/images/logo1.png">
        {{logo}}
      </div>
    </el-menu-item>
    <menu-item v-for="(item,index) in menu" :key="index" :children="item.children" :icon="item.icon" :name="item.name" :path="item.path"></menu-item>

  </el-menu>
</template>

<script>
import MenuItem from "@/components/MenuItem";

export default {
  name: "Menu",
  props: ["isCollapse"],
  components: {MenuItem},
  data(){
    return {
      a:''
    }
  },

  computed: {
    menu() {
      let menu = this.$store.getters.permissionMenus
      if(this.$store.getters.isSuperAdmin){
        return menu
      }else{
        for (let i in menu) {
          if(menu[i].name === '系统维护'){
            menu.splice(i, 1)
          }
        }
        return menu
      }



    },
    path() {
      return this.$route.path;
    },
    logo(){
      if(this.isCollapse) {
        return '喜讯来'
      }else {
        return  '喜讯来学生体检评价管理系统'
      }
    }

  },
  methods:{
    click(){
      this.isCollapse = !this.isCollapse
    },
    select(index){
      this.$emit("current", index)
    }

  }

}
</script>

<style scoped>

.logo{
  pointer-events: none;
  background-color: #3C90F7 !important;
  height: 60px;
  font-weight: bold;
  font-size: 17px;
}
.logo i {
  color: #f0f0f0;
  font-style: inherit;
  font-family: Microsoft YaHei sans-serif;
}


</style>
