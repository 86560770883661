//体检信息 字段为空或null时 '未做'
export function f1(value) {
    if (value === ' ') {
        return "未做";
    }
    if (value) {
        return value;
    }
    return "未做";
}

//百分比
export function percent(str) {
    if(str == 0) return 0+"%"
    if (str) {
        return (parseFloat(str) * 100).toFixed(2) + "%";
    } else {
        return ''
    }
}


export default {


    install(Vue) {

        Vue.filter("f1", f1);
        Vue.filter("percent", percent);


    }


}
