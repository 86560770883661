<template>
  <el-select :value="myValue" v-bind="myAttr" v-on="myListener" style="width: 100%">
    <el-option v-for="item in items" :key="item.value" :value="item.value" :label="item.label"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "BaseSelect",

  props: {
    value:{

    },
    getValue: {
      type: Function,
      default:  (v) => (v.id)
    },

    getLabel: {
      type: Function,
      default:  (v) => v.name
    },
    searchFn: {

      type: Function,
      default:  (v) => []

    }
  },

  async beforeMount() {
    console.log(this.$attrs)

    let tmp = await (this.searchFn());

    while (!_.isArray(tmp)) {
      tmp = tmp.data;
    }
    for(let i in tmp){
      if(tmp[i].code === 'super-admin'){
        tmp.splice(i, 1);
      }
    }
    this.items = tmp.map(item => ({value: this.getValue(item), label: this.getLabel(item), _source: item}));
    this.done();

  },


  data() {
    return {

      status: "init",
      items: []
    }
  },

  methods: {
    getItems() {
      return this.items;
    },

    done(){

      this.status = "done";

      this.$emit("intiDone")

    }
  },
  computed: {
    myValue() {
      console.log("-----", this.value);
      if (this.items.length <= 0) {
        return "";
      }

      return this.value;
    },
    myAttr() {

      return this.$attrs


    },


    myListener() {

      let old = this.$listeners.input


      this.$listeners.input = (v) => {


        console.log("收到输入事件", v);

        if (this.status === 'init') {

          old(this.value);
        } else {

          old(v);
        }

      }

      console.log(this.$listeners);

      return this.$listeners;

    }

  }
}
</script>

<style scoped>

</style>
