<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add' ? '添加': '编辑'"
        :visible="true"
        center
        width="60%"
        @close="close"
    >

      <el-form ref="form" :model="form" align="center" label-width="auto">
        <el-form-item prop="name" label="标题">
          <el-input v-model="form.name"></el-input>
        </el-form-item>


        <el-form-item prop="status" label="状态">
          <Data-select v-model="form.status" parent-code="statusType"></Data-select>
        </el-form-item>


        <el-form-item prop="remarks" label="备注">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item>


        <div style="display: flex; margin-bottom: 20px">
          <el-upload
              ref="abc"
              :action="actionPath"
              :before-upload="beforeUpload"
              :data="{'organizationId':$store.getters.organizationId,'filePath': fileName,'storageType':form.storageType}"
              :on-success="handSuccess"
              class="upload-demo"
              :on-remove="handleRemove"
              multiple
              :file-list="fileList"
              name='file'>
            <el-button size="small" type="primary">文件上传</el-button>

          </el-upload>
        </div>


      </el-form>
      <!-- Two-way Data-Binding -->
      <div style="height: 300px">
        <quill-editor
            id="editor"
            style="height: 100%"
            ref="myQuillEditor"
            v-model="form.content"
        />
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="submit" type="primary">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>
    <upload v-if="uploadShow" @close="uploadShow = !uploadShow" @getFileId="getFileId"></upload>
  </div>

</template>

<script>

import {quillEditor} from 'vue-quill-editor'
import DataSelect from '@/components/DataSelect'
import upload from './upload'

import * as announcementApi from '@/api/gen/announcementApi'
import * as dataMenu from "@/assets/js/data";

export default {
  name: 'edit',
  components: {
    DataSelect,
    quillEditor,
    upload
  },
  data() {
    return {
      menu: dataMenu,
      form: {
        content: '',
        name: '',
        status: 0,
        remarks: '',
        fileIds: [],
        organizationId: this.$store.getters.organizationId,
        storageType: "2"
      },

      uploadShow: false,
      editorOption: [],
      fileName:'',
    }
  },
  props: {
    row: {}
  },
  computed: {
    status() {
      return this.row.id ? 'edit' : 'add'

    },
    actionPath() {


      return process.env.VUE_APP_API + "/file/upload";
    },

    storageTypeMenu() {
      return (this.menu.StorageType)
    },
    fileList() {
      return this.row?.fileInfoVOList?.map(item =>{
        return {
          name: item.fileName,
          url:process.env.VUE_APP_API + "/file/upload?id=" + item.id,
          id:item.id
        }
      })
    }
  },
  mounted() {
    Object.assign(this.form, this.row)

    // this.editorOption = ['bold', 'italic', 'underline', 'strike'];
    //
    // this.quill = new Quill('#editor', {
    //   modules: {
    //     toolbar: this.editorOption
    //   }
    // })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    uploadDialog() {
      this.uploadShow = true
    },

    handSuccess(res, file) {
      this.form.fileIds = [...this.form.fileIds, res.data]
    },

    beforeUpload(file) {
      this.fileName = file.name;

      return new Promise((resolve, reject) => {

        this.$nextTick(x => {


          resolve(true);

        })
      })


    },
    handleRemove(file, fileList) {
      this.form.fileIds.splice(file.id,1)
    },
    async submit() {

      if (this.status === 'add') {
        //添加
        await this.add()
      } else {
        //修改
        await this.edit()
      }

    },


    async add() {
      this.$message.success("添加成功")
      await announcementApi.create(this.form)
      this.$emit("update")
      this.$emit("close");


    },
    async edit() {
      await announcementApi.update(this.form)
      this.$message.success("修改成功")
      this.$emit("update")
      this.$emit("close");

    },

  },
}
</script>

<style scoped>
/deep/ .ql-container {
  height: 85% !important;
}

/deep/ .ql-video {
  display: none !important;
}
</style>
