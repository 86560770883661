<template>
  <div>
    <!--  项目结果--修改-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add'? '添加项目结果建议':'修改项目结果建议'"
        :visible="true"
        center
        width="40%"
        @close="close"
    >
      <el-form ref="form" :model="form" :rules="rules" align="center" label-width="100px">
        <el-form-item label="项目结果：" prop="result">
          <el-input v-model="form.result" disabled></el-input>
        </el-form-item>
        <el-form-item label="结果建议：" prop="advice">
          <el-input v-model="form.data"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update('form')">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import * as testResultAndAdviceApi from "@/api/gen/testResultAndAdviceApi";

export default {
  name: "suggest-edit",
  props: {
    row: {},
    current: {}
  },


  data() {
    return {
      form: {
        id: '',
        result: '',
        advice: '',
        type: '1',
        data: ''
      },


      rules: {

        result: [
          {required: true, message: '请选择项目结果', trigger: 'blur'},
        ],
        // advice: [
        //   {required: true, message: '请输入结果建议', trigger: 'blur'},
        // ]
      },
    }
  },


  computed: {

    status() {


      return this.current.id ? "edit" : "add"
    }

  },

  mounted() {
    Object.assign(this.form, this.row)
    if (!this.form.advice) {
      return this.form.advice = []
    }

  },

  methods: {
    close() {
      this.$emit("close")
    },
    async update(data) {

      let result
      if (this.status === 'add') {


        result = await this.add()
      } else {

        result = await this.edit()
      }
      if (result) {
        this.$emit("update")
        this.$emit("close");

      }
    },


    async add() {

      let valid = await this.$refs.form.validate()

      if (valid) {
        if (!this.form.data) {
          this.$message.error('结果建议不能为空')
          return
        }

        this.form.advice.push(this.form.data)
        let obj = {
          type: 1,
          id: this.form.id,
          result: this.form.result,
          advice: this.form.advice
        }
          await testResultAndAdviceApi.update({...obj})
        this.$message.success('添加成功')
        return valid
      } else {
        return false
      }

    },
    async edit() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        let index = this.form.advice.indexOf(this.form.data)
        this.form.advice.splice(index, 1, this.form.data)
        let obj = {
          type: 1,
          id: this.form.id,
          result: this.form.result,
          advice: this.form.advice
        }
        await testResultAndAdviceApi.update({...obj})
        this.$message.success('修改成功')
        return valid
      } else {
        return false
      }
    },


  }
}
</script>

<style scoped>

</style>