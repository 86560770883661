<template>
  <div>

    <el-card style="margin: 20px">

      <el-form ref="form" inline label-width="80px">
        <el-form-item label="单位：">
          <base-select v-model="form.organizationId" :search-fn="organizationApi.list" clearable
                       :disabled="!$hasPermission('unit.allOrganization')"></base-select>
        </el-form-item>
        <el-form-item>
          <el-button v-show="$hasPermission('fileManagement.list')" type="primary" @click="loadFileInfo()">搜索
          </el-button>
        </el-form-item>
      </el-form>
      <el-button v-show="$hasPermission('fileManagement.create')" type="primary" @click="add">新增</el-button>

      <el-table
          :data="fileInfo"
          style="width: 100%">
        <el-table-column
            label="单位"
        >
          <template slot-scope="scope">
            <div>{{ findName(scope.row.organizationId) }}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="文件名"
            prop="fileName"
        >
        </el-table-column>
        <el-table-column
            label="文件大小"
            prop="fileSize"
        >

          <template slot-scope="scope">
            <div>{{ convertToKb(scope.row.fileSize) }}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            prop="createTime">
        </el-table-column>
        <el-table-column align="center" header-align="center" label="操作">

          <template slot-scope="scope">
            <el-button v-show="$hasPermission('fileManagement.download')" plain size="mini" type="primary"
                       @click="download(scope.row.id);">下载
            </el-button>

            <el-button v-show="$hasPermission('fileManagement.download')" style="color: red" plain size="mini"
                       type="danger" @click="remove(scope.row)">删除
            </el-button>


          </template>
        </el-table-column>
      </el-table>

      <pagination :pageNo.sync="form.pageNo" :pageSize.sync="form.pageSize" :total.sync="total"></pagination>
    </el-card>


    <edit v-if="isEdit" @close="()=>this.isEdit=!this.isEdit" @update="loadFileInfo()"></edit>

  </div>
</template>

<script>
import edit from './edit'
import Pagination from "@/components/Pagination"
import BaseSelect from "@/components/BaseSelect"
import OrganizationSelect from '@/components/OrganizationSelect'
import * as fileInfoApi from "@/api/gen/fileInfoApi"
import * as organizationApi from "@/api/gen/organizationApi"


export default {
  name: "fileManagement",
  components: {edit, OrganizationSelect, Pagination, BaseSelect},
  data() {
    return {
      organizationApi,
      form: {
        pageNo: 1,
        pageSize: 10,
        organizationId: this.$store.getters.organizationId
      },
      total: 0,
      isEdit: false,
      fileInfo: [],
      organizationList: []
    }
  },


  watch: {
    form: {
      deep: true,
      handler() {
        this.loadFileInfo()
      }
    }
  },
  async mounted() {
    await this.loadFileInfo()
    await this.loadOrganization()
  },
  computed: {},
  methods: {


    add() {
      this.isEdit = true
    },

    async loadFileInfo() {
      const res = await fileInfoApi.list(this.form)
      this.fileInfo = res.data
      this.total = res.count
      console.log(this.fileInfo)
    },

    //删除
    async remove(data) {

      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await fileInfoApi.deleteById({id: data.id})
        this.$message.success('删除成功')
        await this.loadFileInfo()
      }).catch(() => {
      })


    },
    //下载
    download(id) {
      let url = process.env.VUE_APP_API + "/file/download?id=" + id;
      this.$FileSaver.saveAs(url)
    },
    convertToKb(i) {
      return Math.round(parseFloat(i) / 1000) + 'kb'
    },


    //机构列表
    async loadOrganization() {
      const res = (await organizationApi.list({pageSize: 1000000}));
      this.organizationList = res.data
    },


    findName(id) {
      for (let x of this.organizationList) {
        if (x.id === id) {
          return x.name
        }
      }
    }


  },
}
</script>

<style scoped>
/deep/ .el-form--inline .el-form-item__label {
  display: unset;
}
</style>