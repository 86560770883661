<template>
  <div>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible="true"
        width="45%"
        center
        :show-close=false
        @close="close"
        :title="this.status === 'add'? '添加':'编辑'"
    >
      <div class="info-form">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" align="center">
          <el-form-item label="角色名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="机构类型">
            <!--            <el-input v-model="form.orgTypes" :disabled="status==='edit'"></el-input>-->
            <data-select type="string" v-model="form.orgTypes" parent-code="OrganizationType" multiple></data-select>
          </el-form-item>
          <el-form-item label="编码">
            <el-input v-model="form.code" :disabled="status==='edit'"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <my-button type="primary" @click="update">确 定</my-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as roleApi from "@/api/gen/roleApi";
import DataSelect from "@/components/DataSelect";

export default {
  name: "edit",
  components: {DataSelect},
  props: {
    row: {}

  },
  data() {
    return {
      form: {
        name: '',
        code: '',
        orgTypes: []
      },
      rules: {
        name: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
        ],
      }
    }
  },
  mounted() {
    Object.assign(this.form, this.row)
  },
  computed: {
    status() {
      return this.row.id ? 'edit' : 'add'
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },


    async update(done) {
      try {
        let result
        if (this.status === "add") {
          result = await this.add()
        } else {
          result = await this.edit()
        }
        if (result) {
          this.$emit("update")
          this.$emit("close");
        }
      } finally {

        done()
      }
    },
    async add() {
      let clone = {...this.form}
      let valid = await this.$refs.form.validate()
      if (valid) {
        await roleApi.create({...clone})
        this.$message.success("添加成功")

        return valid
      } else {
        return false
      }

    },
    async edit() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        await roleApi.update(this.form)
        this.$message.success("修改成功")

        return valid
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
