import request from '@/utils/request'

export function captcha(params) {

  return request({
    url: '/auth/captcha',
    method: 'GET',
    params})
}

   

export function currentUser(params) {

  return request({
    url: '/auth/currentUser',
    method: 'GET',
    params})
}

   

export function login(data) {

  return request({
    url: '/auth/login',
    method: 'POST',
    data})
}

   

export function loginByUserId(data) {

  return request({
    url: '/auth/loginByUserId',
    method: 'POST',
    data})
}

   