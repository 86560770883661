<template>
  <div>
    <div v-if="showAdmin" style="display: flex;justify-content: space-between; margin: 20px; height: 450px">
      <el-card style=" width: 49%" class="box-card">
        <div
            style="display: flex; justify-content: space-between; border-bottom: 1px solid rgba(229, 229, 229, 1); padding: 20px">
          <div>学校数量</div>
          <div class="font">{{ this.schoolCount }}</div>
        </div>

        <div style="height: 300px;display: flex; justify-content: center">
          <div style="width: 300px;">
            <echarts :key="formDate" :option="option"></echarts>
          </div>
          <div style="display: flex;justify-content: center; align-items: center; line-height: 1.5">
            <div>
              <div style="white-space: nowrap">
                <span class="round" style="background-color: rgba(80, 155, 248, 1)"></span>
                <span class="name">幼儿园</span>
              </div>
              <div>
                <span class="round" style="background-color: rgba(85, 191, 192, 1)"></span>
                <span class="name">中小学</span>
              </div>
            </div>
            <div>
              <div class="percent">{{ YeyPercent }}%</div>
              <div class="percent">{{ ZxxPercent }}%</div>
            </div>
            <div>
              <div class="total">{{ statistics.dataCount[1] }}</div>
              <div class="total">{{ statistics.dataCount[0] }}</div>
            </div>

          </div>
        </div>

      </el-card>
      <div style=" width: 49%; display: flex;justify-content: space-between; flex-direction: column" class="box-card">

        <el-card>
          <div class="flex_sb_c height90 item">
            <div class="flex_ai_c">
              <span>体检机构</span>
              <span class="font ml-13">{{ statistics.typeCount[0] }}</span>
            </div>
            <div class="line"></div>
            <div class="flex_ai_c">
              <span>体检数据数量</span>
              <span class="font ml-13">{{ statistics.dataCount[2] }}</span>
            </div>
          </div>
        </el-card>
        <el-card>
          <div class="flex_sb_c height90 item">
            <div>行政单位数量</div>
            <div class="font">{{ statistics.typeCount[1] ? statistics.typeCount[1] : 0 }}</div>
          </div>
        </el-card>
        <el-card>
          <div class="flex_sb_c height90 item">
            <div class="flex_ai_c">
              <span>正在使用的单位</span>
              <span class="font ml-13">{{ validCount[1] }}</span>
            </div>
            <div class="line"></div>
            <div class="flex_ai_c">
              <span>到期停用的单位</span>
              <span class="font ml-13">{{ validCount[0] }}</span>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <div v-if="showOrg" style="display: flex;justify-content: space-between; margin: 20px; height: 200px">
      <el-card style=" width: 49%" class="box-card">
        <div
            style="display: flex; justify-content: space-between; border-bottom: 1px solid rgba(229, 229, 229, 1); padding: 20px">
          <div>学校数量</div>
          <div class="font">{{ this.schoolCount }}</div>
        </div>
        <div class="flex_sb_c height90 item" style="padding: 0 80px;">
          <div class="flex_ai_c">
            <span>幼儿园</span>
            <span class="font ml-13">{{ statistics.typeCount[4] }}</span>
          </div>
          <div class="line"></div>
          <div class="flex_ai_c">
            <span>中小学</span>
            <span class="font ml-13">{{ statistics.typeCount[3] }}</span>
          </div>
        </div>

      </el-card>
      <el-card style=" width: 49%" class="box-card">

        <div
            style="display: flex; justify-content: space-between; border-bottom: 1px solid rgba(229, 229, 229, 1); padding: 20px">
          <div>学生体检数量</div>
          <div class="font">{{ statistics.dataCount[2] }}</div>
        </div>
        <div class="flex_sb_c height90 item" style="padding: 0 80px;">
          <div class="flex_ai_c">
            <span>幼儿园</span>
            <span class="font ml-13">{{ statistics.dataCount[1] }}</span>
          </div>
          <div class="line"></div>
          <div class="flex_ai_c">
            <span>中小学</span>
            <span class="font ml-13">{{ statistics.dataCount[0] }}</span>
          </div>
        </div>


      </el-card>


    </div>


    <el-card class="box-card">
      <div>
        <div>公告信息</div>
        <el-divider></el-divider>
      </div>
      <div>
        <div v-if="tableData.length">
          <template v-for="item in tableData">
            <el-card style="margin-bottom: 20px">
              <div style="font-size: 22px; color: #57C9F7">{{ item.name }}</div>
              <div style="display: flex; font-size: 12px; margin-top: 10px">
                <div style="margin-right: 5px">{{ item.createByName }}</div>
                <div>{{ item.createTime }}</div>
              </div>
              <el-divider></el-divider>
              <div v-html="item.content"></div>

              <div v-if="item.fileInfoVOList.length">
                <el-divider></el-divider>
                <div v-for="file in  item.fileInfoVOList">
                  <el-link @click="download(file.id)">{{ file.fileName }}</el-link>
                </div>
              </div>
            </el-card>
          </template>
        </div>
        <div v-else>
          <el-empty :image-size="200"></el-empty>
        </div>
      </div>

    </el-card>
    <el-dialog
        v-if="inDate <=30"
        title="提示"
        :visible="inDateDialog"
        width="30%"
        top="30vh"
        :show-close="false"
    >

      <div v-if="inDate>0">有效期：{{ inDate }}天</div>
      <div v-else-if="inDate===0">有效期：今天到期,请及时处理,到期后部分功能将不能使用。</div>
      <div v-else-if="inDate<0">有效期：已过期{{ Math.abs(inDate) }}天,部分功能将不能使用。</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="inDateDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import * as announcementApi from "@/api/gen/announcementApi"
import * as fileInfoApi from "@/api/gen/fileInfoApi";
import * as statisticApi from "@/api/gen/statisticApi";
import echarts from "@/components/Echarts";

export default {
  name: "announcementInfomation",
  components: {echarts},
  data() {
    return {
      inDateDialog: true,
      tableData: [],
      statistics: {
        dataCount: {'0': 0, '1': 0, '2': 0},
        typeCount: {'0': 0, '1': 0, '2': 0, '3': 0, '4': 0},
        validCount: {'0': 0, '1': 0}
      },
      option: {},
      formDate: new Date().getTime().toString()
    }
  },
  async created() {

  },
  async beforeMount() {
    await this.getStatisticsList()

    await this.list()
    this.getOptions()

  },
  computed: {
    inDate() {
      return this.$store.getters.inDate
    },
    typeCount() {
      return [
        {value: this.statistics.typeCount[3], name: '中小学'},
        {value: this.statistics.typeCount[4], name: '幼儿园'},
      ]
    },
    validCount() {
      return Object.values(this.statistics.validCount)
    },

    schoolCount() {
      return this._.sum([this.statistics.typeCount[3], this.statistics.typeCount[4]])
    },

    YeyPercent() {
      if (!((this.statistics.dataCount[1] || this.statistics.dataCount[2]))) {
        return 0
      } else {
        return (this._.divide(this.statistics?.dataCount[1] / this.statistics?.dataCount[2]) * 100)?.toFixed(2)
      }

    },
    ZxxPercent() {
      if (!((this.statistics.dataCount[0] || this.statistics.dataCount[2]))) {
        return 0
      } else {
        return (this._.divide(this.statistics?.dataCount[0] / this.statistics?.dataCount[2]) * 100)?.toFixed(2)
      }

    },

    showAdmin() {
      return (this.$store.getters.isAdminOrg || this.$store.getters.isSuperAdmin)
    },
    showOrg() {
      return this.$store.getters.organizationType === 0 || this.$store.getters.organizationType === 3 || this.$store.getters.organizationType === 4;
    }


  },
  methods: {
    close() {
      this.inDateDialog = !this.inDateDialog
    },
    async list() {
      let res = await announcementApi.list()
      this.tableData = res.data.filter(item => item.status === 1)
    },

    async getFileList() {
      this.fileInfoList = (await fileInfoApi.list({organizationId: this.$store.getters.organizationId})).data
    },
    download(id) {
      let url = `${process.env.VUE_APP_API}/file/download?id=${id}`
      window.open(url)
    },


    async getStatisticsList() {
      if (this.showAdmin) {
        this.statistics = await statisticApi.admin()
      } else if (this.showOrg) {
        this.statistics = await statisticApi.org({id: this.$store.getters.organizationId})
      }


    },

    getOptions() {

      this.option = {

        tooltip: {
          trigger: 'item'
        },

        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            width: 300,
            color: ['#509BF8', '#55BFC0'],
            avoidLabelOverlap: false,
            legend: {},

            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {//饼图中间文字设置
              normal: {
                show: true,
                position: 'center',
                color: '#9A9EBA',
                formatter: '{active|学校总数量}' + '\n\r' + '{schoolCount|' + this.schoolCount + '}',
                rich: {
                  active: {
                    fontFamily: "微软雅黑",
                    fontSize: 14,
                    color: '#6c7a89',
                    lineHeight: 30,
                  },
                  schoolCount: {
                    fontSize: 20,
                    fontFamily: "微软雅黑",
                    color: '#454c5c'
                  },

                },
              },
              emphasis: {//中间文字显示
                show: true,
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.typeCount
          }
        ]
      }

    }


  },

  watch: {
    schoolCount(n, o) {
      console.log(n)
    }
  },

}
</script>

<style scoped>
/deep/ .el-divider {
  margin: 10px 0 !important;
}

/*/deep/canvas{*/
/*  width: 400px !important;*/
/*}*/

.item {
  position: relative;
  padding: 0 35px 0 35px;
}


.name {

}

.percent {
  margin: 0 20px;
}

.total {
  color: #2a82e4
}

.round {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.line {
  position: absolute;
  left: 50%;
  width: 1px;
  height: 40px;
  background-color: #e9e9e9;

}

.flex_sb_c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_ai_c {
  display: flex;
  align-items: center;
}

.height90 {
  height: 90px;
}

.font {
  color: rgba(42, 130, 228, 1);
  font-size: 30px;
}

.ml-13 {
  margin-left: 13px;
}
</style>
