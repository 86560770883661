import router from "@/router";
function print(id = '#exportTable', config) {

    if (!config) {
        config = {
            clearClass: false,
            clearStyle: false,



        }
    }


    let {clearClass, clearStyle} = config;

    function process(dom){

        if (!dom) return;
        if (clearClass) {dom.classList = []}
        if (clearStyle) {dom.style=[]}
        [...dom.childNodes].forEach(process)

    }



    let element = document.querySelector(id)
    let cloneElement = element.cloneNode(true)


    let thead = cloneElement.querySelector('.el-table__header-wrapper').querySelector('thead')
    thead.firstChild.lastChild.remove()

    let tbody = cloneElement.querySelector('.el-table__body-wrapper').querySelector('tbody')
    let table = document.createElement('table')

    table.appendChild(thead)
    table.appendChild(tbody)






    let div = document.createElement('div')
     div.appendChild(table)


    process(div)

    console.log(div)



    localStorage.setItem('table', div.innerHTML)


   let url =  router.resolve({path: '/printTable'})
    window.open(url.href,"_blank")

}

export default {
    install(Vue) {
        Vue.prototype.$print = print
    }


}
