<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add' ? '添加': '编辑'"
        :visible="true"
        center
        width="45%"
        @close="close"
    >
      <div class="info-form">
        <el-form ref="form" :model="form" :rules="rules" label-width="110px">
          <el-form-item label="名称" prop="username">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="code">
            <el-input v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item label="父节点">
            <el-cascader
                style="width: 100%;"
                v-model="form.parentId"
                :options="treeList"
                :props="{ expandTrigger:'hover',checkStrictly: true, value: 'id', label: 'name' ,children:'children'}"
                clearable>
            </el-cascader>

          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.description" type="textarea"></el-input>
          </el-form-item>

          <el-form-item v-if="!form.id" label="生成CRUD">
            <el-checkbox v-model="form.genCRDU"/>
          </el-form-item>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update('form')">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import * as permissionApi from "@/api/gen/permissionApi"


export default {
  name: "edit",
  props: {
    row: {},
    permissionsList: {
      type: Array
    },
    treeList: {
      type: Array
    }
  },


  data() {
    return {
      form: {
        code: "",
        name: '',
        parentId: '',
        type: "",
        description: "",
        genCRDU: ""

      },

      rules: {},

    }
  },

  computed: {

    status() {

      return this.row.id ? "edit" : "add"
    }

  },
  mounted() {
    Object.assign(this.form, this.row)
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async update(data) {
      let result
      if (this.status === 'add') {
        //添加
        result = await this.add()
      } else {
        //修改
        result = await this.edit()
      }
      if (result) {
        this.$emit("close");
        this.$emit("update")
      }
    },

    async add() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        if (this._.isArray(this.form.parentId)) {
          this.form.parentId = this.form.parentId.pop()
        }
        let id = (await permissionApi.create({...this.form}))
        if (this.form.genCRDU) {
          let tmp1 = {...this.form}
          tmp1.parentId = id;
          tmp1.code = (this.form.code + '.' + 'create')
          tmp1.name = "新建"
          await permissionApi.create(tmp1)
          tmp1.name = "更新"
          tmp1.code = (this.form.code + '.' + 'update')
          await permissionApi.create(tmp1)

          tmp1.name = "删除"
          tmp1.code = (this.form.code + '.' + 'delete')
          await permissionApi.create(tmp1)

          tmp1.name = "查询"
          tmp1.code = (this.form.code + '.' + 'list')
          await permissionApi.create(tmp1)
          this.$message.success("添加成功")


        }

        return valid
      } else {
        return false
      }
    },
    async edit() {

      let valid = await this.$refs.form.validate()
      if (valid) {
        if (this._.isArray(this.form.parentId)) {
          this.form.parentId = this.form.parentId.pop()
        }
        await permissionApi.update(this.form)
        this.$message.success("修改成功")
        return valid
      } else {
        return false
      }


    }
  }
}
</script>

<style scoped>


</style>