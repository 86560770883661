<template>
    <el-button v-bind="$attrs" :loading="showLoading"  @click="handleClick">
      <slot></slot>

    </el-button>


</template>

<script>
export default {
  name: "my-button",


  props: {

    autoLoading: {
      type: Boolean,
      default: true
    }


  },

  data() {
    return{
      showLoading:false
    }

  },
  methods: {
    done(){
      console.log('done')
      this.showLoading = false
    },
    handleClick() {
      if (this.autoLoading) {
        this.showLoading = true

      }
      this.$emit('click',this.done)


    }
  }
}
</script>

<style scoped>

</style>
