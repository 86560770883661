<template>
  <div style="font-size: 14px">
    <el-card style="margin: 20px">
      <div style="display: flex; margin-bottom: 10px; line-height:1.6">
        <div style="white-space: nowrap">超重和肥胖：</div>
        <div>
          <div> （1）身（长）高别体重 >均数 +2SD的儿童，要进行体质指数（BMI）值的计算</div>
          <div> （2）计算公式BMI=体重（ Kg）/ 身（长）高的平方（ ㎡）</div>
          <div> （3）评价（ P表示百分位数法）</div>
          <div style="margin-left: 20px"> 超重： BMI>P85th、肥胖： BMI>P97th</div>
        </div>
      </div>


      <div style="display: flex; justify-content: flex-end">
        <el-button :class="sex === '男'? '': 'active'" type="primary" @click="sex = '男'">男</el-button>
        <el-button :class="sex === '女'? '': 'active'" type="primary" @click="sex = '女'">女</el-button>
      </div>
      <div style="text-align: center; margin-bottom: 30px; font-size: 22px">WHO(世界卫生组织)BMI标准({{sex}})</div>
      <div style="text-align: center; margin-bottom: 15px; font-size: 22px">(2006年版)</div>


      <div style="display: flex; justify-content: center;">
        <div style="width: 90%; display: flex; justify-content: center; flex-direction: column ">
          <el-table
              v-if="sex === '男'"
              style="width: 100%;"
              :data="form[0]"
              :header-cell-style="{background:'#fafafa'}">
            <el-table-column>
              <el-table-column
                  label="岁"
                  prop="year"
                  width="80px"
              >

              </el-table-column>
              <el-table-column
                  label="月"
                  prop="month"
                  width="80px"
              >

              </el-table-column>

            </el-table-column>

            <el-table-column
               label="百分位数法(BMI in kg/m2)"

            >
              <el-table-column
                  label="50th"
                  prop="p1"
              >
              </el-table-column>
              <el-table-column
                  label="85th"
                  prop="p2"
              >
              </el-table-column>
              <el-table-column
                  label="95th"
                  prop="p3"
              >
              </el-table-column>
              <el-table-column
                  label="均数"
                  prop="p4"
              >
              </el-table-column>
            </el-table-column>


          </el-table>
          <el-table
              v-if="sex === '女'"
              style="width: 100%;"
              :data="form[1]"
              :header-cell-style="{background:'#fafafa'}">
            <el-table-column>
              <el-table-column
                  label="岁"
                  prop="year"
                  width="80px"
              >

              </el-table-column>
              <el-table-column
                  label="月"
                  prop="month"
                  width="80px"
              >

              </el-table-column>

            </el-table-column>

            <el-table-column
                label="百分位数法(BMI in kg/m2)"

            >
              <el-table-column
                  label="50th"
                  prop="p1"
              >
              </el-table-column>
              <el-table-column
                  label="85th"
                  prop="p2"
              >
              </el-table-column>
              <el-table-column
                  label="95th"
                  prop="p3"
              >
              </el-table-column>
              <el-table-column
                  label="均数"
                  prop="p4"
              >
              </el-table-column>
            </el-table-column>


          </el-table>
        </div>
      </div>


    </el-card>
  </div>
</template>

<script>
import * as baseDataYEYBMICZYFPApi from "@/api/gen/baseDataYEYBMICZYFPApi"

export default {
  name: "yeyWeightBase",
  data() {
    return {
      sex: "男",
      list: []
    }
  },
  async mounted() {
    const res = (await baseDataYEYBMICZYFPApi.list({pageSize: 100000}))


    this.list = res.data.sort((a,b)=>{
      let aSum =( (a.year *100) + a.month)
      let bSum =( (b.year *100) + b.month)

      return aSum - bSum
    })

  },
  computed: {
    form() {

      return this._
          .chain(this.list)
          .groupBy(item => item.sex)
          .value()
    }

  },
  methods: {
    changeSex(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.active {
  background-color: #fff;
  color: #000;
}


/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}

</style>