<template>
  <el-autocomplete v-bind="$attrs" v-on="$listeners" :fetch-suggestions="querySearch" placeholder="请选择">
  </el-autocomplete>
</template>

<script>
export default {
name: "Autocomplete",
  props:{
    items:[Array],




  },
  data() {
    return {

    }
  },
  beforeMount() {


  },

  methods: {
    querySearch(queryString, cb) {
      let restaurants = this.items;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };

    },
  },
}
</script>

<style scoped>

</style>