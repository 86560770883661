<template>
  <el-select v-on="$listeners" v-bind="$attrs" clearable>
    <el-option v-for="item in items" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>


</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  inheritAttrs: false,
  name: "OrganizationSelect",
  props: {
    isFilterSchool: {
      type: Boolean,
      default: false
    }
  },
  async beforeMount() {
    if (this.isFilterSchool) {
      this.items = (await organizationApi.list({pageSize: 10000})).data.filter(item=>!(item.type === 3 || item.type === 4))
    } else {
      this.items = (await organizationApi.list({pageSize: 10000})).data

    }
  },

  data() {
    return {
      items: []
    }
  },
  methods: {
    getItems() {
      return this.items
    }
  },
};
</script>

<style scoped>

</style>
