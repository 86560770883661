<template>
  <div>

    <el-card style=" margin: 20px 20px">
      <el-button type="primary" @click="addDialog" v-show="$hasPermission('announcement.create')">+添加</el-button>
      <el-table
          :data="tableData"
          style="width: 100%">

        <el-table-column

            label="标题">
          <template slot-scope="scope">

            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>



        <el-table-column
            label="状态">
          <template slot-scope="scope">

            <span>{{ statusType[scope.row.status] }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="备注">
          <template slot-scope="scope">

            <span>{{ scope.row.remarks }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="已上传的文件"
            width="400px">
          <template slot-scope="scope">

            <div v-for="item in  scope.row.fileInfoVOList">
              <el-link @click="download(item.id)">{{ item.fileName }}</el-link>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="删除">
          <template slot-scope="scope">

            <el-button type="text" @click="updateDialog(scope.row)"  v-show="$hasPermission('announcement.update')">编辑</el-button>
            <el-button type="text" @click="remove(scope.row.id)" style="color: red"  v-show="$hasPermission('announcement.delete')">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </el-card>
    <edit v-if="isEditShow" :row="current" @close="()=>this.isEditShow = !this.isEditShow" @update="list()"></edit>
  </div>
</template>

<script>
import edit from "./edit"
import * as announcementApi from '@/api/gen/announcementApi'
import * as dataMenu from '@/assets/js/data'
import * as fileInfoApi from '@/api/gen/fileInfoApi'

export default {
  name: 'announcement',
  components: {
    edit
  },
  data() {
    return {
      menu: dataMenu,
      isEditShow: false,
      current: {},
      tableData: [],
      fileInfoList: []
    }
  },
  computed: {
    statusType() {
      return Object.values(this.menu.statusType)
    },
  },
  mounted() {
    this.list()
    this.getFileList()
  },
  methods: {
    addDialog() {
      this.isEditShow = true;
      this.current = {}
    },
    updateDialog(data) {
      this.isEditShow = true;
      this.current = data
    },
    async list() {
      this.tableData = (await announcementApi.list()).data
    },
    async getFileList() {
      this.fileInfoList = (await fileInfoApi.list({organizationId: this.$store.getters.organizationId})).data
    },
    download(id) {
      let url = `${process.env.VUE_APP_API}/file/download?id=${id}`
      window.open(url)
    },
    //删除
    async remove(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await announcementApi.deleteById({id: id})
        this.$message.success('删除成功')
        await this.list()
      }).catch((err) => {
        console.log(err)
      })


    }
  },


}

</script>

<style scoped>


</style>
