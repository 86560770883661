<template>
  <div>
    <el-dialog
        :show-close=true
        :visible="true"
        center
        title="关联学校"
        width="50%"
        @close="close"
    >

      <div style="display: flex; justify-content: center">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>省</span>
          </div>
          <div>

            <el-scrollbar wrap-style="height:400px;">
              <div v-for="item in provinceList" :key="item.id" :class="selectedProvince===item.code ? 'active': ''"
                   class="text item"
                   @click="clickProvince(item)">

                {{ item.name }}
              </div>
            </el-scrollbar>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>市</span>
          </div>
          <el-scrollbar wrap-style="height:400px;">
            <div v-for="item in cityList" :key="item.id" :class="selectedCity===item.code ? 'active': ''"
                 class="text item"
                 @click="clickCity(item)">
              {{ item.name }}
            </div>
          </el-scrollbar>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>区</span>
          </div>
          <el-scrollbar wrap-style="height:400px;">

            <div v-for="item in countryList" :key="item.id" :class="selectedCountry===item.code ?'active':''"
                 class="text item"
                 @click="clickCountry(item)">
              {{ item.name }}
            </div>

          </el-scrollbar>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>学校</span>
          </div>
          <el-scrollbar wrap-style="height:400px;">


            <el-checkbox-group v-model="selectedSchool">

              <div style="display: flex; flex-direction: column">

                <el-checkbox v-for="item in schoolList" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
              </div>
            </el-checkbox-group>

          </el-scrollbar>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as areaCodeApi from "@/api/gen/areaCodeApi";
import * as dataMenu from "@/assets/js/data"
import * as organizationApi from "@/api/gen/organizationApi";


export default {
  name: "relevance-school",
  props: {
    row: {},
    schoolListId:{}
  },
  data() {
    return {
      form: {},
      menu: dataMenu,
      provinceList: [],
      selectedProvince: "460000",
      selectedCity: "",
      selectedCountry: "",
      selectedSchool: [],
      formList: []
    }
  },
  async mounted() {
    Object.assign(this.form, this.row)
    await this.loadCity()
    await this.list();
    this.selectedSchool = this.schoolListId|| []
  },

  computed: {
    cityList() {
      for (const item of this.provinceList) {
        if (item.code === this.selectedProvince) {
          return item.children
        }

      }
      return [];
    },
    countryList() {
      for (const item of this.cityList) {
        if (item.code === this.selectedCity) {
          return item.children
        } else {
          // 没找到 下一个
          continue
        }
      }
      // mei

      return []
    },
    schoolList() {
      //当前没有选中区
      if (!this.selectedCountry) {
        return []
      }
      // 选中区
      let arr = []
      arr = this.formList.filter(item => item.type === 3 || item.type === 4)
          .filter(item => item.county === this.selectedCountry);
      return arr
    },

  },


  methods: {

    close() {
      this.$emit("close")
    },


    async update(data) {
      //添加
      await this.add()
      let obj = {
        schools: this.selectedSchool
      }
      this.$emit("selectedSchool", obj)
      this.$emit("close");


    },

    async add() {
      let obj = {
        id: this.row.id,
        // province:this.selectedProvince,
        // city:this.selectedCity,
        // county:this.selectedCountry,
        schools: this.selectedSchool
      }
      await organizationApi.update(obj)
      this.$message.success("添加成功")

    },


    async loadCity() {
      const res = (await areaCodeApi.list({pageSize: 10000}));
      this.provinceList = res.data
    }
    ,


    clickProvince(res) {
      this.selectedProvince = res.code;
    }
    ,
    clickCity(res) {
      this.selectedCity = res.code;
    }
    ,
    clickCountry(res) {
      this.selectedCountry = res.code;
    },


    async list() {
      const res = (await organizationApi.list({pageSize: 10000}));
      this.formList = res.data
      this.total = res.count
    },
  }


}
</script>

<style scoped>
.active {
  color: #1890ff;
}

.el-scrollbar__wrap {
  margin-right: -16px !important;
}

.el-card {
  min-width: 13em;
}


.wrapclass {
  line-height: 2.0;
}

</style>
