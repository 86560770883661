<template>
  <el-select :value="myValue" v-bind="myAttrs" v-on="myListeners" placeholder="请选择" style="width: 100%">
    <el-option v-for="item in items" :key="item.id" :label="item.name"
               :value="type === 'int' ? (item.code) : item.code"></el-option>
  </el-select>
</template>

<script>
import * as baseDataApi from "@/api/gen/baseDataApi"

export default {
  name: "DataSelect",
  data() {
    return {
      status: 'init',
      items: []
    }
  },
  props: {
    value: {},
    parentCode: {},
    type: {

      default: "int"

    },

    isZXXSchool:{
      type:Boolean,
      default: false
    },
    isYEYSchool:{
      type:Boolean,
      default: false
    }

  },
  async beforeMount() {
    let all = (await baseDataApi.list()).data;

    let parentId = all.filter(item => item.code === this.parentCode)?.pop()?.id;


    this.items = all.filter(item => item.parentId === parentId);

    if(this.isZXXSchool){
      this.items = all.filter(item => item.parentId === parentId).filter(item=>item.code === '3')
    }
    if(this.isYEYSchool){
      this.items = all.filter(item => item.parentId === parentId).filter(item=>item.code === '4')
    }

    this.done()

  },
  methods: {
    done() {
      this.status = 'done'
      this.$emit('initDone')
    }
  },
  computed: {
    myValue() {
      if (this.items.length <= 0) {
        return ''
      }
      if (this._.isNumber(this.value)) {
        return this.value + ''
      } else {
        return this.value

      }

    },
    myAttrs() {
      return this.$attrs
    },
    myListeners() {
      let old = this.$listeners.input

      this.$listeners.input = (v) => {
        if (this.status === 'init') {
          old(this.value)
        } else {
          old(v)
        }
      }
      return this.$listeners
    }
  }
}
</script>

<style scoped>

</style>
