let context = require.context("./", true, /.+\.js$/)
import Vue from 'vue'
context.keys().forEach(k => {

    if (k.includes("index")) {
        return;
    }

    let module = context(k)


    Vue.use(module.default)
})

export default {}