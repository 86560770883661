<template>
  <div>

    <el-card style=" margin: 20px 20px">


      <div style="display: flex; justify-content: center">
        <el-radio-group v-model="model">
          <el-radio-button label="1" name="3">中小学</el-radio-button>
          <el-radio-button label="2" name="3">幼儿园</el-radio-button>
        </el-radio-group>
      </div>
      <!--     中小学-->
      <div>
        <div style="display: flex; justify-content: flex-end;margin-top: 20px;">
          <!--          <el-button v-show="$hasPermission('adviceresult.create')" type="primary" @click="addDialogShow">添加</el-button>-->
          <el-button type="primary" @click="listAsync">恢复默认</el-button>
          <el-button v-show="$hasPermission('adviceresult.create')" type="primary" @click="addSuggest">添加</el-button>
        </div>
        <div style="display: flex">

          <!-- 项目结果-->
          <el-table
              :data="tableList"
              border
              highlight-current-row
              style="flex: 1"
              @row-click="rowClick">

            <el-table-column

                label="项目结果"
                prop="result"
            >
            </el-table-column>
            <el-table-column
                align="left"

            >
              <!--              <template slot-scope="scope">-->
              <!--                <el-button v-show="$hasPermission('adviceresult.update')" type="text" @click="updateDialog(scope.row)">-->
              <!--                  修改-->
              <!--                </el-button>-->
              <!--                <el-button v-show="$hasPermission('adviceresult.delete')" type="text" @click="removeResult(scope.row)" style="color: red">-->
              <!--                  删除-->
              <!--                </el-button>-->
              <!--              </template>-->
            </el-table-column>
          </el-table>
          <!-- 结果建议-->
          <el-table
              :data="advice"
              border
              style="flex: 2;">
            <el-table-column

                align="left"
                label="结果建议"

            >
              <template slot-scope="scope">

                {{ scope.row }}
              </template>
            </el-table-column>

            <el-table-column
                label="操作"
                width="100px">
              <template slot-scope="scope">

                <el-button v-show="$hasPermission('adviceresult.update')" type="text" @click="updateSuggest(scope.row)">
                  修改
                </el-button>
                <el-button v-show="$hasPermission('adviceresult.create')" slot="reference" type="text"
                           style="color: red"
                           @click="removeAdvice(scope.row)">
                  删除
                </el-button>


              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>


    </el-card>


    <edit v-if="isEditShow" :row="resultCurrent" @close="()=>this.isEditShow = !this.isEditShow" @update="list"></edit>
    <suggest-edit v-if="isSuggestEditShow"
                  :current=current
                  :row="adviceCurrent"
                  @close="()=>this.isSuggestEditShow = !this.isSuggestEditShow"
                  @update="list">

    </suggest-edit>

  </div>
</template>

<script>
import edit from "./edit"
import suggestEdit from "./suggest-edit"
import * as testResultAndAdviceApi from "@/api/gen/testResultAndAdviceApi"

export default {
  name: "result_suggest",
  components: {
    edit,
    suggestEdit,
  },
  data() {
    return {
      fromList: [],
      model: '1',
      isEditShow: false,
      isSuggestEditShow: false,
      resultCurrent: {},
      adviceCurrent: "",
      current: "",
      form: {
        advice: [],
        result: '',
        type: '',
        id: ''
      }

    }
  },
  watch:{
    model:{
      handler(){
        this.current = ""
      }
    }
  },
  async mounted() {

    await this.list()
  },

  computed: {

    advice() {

      return this.current ? this.current.advice || [] : [];

    },

    tableList(){
      if(this.model === '1'){
        return this.fromList.filter(item=>item.type === 3)
      }
      if(this.model === '2'){
        return this.fromList.filter(item=>item.type === 4)
      }
    }

  },
  methods: {
    addDialogShow() {
      this.isEditShow = true
      this.resultCurrent = {};
    },
    updateDialog(data) {
      this.isEditShow = true
      this.resultCurrent = data;
    },

    rowClick(e) {
      this.current = e;
      this.adviceCurrent = e
    },
    addSuggest() {
      this.isSuggestEditShow = true
      this.current = {id: '', result: this.current.result};

    },
    updateSuggest(data) {
      this.isSuggestEditShow = true
      this.current = {...this.adviceCurrent, data};
      this.adviceCurrent = {...this.adviceCurrent, data};

    },


    //获取列表
    async list() {
      const res = (await testResultAndAdviceApi.list({organizationId: this.$store.getters.organizationId, pageSize:1000}));
      this.fromList = res.data;
      // console.log(res)
    },


    //删除 项目结果
    removeResult(e) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await testResultAndAdviceApi.deleteById({id: e.id})
        this.$message.success('删除成功')
        await this.list()
      }).catch(() => {
      })


    },

    //删除 结果建议
    removeAdvice(e) {

      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {

        let index = this.advice.indexOf(e)
        this.advice.splice(index, 1)
        let obj = {
          advice: this.advice,
          id: this.current.id
        }
        await testResultAndAdviceApi.update(obj);
        this.$message.success("删除成功")
        await this.list()
      }).catch(() => {
      })


    },

//数据同步
    async listAsync() {
      (await testResultAndAdviceApi.init({id: this.$store.getters.organizationId}))
      this.$message.success('恢复成功')
    }
  }

}
</script>

<style scoped>

</style>
