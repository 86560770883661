<template>
  <div>
    <el-container class="container">
      <el-aside :style="menuStyle">
        <Menu :isCollapse=isCollapse @current="current"></Menu>

      </el-aside>

      <el-container>
        <el-header>
          <div class="left">
            <!-- 面包屑 -->
            <svg fill="rgba(255, 255, 255, 1)" height="24px" viewBox="0 0 48 48" width="24px"
                 xmlns="http://www.w3.org/2000/svg"
                 @click="click">
              <path
                  d="M8 21c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM8 9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 24c-1.67 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.33-3-3-3zm6 5h28v-4H14v4zm0-12h28v-4H14v4zm0-16v4h28v-4H14z"></path>
              <path d="M0 0h48v48H0V0z" fill="none"></path>
            </svg>
          </div>
          <div class="right" style="display: flex; align-items: center">
            <!--   搜索    -->
            <!--            <svg fill="rgba(255, 255, 255, 1)" height="20px" viewBox="0 0 48 48" width="20px"-->
            <!--                 xmlns="http://www.w3.org/2000/svg">-->
            <!--              <path-->
            <!--                  d="M31 28h-1.59l-.55-.55C30.82 25.18 32 22.23 32 19c0-7.18-5.82-13-13-13S6 11.82 6 19s5.82 13 13 13c3.23 0 6.18-1.18 8.45-3.13l.55.55V31l10 9.98L40.98 38 31 28zm-12 0c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9z"></path>-->
            <!--              <path d="M0 0h48v48H0z" fill="none"></path>-->
            <!--            </svg>-->
            <!--     提醒     -->
            <!--            <svg fill="rgba(255, 255, 255, 1)" height="20px" style="margin: 0 15px"-->
            <!--                 viewBox="0 0 48 48" width="20px" xmlns="http://www.w3.org/2000/svg"-->
            <!--                 xmlns:xlink="http://www.w3.org/1999/xlink">-->
            <!--              <defs>-->
            <!--                <path id="a" d="M0 0h48v48H0z"></path>-->
            <!--              </defs>-->
            <!--              <clipPath id="b">-->
            <!--                <use overflow="visible" xlink:href="#a"></use>-->
            <!--              </clipPath>-->
            <!--              <path clip-path="url(#b)"-->
            <!--                    d="M24 44c2.21 0 4-1.79 4-4h-8c0 2.21 1.79 4 4 4zm12-12V22c0-6.15-3.27-11.28-9-12.64V8c0-1.66-1.34-3-3-3s-3 1.34-3 3v1.36c-5.73 1.36-9 6.49-9 12.64v10l-4 4v2h32v-2l-4-4zm-4 2H16V22c0-4.97 3.03-9 8-9s8 4.03 8 9v12z"-->
            <!--                    fill="#none"></path>-->
            <!--            </svg>-->


            <el-dropdown trigger="click">
              <div style="display: flex; align-items: center">
                <img src="../../assets/images/avatar.png" style="width: 24px; height: 24px; cursor: pointer">
                <span class="username">{{ username }}</span>
                <span class="el-dropdown-link">
                <i class="el-icon-arrow-down el-icon--right" style="color: #fff"></i>
              </span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="updatePassword">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="switchUser"
                                  v-show="$hasPermission('switchUser.switch') || $store.state.adminToken">切换用户
                </el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </el-header>
        <el-main>
          <Elcard :currentPath="currentPath"></Elcard>
          <router-view class="main_tran"/>
        </el-main>
      </el-container>
    </el-container>

    <switch-user v-if="showSwitchUser" @close="()=>this.showSwitchUser = !this.showSwitchUser"></switch-user>
    <update-password v-if="isUpdatePassword" @close="()=>this.isUpdatePassword = !this.isUpdatePassword"/>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import Menu from "@/components/Menu"
import Elcard from "@/components/Elcard";
import updatePassword from "@/views/updatePassword"
import switchUser from "@/views/home/switchUser";

export default {
  components: {
    Menu,
    Elcard,
    updatePassword,
    switchUser
  },
  name: "Home",
  data() {
    return {
      isCollapse: false,
      currentPath: "",

      isUpdatePassword: false,

      showSwitchUser: false
    }
  },
  mounted() {
    this.currentPath = this.$route.path

  },
  computed: {
    ...mapState,
    menuStyle() {
      return {
        width: this.isCollapse ? "64px" : "330px"
      }
    },
    username() {
      return this.$store.state?.userInfo?.user?.username
    }
  },
  methods: {
    click() {
      this.isCollapse = !this.isCollapse
    },
    current(data) {
      this.currentPath = data
    },

    updatePassword() {
      this.isUpdatePassword = true
    },

    logout() {
      this.$store.commit('logout')
      this.$router.push({path: "/login"})
      localStorage.removeItem('vuex')

    },
    switchUser() {
      this.showSwitchUser = true
    }
  },

}
</script>

<style scoped>
.container {
  height: 100vh;
}

.el-menu-vertical-demo {
  height: 100vh;
}


.el-header {
  padding: 0 30px !important;
  background: #4F9BF8;
  height: 64px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

}


/*::-webkit-scrollbar {*/
/*  display: none;*/
/*  width: 0 !important;*/
/*  height: 0 !important;*/
/*  -webkit-appearance: none;*/
/*  background: transparent;*/
/*  overflow-x: hidden;*/
/*}*/


.el-aside::-webkit-scrollbar {
  display: none;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.el-menu {
  border: 0 !important;
}

.username {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Microsoft YaHei sans-serif;
  margin-left: 5px;
  cursor: pointer;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.iconfont {
  margin-right: 15px;
  color: #fff;
}

svg {
  cursor: pointer;
}

.el-menu-item:focus, .el-menu-item:hover {
  background-color: rgba(24, 144, 255, 0.1) !important;

}

.el-submenu .el-submenu__title:focus, .el-submenu__title:hover {
  background-color: rgba(24, 144, 255, 0.1) !important;
}

@keyframes tran {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.main_tran {
  animation-name: tran;
  animation-duration: .6s;
  animation-timing-function: ease-in-out;
}


</style>