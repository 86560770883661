<template>
  <el-card style="margin: 20px; padding-bottom: 10px; font-size: 14px">

    <div style="font-size: 14px; line-height: 1.5;">
      身高发育水平分成5个等级：身高＜-2SD为下等；身高≥-2SD且＜-1SD为中下等；身高≥-1SD且≤+1SD为中等；身高＞+1SD且≤+2SD为中上等；身高＞+2SD为上等。注：SD为标准差。（备注：假如只统计上、中、下三个等级，中上等和中下等的数据归属到中等这里去）
    </div>
    <div style="display: flex; justify-content: center">
      <div style="display: flex;flex-direction:column;  justify-content: center; align-items: center; width: 60%">
        <div style=" align-self: end;  margin: 20px 0 0 0">7岁~18岁儿童青少年身高发育等级界值点见表A.1和A.2。</div>
      </div>
    </div>
    <div v-for="(item,index) in form" :key="index" style="display: flex; justify-content: center">
      <div style="display: flex;flex-direction:column;  justify-content: center; align-items: center; width: 60%">
        <div v-if="index === '0'" style="margin: 20px 0 ">表A.1男生身高发育等级划分标准</div>
        <div v-if="index === '1'" style="margin: 30px 0 ">表A.2女生身高发育等级划分标准</div>
        <div style="align-self: flex-end; margin-bottom: 10px">单位为厘米</div>
        <el-table
            :data="item"
            style="width: 100%"
            :header-cell-style="{background:'#fafafa'}">
          <el-table-column
              label="年龄/岁"
              prop="age"
          >
          </el-table-column>
          <el-table-column
              label="-2SD"
              prop="p1"
          >
          </el-table-column>
          <el-table-column
              label="-1SD"
              prop="p2">
          </el-table-column>
          <el-table-column
              label="中位数"
              prop="p3">
          </el-table-column>
          <el-table-column
              label="+1SD"
              prop="p4">
          </el-table-column>
          <el-table-column
              label="+2SD"
              prop="p5">
          </el-table-column>
        </el-table>


      </div>
    </div>
  </el-card>
</template>

<script>
import * as baseDataZXXSGFYDJApi from "@/api/gen/baseDataZXXSGFYDJApi";

export default {
  name: "statureBase",
  data() {
    return {
      list: [],
    }
  },
  async mounted() {

    const res = (await baseDataZXXSGFYDJApi.list({pageSize: 100000}))
    this.list = res.data

  },
  computed: {
    form() {

      return this._
          .chain(this.list)
          .groupBy(item => item.sex)
          .mapValues(item => this._.sortBy(item, item => item.age))

          .value();
    },

  }
  ,
  methods: {}
}
</script>

<style scoped>

/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
