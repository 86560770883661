<template>
  <div style="font-size: 14px">
    <el-card style="margin: 20px">
      <div style="line-height: 2">
        <div style="display: flex; margin-bottom: 10px">
          <div style="white-space: nowrap"> 营养不良：</div>
          <div>
            （1）低体重：轻度：均数 -2SD≤年龄别体重 &lt;均数 -SD
            <div style="margin-left: 90px">重度：年龄别体重 &lt;均数-3SD</div>
            <div style="margin-left: 90px">中度：均数 -3SD≤年龄别体重 &lt;均数 -2SD</div>
            （2）生长发育迟缓：年龄别身（长）高 &lt;均数 -2SD<br/>
            （3）消瘦：身（长）高别体重 &lt; 均数 -2SD
          </div>
        </div>
        <div style="color: red">备注：营养不良的判定依据必须具备低体重和生长迟缓和消瘦三项</div>
        <div style="margin-bottom: 10px;">
          身高发育等级评价5个等级：身高＜-2SD为下等；身高≥-2SD且＜-1SD为中下等；身高≥-1SD且≤+1SD为中等；身高＞+1SD且≤+2SD为中上等；身高＞+2SD为上等。注：SD为标准差。（备注：假如只统计上、中、下三个等级，中上等和中下等的数据归属到中等这里去）
        </div>
        <div>
          体重等级分为5个等级：体重＜-2SD为下等；体重≥-2SD且＜-1SD为中下等；体重≥-1SD且≤+1SD为中等；体重＞+1SD且≤+2SD为中上等；体重＞+2SD为上等。注：SD为标准差。（备注：假如只统计上、中、下三个等级，中上等和中下等的数据归属到中等这里去）

        </div>
      </div>

      <div style="display: flex; justify-content: flex-end">
        <el-button :class="sex === '男'? '': 'active'" type="primary" @click="sex = '男'">男</el-button>
        <el-button :class="sex === '女'? '': 'active'" type="primary" @click="sex = '女'">女</el-button>
      </div>
      <div style="text-align: center; margin-bottom: 30px; font-size: 22px">WHO(世界卫生组织)0-6岁{{sex}}童年龄别体重身(长)高标准</div>
      <div style="text-align: center; margin-bottom: 15px; font-size: 22px">(2006年版)</div>


      <div style="display: flex; justify-content: center;">
        <div style="width: 90%; display: flex; justify-content: center; flex-direction: column ">
          <el-table
              v-if="sex === '男'"
              style="width: 100%;"
              :data="form[0]"
              :header-cell-style="{background:'#fafafa'}">
            <el-table-column
                label="年龄"
                prop="age">
              <el-table-column
                  label="岁"
                  prop="year"
              >
              </el-table-column>

              <el-table-column
                  label="月"
                  prop="month"
              >
              </el-table-column>

            </el-table-column>
            <el-table-column
                label="体重(公斤)"
            >
              <el-table-column
                  label="-3SD"
                  prop="p1"
              >
              </el-table-column>
              <el-table-column
                  label="-2SD"
                  prop="p2"
              >
              </el-table-column>
              <el-table-column
                  label="-1SD"
                  prop="p3"
              >
              </el-table-column>
              <el-table-column
                  label="均数"
                  prop="p4"
              >
              </el-table-column>
              <el-table-column
                  label="1SD"
                  prop="p5"
              >
              </el-table-column>
              <el-table-column
                  label="2SD"
                  prop="p6"
              >
              </el-table-column>
              <el-table-column
                  label="3SD"
                  prop="p7"
              >
              </el-table-column>

            </el-table-column>
            <el-table-column
                label="身(长)高(厘米)"
            >
              <el-table-column
                  label="-3SD"
                  prop="p8"
              >
              </el-table-column>
              <el-table-column
                  label="-2SD"
                  prop="p9"
              >
              </el-table-column>
              <el-table-column
                  label="-1SD"
                  prop="p10"
              >
              </el-table-column>
              <el-table-column
                  label="均数"
                  prop="p11"
              >
              </el-table-column>
              <el-table-column
                  label="1SD"
                  prop="p12"
              >
              </el-table-column>
              <el-table-column
                  label="2SD"
                  prop="p13"
              >
              </el-table-column>
              <el-table-column
                  label="3SD"
                  prop="p14"
              >
              </el-table-column>

            </el-table-column>


          </el-table>
          <el-table
              v-if="sex === '女'"
              style="width: 100%;"
              :data="form[1]"
              :header-cell-style="{background:'#fafafa'}">
            <el-table-column
                label="年龄"
                prop="age">
              <el-table-column
                  label="岁"
                  prop="year"
              >
              </el-table-column>

              <el-table-column
                  label="月"
                  prop="month"
              >
              </el-table-column>

            </el-table-column>
            <el-table-column
                label="体重(公斤)"
            >
              <el-table-column
                  label="-3SD"
                  prop="p1"
              >
              </el-table-column>
              <el-table-column
                  label="-2SD"
                  prop="p2"
              >
              </el-table-column>
              <el-table-column
                  label="-1SD"
                  prop="p3"
              >
              </el-table-column>
              <el-table-column
                  label="均数"
                  prop="p4"
              >
              </el-table-column>
              <el-table-column
                  label="1SD"
                  prop="p5"
              >
              </el-table-column>
              <el-table-column
                  label="2SD"
                  prop="p6"
              >
              </el-table-column>
              <el-table-column
                  label="3SD"
                  prop="p7"
              >
              </el-table-column>

            </el-table-column>
            <el-table-column
                label="身(长)高(厘米)"
            >
              <el-table-column
                  label="-3SD"
                  prop="p8"
              >
              </el-table-column>
              <el-table-column
                  label="-2SD"
                  prop="p9"
              >
              </el-table-column>
              <el-table-column
                  label="-1SD"
                  prop="p10"
              >
              </el-table-column>
              <el-table-column
                  label="均数"
                  prop="p11"
              >
              </el-table-column>
              <el-table-column
                  label="1SD"
                  prop="p12"
              >
              </el-table-column>
              <el-table-column
                  label="2SD"
                  prop="p13"
              >
              </el-table-column>
              <el-table-column
                  label="3SD"
                  prop="p14"
              >
              </el-table-column>

            </el-table-column>


          </el-table>
        </div>
      </div>


    </el-card>
  </div>
</template>

<script>
import * as baseDataYEYLBTZSGBZApi from "@/api/gen/baseDataYEYLBTZSGBZApi"

export default {
  name: "weightBase",
  data() {
    return {
      sex: "男",
      list: []
    }
  },
  async mounted() {
    const res = (await baseDataYEYLBTZSGBZApi.list({pageSize: 100000}))


    this.list = res.data.sort((a, b) => {

      let aSum = ((a.year * 100) + a.month)
      let bSum = ((b.year * 100) + b.month)

      return aSum - bSum;
    })

  },
  computed: {
    form() {

      return this._
          .chain(this.list)
          .groupBy(item => item.sex)
          .value()
    }

  },
  methods: {
    changeSex(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.active {
  background-color: #fff;
  color: #000;
}


/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}

</style>
