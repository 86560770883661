<template>

  <div style="display: flex; justify-content: space-between; align-items: stretch; min-height: 70vh">


    <div style="min-width: 30%; ">


      <el-card style="height: 100%">

        <el-button type="primary" @click="add">添加</el-button>
        <el-button type="primary" @click="update">保存</el-button>

        <div v-for="item in tableData"
             style="padding: .5em; border: 1px solid #eee; margin-top: .5em; display: flex; justify-content: space-between">
          <el-link @click="chose(item)" :type="item.id === form.id ? 'primary': ''">


            {{ item.name }}
          </el-link>

          <div>

            <el-link type="primary" @click="run">运行</el-link>

            <el-link type="primary" @click="doDelete" style="margin-left: 10px">删除</el-link>
          </div>
        </div>


      </el-card>

    </div>


    <div style="width: 68%; flex: 1; margin-left: 1em; ">


      <div>
        <el-input v-model="form.name"></el-input>
      </div>

      <div style="margin-top: 10px">


        <codemirror v-model="form.content" :options="cmOptions" style="height: auto"/>
      </div>

    </div>


  </div>


</template>

<script>
import * as api from "@/api/gen/scriptApi"
import Pager from "@/components/Pagination";
import Edit from "@/views/scriptManagement/Edit";
import OrganizationSelect from "@/components/OrganizationSelect";

export default {
  name: "scriptManagement",
  components: {OrganizationSelect, Edit, Pager},
  data() {
    return {
      tableData: [],
      status: "list",
      row: {},
      form: {
        id: "",
        name: "",
        content: ""


      },

      cmOptions: {
        tabSize: 4,
        mode: 'text/x-groovy',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
        viewportMargin: Infinity
        // more CodeMirror options...
      }

    }
  },
  beforeMount() {
    this.list();
  },
  methods: {

    async list() {
      this.status = 'list';
      let data = await api.list({});
      this.tableData = data.data;

      if (this.tableData) {
        this.form = {...this.tableData[0]};
      }

    },

    async deleteById(obj1) {
      await api.deleteById({id: obj1.id});
      await this.list();

    },


    async edit(obj1) {
      this.status = 'edit';
      this.row = obj1;

    },


    async add() {

      await api.create({name: this.$now.toSQLDate() + ".groovy", content: ""})
      await this.list()

    },
    exportCurrent() {
      console.log("exportCurrent")
      this.exportExcel("table", "用户")

    },
    async update() {

      let tmp = {...this.form};
      await api.update(tmp);
      await this.list();

      this.chose(tmp)


    },

    async doDelete() {
      await api.deleteById({id: this.form.id});
      this.$message.success("操作成功")
      await this.list()
    },
    async run() {
      await api.execute({id: this.form.id});

      this.$message.success("运行成功")
    },
    chose(x) {

      this.form = {...x};

    }
  },


}
</script>

<style scoped>
/deep/ .CodeMirror {
  height: auto;
}

/deep/ .CodeMirror-scroll {
  min-height: 500px;
}


</style>
