import request from '@/utils/request'

export function create(data) {

  return request({
    url: '/yEYMedicalInformation/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/yEYMedicalInformation/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/yEYMedicalInformation/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/yEYMedicalInformation/deleteByIds',
    method: 'POST',
    data})
}

   

export function genResult(data) {

  return request({
    url: '/yEYMedicalInformation/genResult',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/yEYMedicalInformation/getById',
    method: 'GET',
    params})
}



export function getByIdc(params) {
  if(!params){
    params ={}
  }
  return request({
    url: '/yEYMedicalInformation/getByIds2',
    method: 'POST',
    data:params})
}

   

export function getByIds(params) {

  return request({
    url: '/yEYMedicalInformation/getByIds',
    method: 'GET',
    params})
}

   

export function getResultAndAdvice(data) {

  return request({
    url: '/yEYMedicalInformation/getResultAndAdvice',
    method: 'POST',
    data})
}

   

export function list(params) {

  return request({
    url: '/yEYMedicalInformation/list',
    method: 'GET',
    params})
}

   

export function update(data) {

  return request({
    url: '/yEYMedicalInformation/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/yEYMedicalInformation/update/batch',
    method: 'POST',
    data})
}

   