<template>
  <el-card style="margin: 20px; font-size: 14px">
   <div> 使用表 1 界值进行超重判断：凡 BMI 大于或等于相应性别、年龄组“超重”界值点且小于“肥胖”界值点者为超重。</div>
  <div>  使用表 1 界值进行肥胖判断：凡 BMI 大于或等于相应性别、年龄组“肥胖”界值点者为肥胖</div>


    <div style="display: flex; justify-content: center">
      <div style="width: 60% ; display: flex; flex-direction: column; justify-content: center; align-items: center">
        <div style="margin: 40px 0 20px 0 ;">表1 6岁~18岁学龄儿童青少年性别与年龄别BMI筛查超重与配胖界值范围</div>
        <div style="align-self: flex-end; margin-bottom: 10px">单位为千克每平方米</div>
        <el-table
            :data="BMIList"
            style="width: 100%"
        >
          <el-table-column
              label="年龄/岁"
              prop="age"
          >
          </el-table-column>
          <el-table-column
              label="男生"
          >
            <el-table-column
                label="超重"
                prop="manP1"
            >
            </el-table-column>
            <el-table-column
                label="肥胖"
                prop="manP2"
            >
            </el-table-column>

          </el-table-column>
          <el-table-column
              label="女生">
            <el-table-column
                label="超重"
                prop="girlP1"
            >
            </el-table-column>
            <el-table-column
                label="肥胖"
                prop="girlP2"
            >
            </el-table-column>
          </el-table-column>
        </el-table>

      </div>
    </div>
  </el-card>
</template>

<script>
import * as baseDataZXXBMICZYFPApi from "@/api/gen/baseDataZXXBMICZYFPApi";

export default {
  name: "superheavyBase",
  data() {
    return {
      form2: [],
    }
  },
  async mounted() {
    this.form2 = (await baseDataZXXBMICZYFPApi.list({pageSize: 100000})).data;


  },
  computed: {
    BMIList() {
      return this._
          .chain(this.form2)
          .groupBy('age') // {'12岁': [{}]}
          .mapValues(item => {

            if (item.length === 1) {
              let current = item[0].sex;
              return [
                item[0],
                {
                  sex: current === 0 ? 1 : 0
                }
              ]

            }

            return item;


          })
          .mapValues(item => this._.sortBy(item, x => x.sex))

          .map((value, key) => {

            return {
              age: key,
              manP1: value[0].p1,
              manP2: value[0].p2,
              girlP2: value[1].p2,
              girlP1: value[1].p1

            }
          })
          .sortBy(x => parseFloat(x.age))
          .value()

    }

  },
  methods: {}
}


</script>

<style scoped>
/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
