import { DateTime } from "luxon";
import _ from "lodash";

export function parseDateTime(d) {


    let dateTime = DateTime.fromISO(d);

    if (!dateTime.isValid) {
        // console.log("尝试解析sql时间格式 " + d)
        dateTime = DateTime.fromSQL(d);
    }

    if (!dateTime.isValid) {
        // console.log("尝试解析http时间格式 " + d)

        dateTime = DateTime.fromHTTP(d);
    }
    if (!dateTime.isValid) {
        // console.log("尝试解析RFC2822时间格式 " + d)
        dateTime = DateTime.fromRFC2822(d);
    }

    return dateTime;
}

export function toDateTime(d) {

    if (!d) {
        return d;
    }
    if (_.isString(d)) {
        return parseDateTime(d);
    }
    if (_.isNumber(d)) {
        return DateTime.fromMillis(d);
    }

    if (_.isDate(d)) {
        return DateTime.fromJSDate(d);
    }

    if (d instanceof DateTime) {
        return d;
    }
    throw new Error("不支持的类型 " + d);

}

export function format(d, f) {
    if (!d) {
        return "";
    }
    return d.toFormat(f);

}
export function diff (start,end, unit="day") {

    if (!start || !end) {
        return 0;
    }

    return  (toDateTime(end).diff(toDateTime(start),unit))

}

export default {

    install(Vue) {

        Vue.prototype.$DateTime = DateTime;
        Vue.prototype.$dateString = (d) => format(toDateTime(d), "yyyy-MM-dd");
        Vue.prototype.$dateTimeString = (d) => format(toDateTime(d), "yyyy-MM-dd HH:mm:ss");
        Vue.prototype.$timeString = (d) => format(toDateTime(d), "HH:mm:ss");
        Vue.prototype.$toDateTime = toDateTime;
        Vue.prototype.$now = ()=> DateTime.now();

        Vue.filter('dateString', (d) => format(toDateTime(d), "yyyy-MM-dd"));
        Vue.filter('dateTimeString', (d) => format(toDateTime(d), "yyyy-MM-dd HH:mm:ss"));
        Vue.filter('timeString', (d) => format(toDateTime(d), "HH:mm:ss"));


        Vue.prototype.$diff = diff

    }

}