<template>
  <div>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add'? '添加':'修改'"
        :visible="true"
        center
        width="40%"
    >

      <el-form ref="form" :model="form" :rules="rules" align="center" label-position="left" label-width="7em">
        <el-form-item label="单位名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <div style="display: flex; flex-wrap: nowrap; justify-content: space-between">
          <!--省-->
          <el-form-item label="所属地区" prop="province">
            <el-select v-model="form.province" placeholder="请选择">
              <el-option
                  v-for="item in provinceList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!--市-->
          <el-form-item label-width="0px">
            <el-select v-model="form.city" placeholder="请选择">
              <el-option
                  v-for="item in cityList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 县-->
          <el-form-item label-width="0px">
            <el-select v-model="form.county" placeholder="请选择">
              <el-option
                  v-for="item in countryList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item label="单位地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="单位类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择" style="width: 100%;">
            <el-option
                v-for="(item,index) in OrganizationTypeEnum"
                :key="index"
                :label="item"
                :value="index">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="角色授权" prop="role">
          <el-select v-model="form.roleIds" style="width: 100%;" placeholder="请选择" :multiple="true">
            <el-option
                v-for="item in checkRoleList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城乡" prop="cxType">
          <el-select v-model="form.cxType" placeholder="请选择" style="width: 100%;">
            <el-option
                v-for="(item,index) in CityEnum"
                :key="index"
                :label="item"
                :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input v-model="form.contact"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="授权时间">
          <el-date-picker
              v-model="time"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="最大用户数量">
          <el-input v-model="form.userCount"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.description"></el-input>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update">确 认</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi";
import * as areaCodeApi from "@/api/gen/areaCodeApi";
import * as dataMenu from "@/assets/js/data"
import * as roleApi from '@/api/gen/roleApi'
import DataSelect from "@/components/DataSelect";

export default {
  name: "edit",
  components: {DataSelect},
  props: {
    row: {},
    orgList: {}
  },
  data() {
    return {
      menu: dataMenu,
      time: [],
      form: {
        name: '',
        code: '',
        cxType: '',
        province: '',
        city: '',
        county: '',
        address: '',
        type: [],
        contact: '',
        phone: '',
        authStartTime: '',
        authEndTime: '',
        userCount: '',
        description: '',
        roleIds: []

      },
      rules: {
        name: [
          {required: true, message: '请输入单位名称', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入单位地址', trigger: 'blur'},
        ],
        county: [
          {required: true, message: '请选择所属地区', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请选择单位名称', trigger: 'blur'},
        ],
        province: [
          {required: true, message: '请选择省份', trigger: 'blur'},
        ]


      },
      optionsList: {},
      model: "",
      value: [],

      provinceList: [],
      roleList: [],


      checkRoleList: []
    }
  },
  watch: {
    'form.province': {
      handler(n, o) {
        if (!o) {
          return;
        }
        this.form.city = this.cityList[0].code
      }
    },
    'form.city': {
      handler(n, o) {
        if (!o) {
          return;
        }

        this.form.county = this.countryList[0].code
      }
    },
    time: {
      deep: true,
      handler(n) {
        if (!n) {
          this.form.authStartTime = ''
          this.form.authEndTime = ''
        }
        this.form.authStartTime = this.time[0]
        this.form.authEndTime = this.time[1]
      }
    },

    'form.type': {
      handler(n) {
        this.checkRoleList = this.roleList.filter(item => this._.isArray(item.orgTypes)).filter(item => item.orgTypes.includes(n + ''))
      }
    }

  },


  async mounted() {
    await this.loadCity()
    await this.getRoleList()
    Object.assign(this.form, this.row)


    await this.findCode(this.form.province)

    if (this.form.authStartTime || this.form.authEndTime) {
      this.time.push(this.form.authStartTime, this.form.authEndTime)
    }


  },
  computed: {
    status() {
      return this.row.id ? "edit" : "add"
    },
    OrganizationTypeEnum() {
      return Object.values(this.menu.OrganizationTypeEnum)
    },
    CityEnum() {
      return Object.values(this.menu.CityEnum)
    },

    cityList() {
      for (const item of this.provinceList) {
        if (item.code === this.form.province) {
          return item.children
        }

      }
      return [];
    },
    countryList() {

      for (const item of this.cityList) {
        if (item.code === this.form.city) {
          return item.children
        } else {
          // 没找到 下一个
          continue
        }
      }
      // mei

      return []
    },
  },


  methods: {
    async getRoleList() {
      let {data} = await roleApi.list({organizationId: this.$store.getters.organizationId})
      this.roleList = data
    },


    close() {
      this.$emit("close")
    },
    async update(done) {
      try {
        let result
        if (this.status === 'add') {
          //添加
          result = await this.add()
        } else {
          //修改
          result = await this.edit()
        }
        if (result) {
          this.$emit("update", this.form.type)
          this.$emit("close");
        }

      } finally {
        // done()

      }


    },

    async add() {
      let valid = await this.$refs.form.validate()
      // let tmp = this.orgList.filter(item => item.name === this.form.name)

      // if (tmp?.some(item => item.id !== this.form.id)) {
      //   this.$message.warning('单位名不能重复')
      //   return
      // }
      if (valid) {
        await organizationApi.create(this.form)
        this.$message.success("添加成功")

        return valid
      } else {
        return false
      }
    },
    async edit() {
      let valid = await this.$refs.form.validate()
      // let tmp = this.orgList.filter(item => item.name === this.form.name)

      // if (tmp?.some(item => item.id !== this.form.id)) {
      //   this.$message.warning('单位名不能重复')
      //   return
      // }
      if (valid) {
        await organizationApi.update(this.form)
        this.$message.success("修改成功")

        return valid
      } else {
        return false
      }
    },
    async loadCity() {
      const res = (await areaCodeApi.list({pageSize: 10000}))
      this.provinceList = res.data

    },

    findCodeInTree(tree, code) {
      // console.log("在 " + tree.name + "中查找 " + code);
      if (tree.code === code) {
        return tree;
      } else {

        for (const c of (tree.children || [])) {

          let ans = this.findCodeInTree(c, code);


          if (ans) {
            return ans;
          }
        }

      }

      return null;

    },


    findCode(e) {
      for (const item of this.provinceList) {

        let res = this.findCodeInTree(item, e);

        if (res) {
          return res
        }

      }

      return null;


    },


  }
}
</script>

<style scoped>

/deep/ .el-dialog--center .el-dialog__body {
  padding: 25px 45px 25px 30px !important;
}

/deep/ .el-cascader {
  width: 30%;
}

/deep/ .el-form-item__label {

  text-align: justify !important;

}


</style>
