<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="true"
        :show-close=true
        :visible="true"
        center
        width="75%"
        @close="close"
    >

      <el-button style="display: block; margin-bottom: 20px" type="primary" @click="update">刷新</el-button>

      <el-tooltip :content="row.r001">

        <el-tag :type=getType(row.r001)>BMI * 100</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r002">

        <el-tag :type=getType(row.r002)>年龄 * 10</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r003">

        <el-tag :type="getType(row.r003)">营养不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r004">

        <el-tag :type="getType(row.r004)">超重</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r005">

        <el-tag :type="getType(row.r005)">肥胖</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r006">

        <el-tag :type="getType(row.r006)">沙眼</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r007">

        <el-tag :type="getType(row.r007)">结膜炎</el-tag>
      </el-tooltip>
      <!--      <el-tooltip :content="row.r008">-->

      <!--        <el-tag :type=getType(row.r008)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <el-tooltip :content="row.r009">

        <el-tag :type="getType(row.r009)">牙周炎</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r010">

        <el-tag :type="getType(row.r010)">心脏杂音</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r011">

        <el-tag :type="getType(row.r011)">谷丙转氨酶</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r012">

        <el-tag :type="getType(row.r012)">D牙牙数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r013">

        <el-tag :type="getType(row.r013)">M牙牙数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r014">

        <el-tag :type="getType(row.r014)">F牙牙数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r015">

        <el-tag :type="getType(row.r015)">恒牙龋齿</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r016">

        <el-tag :type="getType(row.r016)">佩戴角膜塑形镜</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r017">

        <el-tag :type=getType(row.r017)>结核菌素阴性</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r018">

        <el-tag :type=getType(row.r018)>结核菌素阳性</el-tag>
      </el-tooltip>
      <!--      <el-tooltip :content="row.r019">-->

      <!--        <el-tag :type=getType(row.r019)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <el-tooltip :content="row.r020">

        <el-tag :type="getType(row.r020)">生长迟缓</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r021">

        <el-tag :type="getType(row.r021)">轻度消瘦</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r022">

        <el-tag :type="getType(row.r022)">中重度消瘦</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r023">

        <el-tag :type="getType(row.r023)">蛔虫</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r024">

        <el-tag :type="getType(row.r024)">疑沙</el-tag>
      </el-tooltip>
      <!--      <el-tooltip :content="row.r025">-->

      <!--        <el-tag :type="getType(row.r025)">体重上等</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r026">-->

      <!--        <el-tag :type="getType(row.r026)">体重下等</el-tag>-->
      <!--      </el-tooltip>-->
      <el-tooltip :content="row.r028">

        <el-tag :type="getType(row.r028)">血压偏高</el-tag>
      </el-tooltip>
      <!--      <el-tooltip :content="row.r029">-->

      <!--        <el-tag :type=getType(row.r029)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <el-tooltip :content="row.r030">

        <el-tag :type="getType(row.r030)">贫血</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r031">

        <el-tag :type="getType(row.r031)">轻度贫血</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r032">

        <el-tag :type="getType(row.r032)">中度贫血</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r033">

        <el-tag :type="getType(row.r033)">重度贫血</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r034">

        <el-tag :type="getType(row.r034)">d牙牙数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r035">

        <el-tag :type="getType(row.r035)">m牙牙数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r036">

        <el-tag :type="getType(row.r036)">f牙牙数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r037">

        <el-tag :type="getType(row.r037)">轻度低体重</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r038">

        <el-tag :type="getType(row.r038)">中度低体重</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r039">

        <el-tag :type="getType(row.r039)">重度低体重</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r040">

        <el-tag :type="getType(row.r040)">低体重</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r041">

        <el-tag :type="getType(row.r041)">身高下等</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r042">

        <el-tag :type="getType(row.r042)">身高中下等</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r043">

        <el-tag :type="getType(row.r043)">身高中等</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r044">

        <el-tag :type="getType(row.r044)">身高中上等</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r045">

        <el-tag :type="getType(row.r045)">身高上等</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r046">

        <el-tag :type="getType(row.r046)">d+m+f</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r047">

        <el-tag :type="getType(row.r047)">D+M+F</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r048">

        <el-tag :type="getType(row.r048)">左眼 等效球镜度数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r049">

        <el-tag :type="getType(row.r049)">右眼 等效球镜度数</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r050">

        <el-tag :type="getType(row.r050)">左眼轻度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r051">

        <el-tag :type="getType(row.r051)">左眼中度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r052">

        <el-tag :type="getType(row.r052)">左眼重度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r053">

        <el-tag :type="getType(row.r053)">右眼轻度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r054">

        <el-tag :type="getType(row.r054)">右眼中度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r055">

        <el-tag :type="getType(row.r055)">右眼重度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r056">

        <el-tag :type="getType(row.r056)">左眼近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r057">

        <el-tag :type="getType(row.r057)">右眼近视</el-tag>
      </el-tooltip>
<!--      <el-tooltip :content="row.r058">-->

<!--        <el-tag :type=getType(row.r058)>结核菌素</el-tag>-->
<!--      </el-tooltip>-->
      <el-tooltip :content="row.r059">

        <el-tag :type="getType(row.r059)">整体近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r060">

        <el-tag :type="getType(row.r060)">左眼轻度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r061">

        <el-tag :type="getType(row.r061)">左眼中度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r062">

        <el-tag :type="getType(row.r062)">左眼重度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r063">

        <el-tag :type="getType(row.r063)">右眼轻度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r064">

        <el-tag :type="getType(row.r064)">右眼中度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r065">

        <el-tag :type="getType(row.r065)">右眼重度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r066">

        <el-tag :type="getType(row.r066)">整体轻度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r067">

        <el-tag :type="getType(row.r067)">整体中度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r068">

        <el-tag :type="getType(row.r068)">整体重度视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r069">

        <el-tag :type="getType(row.r069)">乳牙龋齿</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r070">

        <el-tag :type="getType(row.r070)">整体轻度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r071">

        <el-tag :type="getType(row.r071)">整体中度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r072">

        <el-tag :type="getType(row.r072)">整体重度近视</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r073">

        <el-tag :type=getType(row.r073)>整体视力不良</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r074">

        <el-tag :type=getType(row.r074)>恒牙或者乳牙龋齿</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r075">

        <el-tag :type=getType(row.r075)>体重下等</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r076">

        <el-tag :type=getType(row.r076)>体重中等</el-tag>
      </el-tooltip>
      <el-tooltip :content="row.r077">

        <el-tag :type=getType(row.r077)>体重上等</el-tag>
      </el-tooltip>
      <!--      <el-tooltip :content="row.r078">-->

      <!--        <el-tag :type=getType(row.r078)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r079">-->

      <!--        <el-tag :type=getType(row.r079)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r080">-->

      <!--        <el-tag :type=getType(row.r080)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r081">-->

      <!--        <el-tag :type=getType(row.r081)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r082">-->

      <!--        <el-tag :type=getType(row.r082)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r083">-->

      <!--        <el-tag :type=getType(row.r083)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r084">-->

      <!--        <el-tag :type=getType(row.r084)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r085">-->

      <!--        <el-tag :type=getType(row.r085)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r086">-->

      <!--        <el-tag :type=getType(row.r086)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r087">-->

      <!--        <el-tag :type=getType(row.r087)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r088">-->

      <!--        <el-tag :type=getType(row.r088)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r089">-->

      <!--        <el-tag :type=getType(row.r089)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r090">-->

      <!--        <el-tag :type=getType(row.r090)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r091">-->

      <!--        <el-tag :type=getType(row.r091)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r092">-->

      <!--        <el-tag :type=getType(row.r092)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r093">-->

      <!--        <el-tag :type=getType(row.r093)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r094">-->

      <!--        <el-tag :type=getType(row.r094)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r095">-->

      <!--        <el-tag :type=getType(row.r095)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r096">-->

      <!--        <el-tag :type=getType(row.r096)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r097">-->

      <!--        <el-tag :type=getType(row.r097)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r098">-->

      <!--        <el-tag :type=getType(row.r098)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="row.r099">-->

      <!--        <el-tag :type=getType(row.r099)>保留字段</el-tag>-->
      <!--      </el-tooltip>-->


    </el-dialog>

  </div>
</template>

<script>

import * as medicalInformationApi from "@/api/gen/medicalInformationApi"

export default {
  components: {},
  name: "result",
  props: {
    row: {},
    rowCurrent: {}
  },

  data() {
    return {}
  },


  methods: {
    close() {
      this.$emit('close')
    },

    async update() {
      await medicalInformationApi.update({id: this.row.id})
      await medicalInformationApi.getById({id: this.row.id})
    },


    getType(value) {
      if (value === null || value === undefined) {
        return "info";

      }


      if (value === "0") {
        return "success"
      } else {
        return "warning";
      }


    }

  },


  async mounted() {
    this.form = {...this.rowCurrent}
    for (let key in this.row) {
      if (this._.isNumber(this.row[key])) {
        this.row[key] = `${this.row[key]}`

      }
    }
  },


}
</script>

<style scoped>
.el-tag {
  margin: 5px;
  width: 10em;
  text-align: center;
}

</style>
