<template>
  <div>
    <el-card style=" margin: 20px 20px">

      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="角色名称:">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: -60px">
          <el-button v-show="$hasPermission('role.list')" type="primary" @click="list()">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-button v-show="$hasPermission('role.create')" type="primary" @click="addDialog">+ 新增</el-button>
      <el-table
          :data="List"
          :header-cell-style="{background:'#fafafa'}"
          style="width: 100%">
        <el-table-column
            align="left"
            label="角色名称"
            prop="name"
        >
        </el-table-column>
<!--        <el-table-column-->

<!--            label="编码"-->
<!--            prop="code"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            label="创建时间"
            prop="createTime"
            sortable>
        </el-table-column>
        <el-table-column
            align="left"
            label="操作"
            prop="address">
          <template slot-scope="scope">
            <el-button v-show="$hasPermission('role.update')" type="text" @click="editDialog(scope.row)">编辑</el-button>
            <el-button v-show="$hasPermission('role.permission')" type="text" @click="roleDialog(scope.row)">权限
            </el-button>
            <el-button v-show="$hasPermission('role.delete')" type="text" @click="remove(scope.row.id)" style="color: red">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :pageNo.sync="form.pageNo" :pageSize.sync="form.pageSize" :total.sync="total"></pagination>

    </el-card>
    <edit v-if="isAddShow" :row="current" @close="()=>this.isAddShow = !this.isAddShow" @update="list"></edit>
    <role v-if="isRoleShow" :row="current" @close="()=>this.isRoleShow = !this.isRoleShow" @update="list"></role>
  </div>
</template>

<script>
import edit from "./edit"
import role from "./role-admin"
import Pagination from "@/components/Pagination";
import * as roleApi from "@/api/gen/roleApi"

export default {
  components: {
    edit,
    role,
    Pagination
  },
  name: "Role",
  data() {
    return {
      form: {
        name: "",
        code: "",
        pageNo: 1,
        pageSize: 10
      },
      List: [],
      total: 0,
      isAddShow: false,
      isRoleShow: false,
      current: {}
    }
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.handList()
      }
    }
  },

  created() {
    this.list()
    this.handList = this._.debounce(this.list, 1000, false)


  },
  methods: {
    addDialog() {
      this.isAddShow = true
      this.current = {}
    },
    editDialog(data) {
      this.isAddShow = true
      this.current = data
    },
    roleDialog(data) {
      this.isRoleShow = !this.isRoleShow
      this.current = data

    },

    //列表
    async list() {
      const res = (await roleApi.list(this.form));
      for(let i in res.data){
        if(res.data[i].code === 'super-admin'){
          res.data.splice(i,1)
        }
      }
      this.List = res.data
      this.total =  res.data.length

    },
    //删除
    remove(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await roleApi.deleteById({id: id})
        this.$message.success('删除成功')
        await this.list()
      }).catch(()=>{})
    },
  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
}

</style>
