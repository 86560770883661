import _ from 'lodash';

let arr = [
    "小班",
    "中班",
    "大班",
    '一年级', '1年级',
    '二年级', '2年级',
    '三年级', '3年级',
    '四年级', '4年级',
    '五年级', '5年级',
    '六年级', '6年级',
    '初一', "初1",
    '初二', "初2",
    '初三', "初3",
    '高一', "高1",
    '高二', "高2",
    '高三', "高3"
]

function sortByGrade(list, func = (x) => x._id) {

    if (_.isString(func)) {

        func = (x) => x[func];
    }


    list.sort((a, b) => {
        console.log(a, b)
        return indexOf(arr, func(a), 10000) - indexOf(arr, func(b), 10000)
    })
}


function indexOf(arr, key, defaultValue) {


    let index = arr.indexOf(key)
    return index === -1 ? defaultValue : index;
}


export default {
    install(Vue) {
        Vue.prototype.$sortByGrade = sortByGrade
    }
}
