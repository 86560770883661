<template>
  <v-chart :option="option" ref="myChart" class="chart" v-bind="$attrs"/>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, PieChart} from "echarts/charts";
import {GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  BarChart

]);

export default {
  name: "HelloWorld",
  components: {
    VChart
  },
  provide: {},
  props: {
    option: {}
  },
  mounted() {
    // this.$refs.myChart.setOption(this.option, true);
  },
  data() {
    return {};
  }
};
</script>

<style scoped>

</style>
