import Vue from 'vue'
import App from './App.vue'
import plugins from "@/plugin";
import store from './store'
import router from './router'

import '@/utils/request'

import '@/assets/fonticon/iconfont.css'



Vue.config.performance = true

Vue.config.productionTip = false



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
