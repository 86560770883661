<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add' ? '添加': '编辑'"
        :visible="true"
        center
        width="45%"
        @close="close"
    >
      <div class="info-form">
        <el-form ref="form" :model="form" :rules="rules" align="center" label-width="110px">
          <el-form-item label="单位">
            <base-select v-model="form.organizationId"
                         :disabled="status==='edit' || !$hasPermission('unit.allOrganization')" :multiple="false"
                         :search-fn="organizationApi.list"
                         clearable
                         filterable
                         @change="organizationChange"
            >

            </base-select>
          </el-form-item>
          <el-form-item v-if="this.form.organizationId" align="left">
            <div>用户数量：{{ count }}</div>
            <div>最大允许用户数量：{{ userCount }}</div>
          </el-form-item>
          <el-form-item label="账号" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>

          <el-form-item label="用户姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <!--          <el-form-item label="用户角色">-->
          <!--            <base-select v-model="form.roleIds"-->
          <!--                         :multiple="true"-->
          <!--                         :disabled="!$hasPermission('user.editRole')"-->
          <!--                         :search-fn="roleApi.list"></base-select>-->


          <el-form-item label="用户角色" prop="role">
            <el-select v-model="form.roleIds" style="width: 100%;" placeholder="请选择" :multiple="true">
              <el-option
                  v-for="item in checkRoleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="用户密码" prop="password" v-if="status==='add'">
            <el-input v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="用户密码" v-if="status=== 'edit'">
            <el-input v-model="form.password"></el-input>
          </el-form-item>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <my-button :disabled='btnDisabled' type="primary" @click="update">确 定</my-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import * as userApi from "@/api/gen/userApi"
import BaseSelect from "@/components/BaseSelect";
import * as roleApi from "@/api/gen/roleApi"
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  name: "edit",
  props: {
    row: {}
  },

  components: {BaseSelect},

  data() {
    return {
      roleApi,
      organizationApi,
      form: {
        username: '',
        password: '',
        name: '',
        roleIds: [],
        organizationId: '',
        userCount: ''
      },
      count: 0, //当前用户数量
      userCount: 0,

      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},

        ],
        password: [
          {required: true, message: '请选择密码', trigger: 'blur'},

        ]
      },
      model: '',
      checkRoleList: [],
      roleList: []
    }
  },

  watch: {
    "form.organizationId": {
      deep: true,
      async handler(n, o) {
        if (!n) {
          return;
        }

        let organization = (await organizationApi.getById({id: n}));
        this.userCount = organization.userCount
        if (!organization.userCount) {
          this.userCount = 0
        }
        if (!organization) {
          this.count = 0
          return
        }

        let userList = (await userApi.list({organizationId: n}))
        this.count = userList.count

        this.checkRoleList = organization.roleIds?.map(x => {
          return this.roleList.filter(y => y.id === x)?.pop()
        })

      },
      immediate: true

    }
  },

  computed: {

    status() {

      return this.row.id ? "edit" : "add"
    },
    btnDisabled() {

      return !(this.count < this.userCount || this.$store.getters.isSuperAdmin) && this.status === 'add';
    }

  },
  mounted() {
    Object.assign(this.form, this.row)
    this.getRoleList()
    if (this.status === 'add' && !this.$store.getters.hasRole("2")) {
      this.form.roleIds = [...this.$store.getters.roleIds]
    }
    if (this.status === 'add' && this.$store.getters.organizationCode !== 'adminOrg') {
      this.form.organizationId = this.$store.getters.organizationId
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async update(done) {
      try {
        let result
        if (this.status === 'add') {
          //添加
          result = await this.add()
        } else {
          //修改
          result = await this.edit()
        }
        if (result) {
          this.$emit("update")
          this.$emit("close");

        }
      } finally {

        done()
      }


    },

    async add() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        await userApi.create(this.form)
        this.$message.success("添加成功")
        return valid
      } else {
        return false
      }
    },
    async edit() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        await userApi.update(this.form)
        this.$message.success("修改成功")
        return valid
      } else {
        return false
      }

    },

    async getRoleList() {
      let {data} = await roleApi.list()
      this.roleList = data
    },

    organizationChange() {

    }
  }
}
</script>

<style scoped>


</style>
