<template>
  <el-select :value="myValue" v-on="$listeners" v-bind="$attrs" style="width: 100%">
    <el-option v-for="(item,index) in items" :key="index" :label="item" :value="item"></el-option>
  </el-select>


</template>

<script>
import SetDefaultValue from "@/mixins/SetDefaultValue";

export default {
  inheritAttrs: false,
  mixins: [SetDefaultValue],

  name: "yearSelect",
  async beforeMount() {
    for (let i = this.$now().year - 10; i <= this.$now().year; i++) {
      this.items.push(i)
    }

    this.items.reverse();

    this.setDefaultValue(this.$now().year)
  },
  props: {
    value: {}
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    myValue() {
      return this.value;
    }
  },
  watch: {
    myValue(n) {
      this.$store.commit('setTableYear',n)
    }
  },
};
</script>

<style scoped>

</style>
