import Vue from "vue";
export default {

    install(Vue) {


        Vue.prototype.$hasPermission = function (str) {

            return this.$store.getters.hasPermission(str)
        }

        }


}