<template>
  <div>

    <el-card style=" margin: 20px 20px">

      <el-form :model="form" inline label-width="100px">
        <el-form-item>
          <el-input v-model="form.username" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button v-show="$hasPermission('dataDictionary.list')" type="primary" @click="list()">搜索</el-button>
          <el-button v-show="$hasPermission('dataDictionary.create')" type="primary" @click="addDialogShow">添加
          </el-button>
        </el-form-item>
      </el-form>

      <el-table
          :data="treeList"
          :header-cell-style="{background:'#fafafa'}"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          border
          row-key="id"

      >
        <el-table-column
            label="名称"
            prop="name"
        >
        </el-table-column>
        <el-table-column
            label="code"
            prop="code"
        >
        </el-table-column>
        <el-table-column
            label="描述"
            prop="description">
        </el-table-column>

        <el-table-column
            label="状态"
            prop="isDelete"
        >
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.status"
                :active-value="0"
                :inactive-value="1"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="change(scope.row,$event)">

            </el-switch>
          </template>

        </el-table-column>

        <el-table-column
            label="操作"
            prop="address">
          <template slot-scope="scope">
            <el-button v-show="$hasPermission('dataDictionary.update')" plain size="mini" type="primary"
                       @click="updateDialog(scope.row)">编辑
            </el-button>
            <el-button v-show="$hasPermission('dataDictionary.add')" plain size="mini" type="primary"
                       @click="addSubordinate(scope.row)">添加下级
            </el-button>
            <el-button v-show="$hasPermission('dataDictionary.delete')" style="color: red" plain size="mini" type="danger"
                       @click="remove(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>
    <edit v-if="isEditShow" :permissionsList='permissionsList' :row="current" :treeList="treeList"
          @close="()=>this.isEditShow = !this.isEditShow" @update=list></edit>
  </div>
</template>

<script>
import edit from "./edit"
import * as baseDataApi from "@/api/gen/baseDataApi"

export default {
  components: {
    edit,
  },
  name: "permission",
  data() {
    return {
      form: {
        pageSize: 1000000
      },
      formList: [],
      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      permissionsList: [],

      treeList: [],
    }
  },

  watch: {

    form: {
      deep: true,
      handler() {
        this.handList()
      }
    }

  },
  async created() {
    await this.list()
    //防抖
    this.handList = this._.debounce(this.list, 600)
  },
  computed: {
    // tree() {
    //   return this.$buildTree(this.formList)
    // }


  },
  methods: {
    addDialogShow() {
      this.isEditShow = true
      this.current = {};
    },
    updateDialog(data) {
      this.isEditShow = true
      this.current = data;
      console.log(data)
    },
    addSubordinate(data) {
      this.isEditShow = true
      this.current = {parentId: data.id, code: data.code};
    },

    //列表 搜索
    async list() {
      const res = (await baseDataApi.list(this.form));
      this.formList = res.data


      this.permissionsList = this._.chain(this.formList)
          .map(item => ({id: item.id, name: item.name}))
          .value()
      this.treeList = this.$buildTree(this.formList)

      console.log(this.treeList);


    },
    //删除
    async remove(data) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await baseDataApi.deleteById({id: data.id})
        this.$message.success('删除成功')
        await this.list()
      }).catch(() => {
      })


    },

    async change(data, e) {
      let params = {
        id: data.id,
        status: e
      }
      await baseDataApi.update({...params})

    }
  },
  mounted() {


  }

}
</script>

<style scoped>

/*/deep/.el-table td,*/
/*/deep/.el-table th{*/
/*  text-align: center;*/
/*}*/
/*/deep/.el-table td,*/
/*/deep/.el-table th.is-leaf{*/
/*  border: 1px solid #EBEEF5 !important;*/
/*}*/
/*/deep/.el-table--border th, /deep/.el-table__fixed-right-patch{*/
/*  border: 1px solid #EBEEF5 !important;*/
/*}*/


</style>