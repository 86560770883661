class RuleBuilder {


    constructor() {
        this.rule = {};
    }

    //string: Must be of type string. This is the default type.
    // number: Must be of type number.
    // boolean: Must be of type boolean.
    // method: Must be of type function.
    // regexp: Must be an instance of RegExp or a string that does not generate an exception when creating a new RegExp.
    // integer: Must be of type number and an integer.
    // float: Must be of type number and a floating point number.
    // array: Must be an array as determined by Array.isArray.
    // object: Must be of type object and not Array.isArray.
    // enum: Value must exist in the enum.
    // date: Value must be valid as determined by Date
    // url: Must be of type url.
    // hex: Must be of type hex.
    // email: Must be of type email.
    // any: Can be any type.
    type(t) {

        this.rule.type = t;

        return this;
    }

    typeString() {
        this.type('string');
        return this;
    }

    typeNumber() {
        this.type('number');
        return this;
    }

    typeBoolean() {
        this.type('boolean');
        return this;
    }

    typeMethod() {
        this.type('method');
        return this;
    }

    typeRegexp() {
        this.type('regexp');
        return this;
    }

    typeInteger() {
        this.type('integer');
        return this;
    }

    typeFloat() {
        this.type('float');
        return this;
    }

    typeArray() {
        this.type('array');
        return this;
    }

    typeObject() {
        this.type('object');
        return this;
    }

    typeEnum() {
        this.type('enum');
        return this;
    }

    typeDate() {
        this.type('date');
        return this;
    }

    typeUrl() {
        this.type('url');
        return this;
    }

    typeHex() {
        this.type('hex');
        return this;
    }

    typeEmail() {
        this.type('email');
        return this;
    }

    typeAny() {
        this.type('any');
        return this;
    }

    required(b = true) {

        this.rule.required = b;
        return this;
    }

    pattern(p) {

        this.rule.pattern = p;
        return this;
    }

    min(p) {

        this.rule.min = p;
        return this;
    }

    max(p) {

        this.rule.max = p;
        return this;
    }

    len(p) {

        this.rule.len = p;
        return this;
    }

    trigger(t) {
        this.rule.trigger = t;

        return this;
    }

    blur() {

        this.trigger("blur")
        return this;
    }

    change() {
        this.trigger("change")
        return this;
    }

    message(m) {
        this.rule.message = m;


        return this;
    }

    email() {
        this.typeString();
        this.pattern(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);
        return this
    }

    phone() {
        this.typeString();
        this.pattern(/^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[235-8]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|66\d{2})\d{6}$/)
        return this;
    }

    age() {
        this.typeNumber();
        this.min(0);
        this.max(200);
        return this
    }

    qq() {
        this.typeString();
        this.pattern(/^[1-9][0-9]{4,10}$/);
        return this
    }


    build() {
        if (!this.rule.trigger) {
            this.rule.trigger = 'change';
        }

        if (!this.rule.message) {

            if (this.rule.required) {
                this.rule.message = '必填项';
            } else if (this.rule.min || this.rule.max) {
                this.rule.message = `长度在 ${this.rule.min} 到 ${this.rule.max} 之间`;
            } else if (this.rule.pattern) {
                this.rule.message = '请填写合适的数据'
            } else if (this.rule.type) {
                this.rule.message = '类型错误';

            }

        }
        return this.rule;
    }

}


export default () => new RuleBuilder();
