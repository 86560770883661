<template>
  <div>
    <el-card style="height: 60px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item,index) in fullName" :key="index">{{ item }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Elcard",
  props: {
    currentPath: ""
  },
  data() {
    return {
      paths: []
    }

  },
  mounted() {
  },
  methods: {
    findFullName(menu) {
      this.paths.push(menu.name)
      if (menu.path === this.currentPath) {
        return menu.name;
      }
      if (menu.children) {
        // console.log("开始查找 " + menu.path + "子节点")
        for (const child of menu.children) {
          let ans = this.findFullName(child);
          if (ans) {
            return ans;
          }
          this.paths.pop()
        }
      }
      return null;
    },
  },
  computed: {
    fullName() {
      let menus = this.$store.state.menu
      for (const menu of menus) {
        // console.log("开始查找"  +menu.path)
        this.paths = [];
        let ans = this.findFullName(menu);
        if (ans) {
          break
        }
      }
      // console.log(this.paths)
      // return this.paths.join("/");
      this.$storage.setStorage("pageName",this.paths.slice(-1))
      return this.paths;
    },



  }

}
</script>

<style scoped>

</style>