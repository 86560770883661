<template>

  <div style="display: flex; align-items: stretch; justify-content: space-between">

    <div style="width: 40%">

      <el-input type="textarea" rows="25" v-model="javaCode"></el-input>
    </div>

    <div style="width: 55%">


      <el-tabs type="card" v-model="activeName">
        <el-tab-pane label="table代码" name="tableCode">

          <el-input type="textarea" rows="20" :value="tableCode"></el-input>

        </el-tab-pane>
        <el-tab-pane label="form代码" name="formCode">

          <el-input type="textarea" rows="20" :value="formCode"></el-input>
        </el-tab-pane>
        <el-tab-pane label="text代码" name="textCode">

          <el-input type="textarea" rows="20" :value="textCode"></el-input>
        </el-tab-pane>
      </el-tabs>
    </div>


  </div>


</template>

<script>
export default {
  name: "CodeGen",
  data() {
    return {
      activeName: "tableCode",
      javaCode: "",
    }
  },
  methods: {},
  computed: {
    tableCode() {

      return this.parseJavaCode.map(item => {

        return `
        <el-table-column
          label="${item.name}">
            <template slot-scope="scope">

                <span>{{scope.row.${item.code}}}</span>
            </template>
        </el-table-column>
        `

      }).join("\n")

    },

    parseJavaCode() {
      let regex = /(\/\*\*.+?\*\/)\s+private.+?\s(\w+);/gms
      let match;
      let arr = []
      while ((match = regex.exec(this.javaCode)) != null) {
        console.log(match)

        let comment = match[1];
        if (comment) {

          comment = comment.replace(/\//g, "").replace(/\*/g, "").split("\n").map(x => x.trim()).filter(x => !this._.isEmpty(x))[0];

        }
        arr.push({

          name: comment,
          code: match[2]

        });
      }
      console.log(arr);

      return arr
    },

    formCode() {

      return this.parseJavaCode.map(item => {

        return `
      <el-form-item prop="${item.code}" label="${item.name}">
        <el-input v-model="form.${item.code}"></el-input>
      </el-form-item>
        `

      }).join("\n")
    },

    textCode() {

      return this.parseJavaCode.map(item => {

        return `
       <div>

             <span class="name"> ${item.name} </span> <span class="code"> {{ form.${item.code} }} </span>
       </div>

        `

      }).join("\n")
    }

  }
}
</script>

<style scoped>

</style>
