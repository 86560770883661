//中小学身高信息
export function SGInfo(data) {
    if (data.r041) {
        return ('身高下等');
    } else if (data.r042) {
        return ('身高中下等');
    } else if (data.r043) {
        return ('身高中等');
    } else if (data.r044) {
        return ('身高中上等');
    } else if (data.r045) {
        return ('身高上等');
    } else {
        return ('　');
    }
}

//中小学体重信息
export function TZInfo(data) {
    // if (data.r004) {
    //     return ('超重')
    // } else if (data.r005) {
    //     return ('肥胖')
    // } else if (data.r037) {
    //     return ('轻度低体重');
    // } else if (data.r038) {
    //     return ('中度低体重');
    // } else if (data.r039) {
    //     return ('重度低体重');
    // } else if (data.r040) {
    //     return ('低体重');
    if (data.r075) {
        return ('体重下等')
    } else if (data.r076) {
        return ('体重中等')
    } else if (data.r077) {
        return ('体重上等')
    } else {
        return ('　');
    }
}

//中小学营养状况
export function YYInfo(data) {
    if (data.r003) {
        return "营养不良"
    } else if (data.r004) {
        return "超重"
    } else if (data.r005) {
        return "肥胖"
    } else if (data.r020) {
        return "生长迟缓"
    } else if (data.r021) {
        return "轻度消瘦"
    } else if (data.r022) {
        return "中重度消瘦"
    } else {
        return ('正常');
    }
}

export function SGInfoShow(row,data){
    if (data.r041) {
        row.push('身高下等');
    } else if (data.r042) {
        row.push('身高中下等');
    } else if (data.r043) {
        row.push('身高中等');
    } else if (data.r044) {
        row.push('身高中上等');
    } else if (data.r045) {
        row.push('身高上等');
    } else {
        row.push(' ');
    }
}


export function YYInfoShow(row,data){
    if (data.r003) {
        row.push("营养不良")
    } else if (data.r004) {
        row.push("超重")
    } else if (data.r005) {
        row.push("肥胖")
    } else if (data.r020) {
        row.push("生长迟缓")
    } else if (data.r021) {
        row.push("轻度消瘦")
    } else if (data.r022) {
        row.push("中重度消瘦")
    } else {
        row.push("正常")
    }
}

export function TZInfoShow(row, data) {
    // if (data.r037) {
    //     row.push('轻度低体重');
    // } else if (data.r038) {
    //     row.push('中度低体重');
    // } else if (data.r039) {
    //     row.push('重度低体重');
    // } else if (data.r040) {
    //     row.push('低体重');
    // } else
    if (data.r075) {
        row.push('体重下等');
    } else if (data.r076) {
        row.push('体重中等')
    } else if (data.r077) {
        row.push('体重上等')
    } else {
        row.push('');
    }
}


/*------------------------------------------------------------------------幼儿园-----------------------------------------------------------*/

//幼儿园身高信息
export function yeySGInfo(data) {
    if (data.r041) {
        return ('身高下等');
    } else if (data.r043) {
        return ('身高中等');
    } else if (data.r045) {
        return ('身高上等');
    } else {
        return ('　');
    }
}

//幼儿园体重信息
export function yeyTZInfo(data) {
    if (data.r075) {
        return ('体重下等')
    } else if (data.r076) {
        return ('体重中等')
    } else if (data.r077) {
        return ('体重上等')
    } else {
        return ('　');
    }
}

//幼儿园营养状况
export function yeyYYInfo(data) {
    if (data.r037) {
        return "轻度低体重"
    } else if (data.r038) {
        return "中度低体重"
    } else if (data.r039) {
        return "重度低体重"
    } else if (data.r029) {
        return "消瘦"
    } else if (data.r020) {
        return "生长迟缓"
    } else if (data.r004) {
        return "超重"
    } else {
        return ('正常');
    }
}


export function YeyYYInfoShow(row,data) {
    if (data.r037) {
        row.push("轻度低体重")
    } else if (data.r038) {
        row.push("中度低体重")
    } else if (data.r039) {
        row.push("重度低体重")
    } else if (data.r029) {
        row.push("消瘦")
    } else if (data.r020) {
        row.push("生长迟缓")
    } else if (data.r004) {
        row.push("超重")
    } else {
        row.push("正常")
    }
}

export function YeySGInfoShow(row,data) {
    if (data.r041) {
        row.push('身高下等');
    } else if (data.r043) {
        row.push('身高中等');
    } else if (data.r045) {
        row.push('身高上等');
    } else {
        row.push(' ');
    }
}
export function YeyTZInfoShow(row,data) {
    if (data.r075) {
        row.push('体重下等');
    } else if (data.r076) {
        row.push('体重中等')
    } else if (data.r077) {
        row.push('体重上等')
    } else {
        row.push('');
    }
}
export default {

    install(Vue) {
        Vue.prototype.$YYInfo = YYInfo
        Vue.prototype.$SGInfo = SGInfo
        Vue.prototype.$TZInfo = TZInfo
        Vue.prototype.$YYInfoShow = YYInfoShow
        Vue.prototype.$SGInfoShow = SGInfoShow
        Vue.prototype.$TZInfoShow = TZInfoShow
        Vue.prototype.$yeyYYInfo = yeyYYInfo
        Vue.prototype.$yeySGInfo = yeySGInfo
        Vue.prototype.$yeyTZInfo = yeyTZInfo
        Vue.prototype.$YeyYYInfoShow = YeyYYInfoShow
        Vue.prototype.$YeySGInfoShow = YeySGInfoShow
        Vue.prototype.$YeyTZInfoShow = YeyTZInfoShow
    }

}
