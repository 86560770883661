import request from '@/utils/request'

export function changeMyPassword(data) {

  return request({
    url: '/user/changeMyPassword',
    method: 'POST',
    data})
}

   

export function create(data) {

  return request({
    url: '/user/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/user/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/user/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/user/deleteByIds',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/user/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/user/getByIds',
    method: 'GET',
    params})
}

   

export function list(params) {

  return request({
    url: '/user/list',
    method: 'GET',
    params})
}

   

export function update(data) {

  return request({
    url: '/user/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/user/update/batch',
    method: 'POST',
    data})
}

   