<template>
  <div>
    <el-card style=" margin: 20px 20px">

      <el-form
          ref="form"
          :model="form"
          label-width="80px"

      >
        <el-form-item label="单位名称 :">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="单位类型 :">
          <data-select type="string" v-model="form.type" parent-code="OrganizationType" :clearable="false"></data-select>
        </el-form-item>
        <el-form-item style="margin-left: -50px">
          <el-button v-show="$hasPermission('unit.list')" type="primary" @click="list()">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-button v-show="$hasPermission('unit.create')" type="primary" @click="editDialog">+ 新增</el-button>
      <div style="position: relative">
        <el-table
            v-loading="loading"
            :data="formList"
            :header-cell-style="{background:'#fafafa'}"
        >
          <el-table-column
              label="单位名称"
              prop="name"

          >
          </el-table-column>
          <el-table-column
              label="单位地址"
              prop="address"

          >
          </el-table-column>
          <el-table-column
              label="单位类型"
              prop="type"

          >
            <template slot-scope="scope">
              <div>{{ menu.OrganizationTypeEnum[scope.row.type] }}</div>
            </template>
          </el-table-column>

          <el-table-column
              v-if="!showColumn"
              label="最大用户数量"
              prop="userCount"
              width="150"

          >
          </el-table-column>

          <el-table-column
              label="联系电话"
              prop="phone"

          >
          </el-table-column>
          <el-table-column
              label="联系人"
              prop="contact"

          >
          </el-table-column>

          <el-table-column
              v-if="!showColumn"
              label="授权有效期"


          >
            <template slot-scope="scope">
              <div v-if="scope.row.authEndTime">
                {{ inDate(scope.row.authEndTime) }}
              </div>
              <div v-else>0</div>
            </template>
          </el-table-column>
          <el-table-column

              label="操作">
            <template slot-scope="scope">
             <div style="display: flex">
               <el-button v-show="$hasPermission('unit.relevanceSchool')" size="mini" type="text"
                          @click="schloolDialogShow(scope.row)">关联学校
               </el-button>
               <el-button v-if="scope.row.type===3 || scope.row.type===4" v-show="$hasPermission('unit.gradeClass')"
                          size="mini"
                          type="text" @click="gradeClassDialog(scope.row)">关联年级班级
               </el-button>
               <el-button v-show="$hasPermission('unit.update')" size="mini" type="text"
                          @click="updateDialog(scope.row)">修改
               </el-button>
               <el-button v-show="$hasPermission('unit.delete')" size="mini" type="text" style="color: red"
                          @click="remove(scope.row.id)">
                 删除
               </el-button>
             </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination :pageNo.sync="form.pageNo" :pageSize.sync="form.pageSize" :total.sync="total"></pagination>

    </el-card>
    <edit v-if="isEditShow" :row="current" :orgList="formList" @close="() => this.isEditShow = !this.isEditShow" @update="update"></edit>
    <relevance v-if="isRelevance" :row="current" @close="() => this.isRelevance = !this.isRelevance"></relevance>
    <grade-class v-if="isGradeClass" :row="gradeClassCurrent"
                 @close="() => this.isGradeClass = !this.isGradeClass"></grade-class>


    <schoolTable v-if="showSchoolTable" :row="current" @close="showSchoolTable = ! showSchoolTable" @update="list()"></schoolTable>

  </div>
</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi"
import * as dataMenu from "@/assets/js/data"
import edit from "./edit"
import Pagination from '@/components/Pagination'
import relevance from "./relevance-school"
import gradeClass from "./gradeClass"
import DataSelect from "@/components/DataSelect";
import schoolTable from "@/views/unit/schoolTable";

export default {
  components: {
    edit,
    Pagination,
    relevance,
    gradeClass,
    DataSelect,
    schoolTable
  },
  name: "Unit",
  data() {
    return {
      menu: dataMenu,
      form: {
        name: "",
        type:'0',
        pageNo: 1,
        pageSize: 10
      },
      formList: [],
      total: 0,
      isEditShow: false,
      isRelevance: false,
      isGradeClass: false,
      current: {},
      gradeClassCurrent: {},
      showSchoolTable: false,
      loading: true
    }
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.list()
      }
    }
  },

  computed: {
    now() {
      return this.$dateTimeString(this.$now())
    },
    showColumn() {
      if( this.form.type === '1' || this.form.type === '3' || this.form.type === '4' ){
        return true
      }
    }
  },
  created() {
    this.list()
    this.handList = this._.debounce(this.list, 1000)
  },
  methods: {
    editDialog() {
      this.isEditShow = true
      this.current = []
    },
    updateDialog(data) {
      this.isEditShow = true
      this.current = data
    },
    schloolDialogShow(data) {
      this.showSchoolTable = true
      this.current = data
    },
    gradeClassDialog(data) {
      this.isGradeClass = true
      this.gradeClassCurrent = data
    },


    //列表 搜索
    async list() {
      this.loading = true
      const res = (await organizationApi.list(this.form));
      this.formList = res.data
      this.total = res.count
      this.loading = false
    },
    //删除
    async remove(id) {
      this.$confirm('删除单位会同时删除该单位下所有用户,确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await organizationApi.deleteById({id: id})
        this.$message.success('删除成功')
        await this.list()
      }).catch(() => {
      })


    },
    inDate(date) {
      let tmp = this.$diff(this.now, date)?.days?.toFixed(0)
      if (tmp < 0) {
        return `已过期${Math.abs(tmp)}天`
      }
      if (tmp > 0) {
        return `${tmp}天`
      }
      if (tmp === 0) {
        return `今天到期`
      }

    },

    update(e){

      this.form.type = e + ''
      this.list()

    }


  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
}

/*/deep/ .el-table .cell{*/
/*  display: flex;*/
/*}*/


</style>
