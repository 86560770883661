<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add' ? '添加': '编辑'"
        :visible="true"
        center
        width="50%"
        @close="close"

    >
      <el-form ref="form" :model="form" align="center" label-width="8em" style="padding: 10px">
        <el-form-item label="项目名称 :">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="code :">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item align="left" label="类型 :">


          <el-checkbox-group v-model="form.types" @change="changeTypes">
            <el-checkbox v-for="(item,index) in this.SchoolTypeEnum" :key="index" :label="index">{{ item }}
            </el-checkbox>

          </el-checkbox-group>

        </el-form-item>
        <el-form-item label="描述 :">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item align="left" label="填写类型 :">
          <el-radio-group v-model="form.inputType">
            <el-radio v-for="(item,index) in InputTypeEnum" :key="index" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手输提示 :" v-if="isTypeShoushu">
          <el-input v-model="form.tips"></el-input>
        </el-form-item>

        <el-form-item label="选择细项 :" v-if="isTypeXuanze">
          <el-input v-model="form.options"></el-input>
        </el-form-item>
        <el-form-item label="默认值">
          <el-input v-model="form.defaultOption"></el-input>
        </el-form-item>
        <el-form-item label="是否有子项" align="left">
          <el-radio-group v-model="form.hasSub">
            <el-radio v-for="(item,index) in hasSubEnum" :key="index" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="form.hasSub === 1">
          <el-form-item label="子项描述 :">
            <el-input v-model="form.subItemDescription"></el-input>
          </el-form-item>
          <el-form-item align="left" label="子项填写类型 :">
            <el-radio-group v-model="form.subItemInputType">
              <el-radio v-for="(item,index) in InputTypeEnum" :key="index" :label="index">{{ item }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手输提示 :" v-if="isItemShoushu">
            <el-input v-model="form.subItemTips"></el-input>
          </el-form-item>
          <el-form-item label="选择细项 :" v-if="isItemXuanze">
            <el-input v-model="form.subItemOptions"></el-input>
          </el-form-item>
          <el-form-item label="默认值">
            <el-input v-model="form.subItemDefaultOption"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <my-button type="primary" @click="update">确 定</my-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import * as checkItemApi from "@/api/gen/checkItemApi";
import * as dataMenu from "@/assets/js/data"


export default {
  name: "edit",
  props: {
    row: {}
  },


  data() {
    return {
      menu: dataMenu,
      form: {
        name: '',
        types: [],
        description: '',
        inputType: 0,
        tips: '',
        options: [],
        subItemDescription: '',
        subItemInputType: 0,
        subItemTips: '',
        subItemOptions: [],
        code: '',
        defaultOption: '',
        subItemDefaultOption: '',
        hasSub: 1

      },
      isTypeShoushu: false,
      isTypeXuanze: false,
      isItemShoushu: false,
      isItemXuanze: false,
    }
  },

  watch: {

    "form.inputType": {
      deep: true,
      handler(e) {
        if (e === 0) {
          this.isTypeShoushu = !this.isTypeShoushu;
          this.isTypeXuanze = false
        }
        if (e === 1) {
          this.isTypeXuanze = !this.isTypeXuanze;
          this.isTypeShoushu = false
        }
        if (e === 2) {
          this.isTypeShoushu = false
          this.isTypeXuanze = false
        }
      }
    },

    "form.subItemInputType": {
      deep: true,
      handler(e) {
        if (e === 0) {
          this.isItemShoushu = !this.isItemShoushu;
          this.isItemXuanze = false
        }
        if (e === 1) {
          this.isItemXuanze = !this.isItemXuanze;
          this.isItemShoushu = false
        }
        if (e === 2) {
          this.isItemShoushu = false
          this.isItemXuanze = false
        }
      }
    }
  },

  mounted() {
    if (this.form.inputType === 0) {
      this.isTypeShoushu = true
    }
    if (this.form.subItemInputType === 0) {
      this.isItemShoushu = true
    }

    const tm = this._.cloneDeep(this.row);
    tm.options = tm.options?.join(",");
    tm.subItemOptions = tm.subItemOptions?.join(",")


    Object.assign(this.form, tm);


  },
  computed: {

    status() {
      return this.row.id ? "edit" : "add"
    },
    SchoolTypeEnum() {
      return Object.values(dataMenu.SchoolTypeEnum)
    },
    InputTypeEnum() {
      return Object.values(dataMenu.InputTypeEnum)
    },
    hasSubEnum() {
      return Object.values(dataMenu.hasSubEnum)
    }
  },

  methods: {
    close() {
      this.$emit("close")
    },
    async update(done) {
      let result
      if (this.status === 'add') {
        result = await this.add()
      } else {
        result = await this.edit()
      }

      if (result) {
        this.$emit("update")
        this.$emit("close");
      }
      done()
    },

    async add() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        let clone = this._.cloneDeep(this.form)
        clone.options = this.form.options?.split(',')
        clone.subItemOptions = this.form.subItemOptions?.split(',')
        await checkItemApi.create(clone)
        this.$message.success('添加成功')
        return valid
      } else {
        return false
      }
    },
    async edit() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        let clone = this._.cloneDeep(this.form)
        clone.options = this.form.options?.split(',')
        clone.subItemOptions = this.form.subItemOptions?.split(',')
        await checkItemApi.update(clone)
        this.$message.success('修改成功')
        return valid
      } else {
        return false
      }

    },


    changeTypes(e) {

      console.log(e)

    },


  }
}
</script>

<style scoped>

</style>
