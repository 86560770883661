import request from '@/utils/request'

export function create(data) {

  return request({
    url: '/script/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/script/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/script/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/script/deleteByIds',
    method: 'POST',
    data})
}

   

export function execute(data) {

  return request({
    url: '/script/execute',
    method: 'POST',
    data})
}

   

export function executeAsync(data) {

  return request({
    url: '/script/executeAsync',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/script/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/script/getByIds',
    method: 'GET',
    params})
}

   

export function list(params) {

  return request({
    url: '/script/list',
    method: 'GET',
    params})
}

   

export function update(data) {

  return request({
    url: '/script/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/script/update/batch',
    method: 'POST',
    data})
}

   