<template>
  <div>
    <el-dialog
        :show-close=true
        :visible="true"
        center
        title="关联学校"
        width="50%"
        @close="close"
        :showRelevance="false"
        :close-on-click-modal="false"
    >

      <div>
        <el-button size="mini" type="primary" @click="handleRelevance">添加</el-button>
      </div>
      <el-table
          size="mini"
          :data="tableData"
          style="width: 100%">
        <el-table-column
            label="学校名称"
            prop="name"
            width="170px"

        >
        </el-table-column>
        <el-table-column
            label="学校地址"
            prop="address"

            width="240px"
        >
        </el-table-column>
        <el-table-column
            label="学校类型"
            prop="type"

        >
          <template slot-scope="scope">
            <div v-if="scope.row.type === 3">中小学</div>
            <div v-else-if="scope.row.type === 4">幼儿园</div>
            <div v-else></div>
          </template>
        </el-table-column>


        <el-table-column
            label="联系电话"
            prop="phone"

        >
        </el-table-column>
        <el-table-column
            label="联系人"
            prop="contact"

        >
        </el-table-column>
        <el-table-column
            label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" style="color: red" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="submit">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

    <relevance v-if="showRelevance" :row="row" :schoolListId="schoolListId" @selectedSchool="selectedSchool($event)"
               @close="() => this.showRelevance = !this.showRelevance"></relevance>

  </div>
</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi";
import * as dataMenu from "@/assets/js/data"

import relevance from "./relevance-school"

export default {
  name: "schoolTable",
  components: {relevance},
  props: {
    row: {}
  },
  data() {
    return {
      menu: dataMenu,
      showRelevance: false,
      schoolListId: [],
      schoolList: [],
      schoolInfo: {},
    }
  },
  async mounted() {
    await this.getSchoolList()
  },

  computed: {

    tableData() {

      return this.schoolListId.map(x => {

        return this.schoolList.filter(y => x === y.id).pop()

      }).filter(item => !!item)

    }

  },


  methods: {
    close() {
      this.$emit('close')
    },
    handleRelevance() {
      this.showRelevance = true
    },
    selectedSchool(e) {
      console.log(e.schools)
      this.schoolInfo = e
      this.schoolListId.push(...e.schools)
      this.schoolListId = this._.uniq(this.schoolListId)


      this.getSchoolList()

    },


    async getSchoolList() {
      let res = await organizationApi.list({pageSize: 10000})
      this.schoolList = res.data.filter(item => item.type === 3 || item.type === 4)

      let orgInfo = res.data.filter(item => item.id === this.row.id)?.pop()
      this.schoolListId = this._.uniq(orgInfo.schools)

    },


    handleDelete(row) {

      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        this.schoolListId.splice(this.schoolListId.indexOf(row.id), 1)
      }).catch(() => {
      })


    },
    async submit() {
      let obj = {
        id: this.row.id,
        schools: this.schoolListId
      }
      await organizationApi.update(obj)
      this.$message.success("添加成功")
      this.close()
      this.$emit('update')

    }
  }


}
</script>

<style scoped>
.active {
  color: #1890ff;
}

.el-scrollbar__wrap {
  margin-right: -16px !important;
}

.el-card {
  min-width: 13em;
}


.wrapclass {
  line-height: 2.0;
}

</style>
