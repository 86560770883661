<template>
  <div>
    <el-card style=" margin: 20px 20px">

      <el-form ref="form" inline label-width="auto">
        <el-form-item label="操作人 :">
          <el-input v-model="form.createByName"></el-input>
        </el-form-item>
        <el-form-item label="操作类型 :">
          <el-input v-model="form.sumModuleName"></el-input>
        </el-form-item>
        <el-form-item label="操作模块 :">
          <el-input v-model="form.moduleName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="list" v-show="$hasPermission('logManagement.list')">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table
          :data="List">
        <el-table-column
            label="操作人"
            prop="createByName"
            width="100px"
        >
        </el-table-column>
        <el-table-column
            label="操作时间"
            prop="createTime"
        >
        </el-table-column>
        <el-table-column
            label="操作类型"
            prop="sumModuleName"
        >
        </el-table-column>
        <el-table-column
            label="操作模块"
            prop="moduleName">
        </el-table-column>
        <el-table-column
            label="IP地址"
            prop="createIp">
        </el-table-column>
        <el-table-column
            label="日志详情"
            prop="content">
          <template slot-scope="scope">
            <el-popover
                placement="left-start"
                width="200"
                trigger="click"
                :content="scope.row.content">
              <el-button type="text" slot="reference" v-show="$hasPermission('logManagement.viewlog')">查看日志</el-button>
            </el-popover>
          </template>
        </el-table-column>

      </el-table>


      <pagination :total.sync="total" :pageNo.sync="form.pageNo" :pageSize.sync="form.pageSize"
                  @changed="list"></pagination>
    </el-card>


  </div>
</template>

<script>
import * as sysLogApi from "@/api/gen/sysLogApi";
import pagination from "@/components/Pagination"

export default {
  name: "logManagement",
  components: {
    pagination
  },
  data() {
    return {
      List: [],
      form: {
        createByName: '',
        sumModuleName: '',
        moduleName: "",
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],

    }
  },

  watch: {

    form: {
      deep: true,
      handler() {
        this.handList()
      }
    }

  },

  async created() {
    await this.list()
    this.handList = this._.debounce(this.list, 600, false)

  },
  methods: {
    //列表 搜索
    async list() {
      const res = (await sysLogApi.list(this.form));
      this.List = res.data
      this.total = res.count
    },


  }

}
</script>

<style scoped>


</style>
