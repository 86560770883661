<template>
  <div>

    <el-card style=" margin: 20px 20px">

      <el-form :model="form" inline label-width="100px">

        <el-form-item label="单位：">
          <base-select v-model="form.organizationId" :search-fn="organizationApi.list" clearable filterable
                       :disabled="!$hasPermission('unit.allOrganization')"></base-select>
        </el-form-item>

        <el-form-item label="账号：">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="用户名称：">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="用户角色：">
          <base-select v-model="form.roleIds"
                       :disabled="!$hasPermission('user.editRole')"
                       :search-fn="roleApi.list"

                       :clearable="true"
          >

          </base-select>
        </el-form-item>
        <el-form-item>
          <el-button v-show="$hasPermission('user.list')" type="primary" @click="list()">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-button v-show="$hasPermission('user.create') && $store.getters.inDate>=0" type="primary"
                 @click="addDialogShow">+ 新增
      </el-button>
      <el-table
          :data="List"
          :header-cell-style="{background:'#fafafa'}"
          border
      >
        <el-table-column
            align="left"
            label="单位"
            prop="organizationVO.name"
        >
        </el-table-column>

        <el-table-column
            align="left"
            label="账号"
            prop="username"
        >
        </el-table-column>
        <el-table-column

            label="用户名称"
            prop="name"
        >
        </el-table-column>
        <el-table-column
            label="用户角色"
            prop="roleIds">
          <template slot-scope="scope">

            <div>{{ findRole(scope.row.roleIds) }}</div>

          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center"
        >

          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="change(scope.row,$event)"
                v-show="$hasPermission('user.delete') && $store.getters.inDate>=0">

            </el-switch>
          </template>

        </el-table-column>


        <el-table-column
            align="left"
            label="操作"
            prop="address">
          <template slot-scope="scope">
            <el-button v-show="$hasPermission('user.update') && $store.getters.inDate>=0" type="text"
                       @click="updateDialog(scope.row)">修改
            </el-button>
            <el-button v-show="$hasPermission('user.dataPermission') && $store.getters.inDate>=0" type="text"
                       @click="roleAdminDialog(scope.row) ">数据权限
            </el-button>
            <el-button v-show="$hasPermission('user.delete') && $store.getters.inDate>=0" type="text"
                       style="color: red" @click="remove(scope.row.id) ">删除
            </el-button>

          </template>
        </el-table-column>
      </el-table>

<!--      <pagination :pageNo.sync="form.pageNo" :pageSize.sync="form.pageSize" :total.sync="total"></pagination>-->
    </el-card>
    <edit v-if="isEditShow" :row="current" @close="()=>this.isEditShow = !this.isEditShow" @update=list()></edit>
    <role-admin v-if="isRoleAdmin" :row="current" @close="()=>this.isRoleAdmin = !this.isRoleAdmin"
                @update=list()></role-admin>
  </div>
</template>

<script>
import edit from "./edit"
import pagination from "@/components/Pagination"
import BaseSelect from "@/components/BaseSelect";
import roleAdmin from './role-admin'
import * as userApi from "@/api/gen/userApi"
import * as organizationApi from "@/api/gen/organizationApi"
import * as roleApi from "@/api/gen/roleApi"

export default {
  components: {
    BaseSelect,
    edit,
    pagination,
    roleAdmin
  },
  name: "user",
  data() {
    return {
      roleApi,
      organizationApi,
      form: {
        organizationId: this.$store.getters.organizationId,
        username: "",
        name: "",
        user: '',
        roleIds: '',

      },
      total: 0,
      List: [],
      isEditShow: false,
      isUpdateDialog: false,
      isRoleAdmin: false,
      current: {},
      roleList: []

    }
  },

  watch: {

    form: {
      deep: true,
      handler() {
        this.handList()
      }
    },


  },
  created() {
    this.list()
    //防抖
    this.handList = this._.debounce(this.list, 600)
  },
  computed: {},
  methods: {
    async addDialogShow() {
      this.isEditShow = true
      this.current = {};
    },
    updateDialog(data) {
      this.isEditShow = true
      this.current = data;
    },
    roleAdminDialog(data) {
      this.current = data;
      this.isRoleAdmin = true
    },


    //删除
    remove(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {

        await userApi.deleteById({id: id})
        this.$message({
          type: "success",
          message: "删除成功"
        })

        await this.list()

      }).catch((err) => {

      })


    },


    //禁用

    async change(data, e) {
      console.log(data, e)
      let params = {
        id: data.id,
        status: e
      }
      await userApi.update({...params})

    },


    //列表 搜索
    async list() {
      let clone = {...this.form}
      if (this.form.roleIds) {
        clone.roleIds = clone.roleIds + ','
      }
      const res = (await userApi.list({...clone,pageSize:100000}));


      for (let index in res.data) {
        if (res.data[index].username === 'adminuser') {
          res.data.splice(index, 1)
        }

      }

      this.List = res.data

      console.log(res)

    },


    async role() {
      let res = await roleApi.list()
      this.roleList = res.data
    },


    findRole(arr) {
      let array = []
      this.roleList.forEach(item => {
        arr.find((val) => {
          if (val === item.id) {
            return array.push(item.name)
          }
        })

      })

      array = array.join('、')
      return array
    },


  },
  mounted() {
    this.role()
  }

}
</script>

<style scoped>
/deep/ .el-form--inline .el-form-item__label {
  display: unset;
}

</style>
