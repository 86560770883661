import request from '@/utils/request'

export function create(data) {

  return request({
    url: '/announcement/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/announcement/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/announcement/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/announcement/deleteByIds',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/announcement/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/announcement/getByIds',
    method: 'GET',
    params})
}

   

export function list(params) {

  return request({
    url: '/announcement/list',
    method: 'GET',
    params})
}

   

export function update(data) {

  return request({
    url: '/announcement/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/announcement/update/batch',
    method: 'POST',
    data})
}

   