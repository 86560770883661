<template>
  <div style="font-size: 14px">
    <el-card style="margin: 20px">
      <div style="display: flex; margin-bottom: 10px">
        <div style="white-space: nowrap"> 营养不良：</div>
        <div>
          （3）消瘦：身（长）高别体重 < 均数 -2SD
        </div>
      </div>


      <div style="display: flex; justify-content: flex-end">
        <el-button :class="sex === '男'? '': 'active'" type="primary" @click="sex = '男'">男</el-button>
        <el-button :class="sex === '女'? '': 'active'" type="primary" @click="sex = '女'">女</el-button>
      </div>
      <div style="display: flex; justify-content: center;">
        <template  v-if="sex === '男'">
          <div style="width: 90%; display: flex; justify-content: center; flex-direction: column ">
            <div>
              <div style="text-align: center; margin-bottom: 30px; font-size: 22px">WHO(世界卫生组织)0-2岁{{ sex }}童身长别体重标准</div>
              <div style="text-align: center; margin-bottom: 15px; font-size: 22px">(2006年版)</div>
              <el-table
                  style="width: 100%;"
                  :data="boy0_2"
                  :header-cell-style="{background:'#fafafa'}">
                <el-table-column
                    label="身长(cm)"
                    prop="height"
                >

                </el-table-column>

                <el-table-column
                    label="-3SD"
                    prop="p1"
                >
                </el-table-column>
                <el-table-column
                    label="-2SD"
                    prop="p2"
                >
                </el-table-column>
                <el-table-column
                    label="-1SD"
                    prop="p3"
                >
                </el-table-column>
                <el-table-column
                    label="均数"
                    prop="p4"
                >
                </el-table-column>
                <el-table-column
                    label="1SD"
                    prop="p5"
                >
                </el-table-column>
                <el-table-column
                    label="2SD"
                    prop="p6"
                >
                </el-table-column>
                <el-table-column
                    label="3SD"
                    prop="p7"
                >
                </el-table-column>


              </el-table>
            </div>

            <div style="margin-top: 30px">
              <div style="text-align: center; margin-bottom: 30px; font-size: 22px">WHO(世界卫生组织)2-6岁{{ sex }}童身长别体重标准</div>
              <div style="text-align: center; margin-bottom: 15px; font-size: 22px">(2006年版)</div>
              <el-table
                  style="width: 100%;"
                  :data="boy2_6"
                  :header-cell-style="{background:'#fafafa'}">
                <el-table-column
                    label="身长(cm)"
                    prop="height"
                >

                </el-table-column>

                <el-table-column
                    label="-3SD"
                    prop="p1"
                >
                </el-table-column>
                <el-table-column
                    label="-2SD"
                    prop="p2"
                >
                </el-table-column>
                <el-table-column
                    label="-1SD"
                    prop="p3"
                >
                </el-table-column>
                <el-table-column
                    label="均数"
                    prop="p4"
                >
                </el-table-column>
                <el-table-column
                    label="1SD"
                    prop="p5"
                >
                </el-table-column>
                <el-table-column
                    label="2SD"
                    prop="p6"
                >
                </el-table-column>
                <el-table-column
                    label="3SD"
                    prop="p7"
                >
                </el-table-column>


              </el-table>
            </div>

          </div>
        </template>

       <template v-if="sex === '女'">
         <div  style="width: 90%; display: flex; justify-content: center; flex-direction: column ">
           <div>
             <div style="text-align: center; margin-bottom: 30px; font-size: 22px">WHO(世界卫生组织)0-2岁{{ sex }}童身长别体重标准</div>
             <div style="text-align: center; margin-bottom: 15px; font-size: 22px">(2006年版)</div>
             <el-table
                 style="width: 100%;"
                 :data="girl0_2"
                 :header-cell-style="{background:'#fafafa'}">
               <el-table-column
                   label="身长(cm)"
                   prop="height"
               >

               </el-table-column>

               <el-table-column
                   label="-3SD"
                   prop="p1"
               >
               </el-table-column>
               <el-table-column
                   label="-2SD"
                   prop="p2"
               >
               </el-table-column>
               <el-table-column
                   label="-1SD"
                   prop="p3"
               >
               </el-table-column>
               <el-table-column
                   label="均数"
                   prop="p4"
               >
               </el-table-column>
               <el-table-column
                   label="1SD"
                   prop="p5"
               >
               </el-table-column>
               <el-table-column
                   label="2SD"
                   prop="p6"
               >
               </el-table-column>
               <el-table-column
                   label="3SD"
                   prop="p7"
               >
               </el-table-column>


             </el-table>
           </div>
           <div style="margin-top: 30px">
             <div style="text-align: center; margin-bottom: 30px; font-size: 22px">WHO(世界卫生组织)2-6岁{{ sex }}童身长别体重标准</div>
             <div style="text-align: center; margin-bottom: 15px; font-size: 22px">(2006年版)</div>
             <el-table
                 style="width: 100%;"
                 :data="girl2_6"
                 :header-cell-style="{background:'#fafafa'}">
               <el-table-column
                   label="身长(cm)"
                   prop="height"
               >

               </el-table-column>

               <el-table-column
                   label="-3SD"
                   prop="p1"
               >
               </el-table-column>
               <el-table-column
                   label="-2SD"
                   prop="p2"
               >
               </el-table-column>
               <el-table-column
                   label="-1SD"
                   prop="p3"
               >
               </el-table-column>
               <el-table-column
                   label="均数"
                   prop="p4"
               >
               </el-table-column>
               <el-table-column
                   label="1SD"
                   prop="p5"
               >
               </el-table-column>
               <el-table-column
                   label="2SD"
                   prop="p6"
               >
               </el-table-column>
               <el-table-column
                   label="3SD"
                   prop="p7"
               >
               </el-table-column>


             </el-table>
           </div>


         </div>
       </template>
      </div>


    </el-card>
  </div>
</template>

<script>
import * as baseDataYEYSGTZBZApi from "@/api/gen/baseDataYEYSGTZBZApi"

export default {
  name: "yeyStatureBase",
  data() {
    return {
      sex: "男",
      list: []
    }
  },
  async mounted() {
    const res = (await baseDataYEYSGTZBZApi.list({pageSize: 100000}))


    this.list = res.data

  },
  computed: {
    form() {

      return this._
          .chain(this.list)
          .groupBy(item => item.sex)
          .mapValues(item => this._.sortBy(item, item => parseFloat(item.height)))
          .value()
    },

    boy0_2() {
      return this.form[0]?.filter(item => item.age === 2)
    },
    boy2_6() {
      return this.form[0]?.filter(item => item.age === 6)
    },
    girl0_2() {
      return this.form[1]?.filter(item => item.age === 2)
    },
    girl2_6() {
      return this.form[1]?.filter(item => item.age === 6)
    }


  },
  methods: {
    changeSex(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.active {
  background-color: #fff;
  color: #000;
}


/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}

</style>
