export function isEnabled(obj, key) {

    if (key?.includes("Sub")){


        key = key.replace("Sub", "")
    }

    try {

        return obj.checkItemStatus[parseInt(key.substring(1))] === "1";
    } catch (e) {
        console.log(e);
        return false;
    }


};


export const 未做 = "未做";