<template>
  <div style="font-size: 14px">
    <el-card style="margin: 20px">
      <div style="font-size: 14px; line-height: 1.5">
        <div>
          体重等级分为5个等级：体重＜-2SD为下等；体重≥-2SD且＜-1SD为中下等；体重≥-1SD且≤+1SD为中等；体重＞+1SD且≤+2SD为中上等；体重＞+2SD为上等。注：SD为标准差。（备注：假如只统计上、中、下三个等级，中上等和中下等的数据归属到中等这里去）

        </div>
        <div style="color: #ff0000; margin-top: 10px">提示：下面表格主要是看中小学的体重标准差范围，忽略身高标准差</div>
      </div>

      <div style="display: flex; justify-content: flex-end">
        <el-button :class="sex === '男'? '': 'active'" type="primary" @click="sex = '男'">男</el-button>
        <el-button :class="sex === '女'? '': 'active'" type="primary" @click="sex = '女'">女</el-button>
      </div>
      <div style="text-align: center; margin-bottom: 30px">0~18岁儿童青少年体重标准差单数值表({{ sex }})</div>


      <div style="display: flex; justify-content: center;">
        <div style="width: 90%; display: flex; justify-content: center; flex-direction: column ">
          <div style="align-self: flex-end; margin-bottom: 10px">单位为千克</div>
          <el-table
              v-if="sex === '男'"
              style="width: 100%;"
              :data="form[0]"
              :header-cell-style="{background:'#fafafa'}">
            <el-table-column
                label="年龄"
                prop="age">

            </el-table-column>
            <el-table-column
                label="-3SD"
                prop="p1">

            </el-table-column>
            <el-table-column
                label="-2SD"
                prop="p2">

            </el-table-column>
            <el-table-column
                label="-1SD"
                prop="p3">

            </el-table-column>
            <el-table-column
                label="中位数"
                prop="p4">

            </el-table-column>
            <el-table-column
                label="+1SD"
                prop="p5">

            </el-table-column>
            <el-table-column
                label="+2SD"
                prop="p6">

            </el-table-column>
            <el-table-column
                label="+3SD"
                prop="p7">

            </el-table-column>

          </el-table>
          <el-table
              v-else
              style="width: 100%;"
              :data="form[1]"
              :header-cell-style="{background:'#fafafa'}">
            <el-table-column
                label="年龄"
                prop="age">

            </el-table-column>
            <el-table-column
                label="-3SD"
                prop="p1">

            </el-table-column>
            <el-table-column
                label="-2SD"
                prop="p2">

            </el-table-column>
            <el-table-column
                label="-1SD"
                prop="p3">

            </el-table-column>
            <el-table-column
                label="中位数"
                prop="p4">

            </el-table-column>
            <el-table-column
                label="+1SD"
                prop="p5">

            </el-table-column>
            <el-table-column
                label="+2SD"
                prop="p6">

            </el-table-column>
            <el-table-column
                label="+3SD"
                prop="p7">

            </el-table-column>

          </el-table>
        </div>
      </div>


    </el-card>
  </div>
</template>

<script>
import * as baseDataZXXTZDJPJApi from "@/api/gen/baseDataZXXTZDJPJApi"

export default {
  name: "weightBase",
  data() {
    return {
      sex: "男",
      list: []
    }
  },
  async mounted() {
    const res = (await baseDataZXXTZDJPJApi.list({pageSize: 100000}))
    this.list = res.data

  },
  computed: {
    form() {

      return this._
          .chain(this.list)
          .groupBy(item => item.sex)
          .mapValues(item => this._.sortBy(item, item => parseFloat(item.age)))
          .value()
    }

  },
  methods: {
    changeSex(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.active {
  background-color: #fff;
  color: #000;
}


/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}

</style>
