
// 时间选择器 - 多种输入格式
import Vue from "vue";

export default  Vue.directive('dateFormat', {
    inserted: function (el, binding, vnode, oldVnode) {
        // console.log('el',el)
        // console.log('binding',binding)
        // console.log('vnode',vnode)
        const { value: _obj } = binding
        const { context: that, data } = vnode
        const { expression: key } = data.model

        const input = vnode.elm.children[0]
        input.addEventListener('change',function (e){
            let value = e.target.value

            // 中文日期
            if (!value.match(/\d{1,}/g)) {
                const chinaNum = ['〇', '一', '二', '三', '四', '五', '六', '七', '八', '九']
                const wList = [...value].map(w => {
                    const idx = chinaNum.findIndex(p => p == w)
                    return idx == -1 ? w : idx
                })
                value = wList.join('')
            }

            value = value.replace(/^(\d{4})\D*(\d{1,2})\D*(\d{1,2})\D*/, '$1-$2-$3') // 格式化输入格式
            const time = value && value.constructor == String ? new Date(value) : value  // 转换时间格式
            let keys = key.split('.')

            // 自定义赋值
            if (_obj) {
                const { keys: keyList } = _obj
                keys = keyList
            }

            if (keys && keys.length >= 2) {
                const [key1, key2, key3, key4] = keys

                if (key4) {
                    that[key1][key2][key3][key4] = time
                } else if (key3) {
                    that[key1][key2][key3] = time
                } else {
                    that[key1][key2] = time
                }
            } else {
                that[key] = time
            }


        })
    }
})