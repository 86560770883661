<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add' ? '添加': '编辑'"
        :visible="true"
        center
        width="45%"
        @close="close"
    >
      <div class="info-form">
        <el-form ref="form" :model="form" align="center" label-width="110px">
          <el-form-item label="单位">
            <base-select ref="baseSelect" v-model="form.schoolId" :disabled="status==='edit'" :multiple="false"
                         :search-fn="organizationApi.list"
                         clearable filterable>

            </base-select>
          </el-form-item>
          <el-form-item label="所属地区">
            <div style="display: flex; flex-wrap: nowrap; justify-content: space-between">
              <!--省-->
              <el-select v-model="form.province" placeholder="请选择省">
                <el-option
                    v-for="item in provinceList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                >
                </el-option>
              </el-select>
              <!--市-->
              <el-select v-model="form.city" placeholder="请选择市">
                <el-option
                    v-for="item in cityList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
              <!-- 县-->
              <el-select v-model="form.county" placeholder="请选择县">
                <el-option
                    v-for="item in countryList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="年份" prop="year">
            <year-select v-model="form.year"></year-select>
          </el-form-item>


          <el-form-item label="体检总人数" prop="count">
            <el-input v-model="form.count"></el-input>
          </el-form-item>


          <el-form-item label="学校ID" prop="schoolId">
            <el-input v-model="form.schoolId"></el-input>
          </el-form-item>


          <el-form-item label="单位名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>

          <el-form-item label="详细地址" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>


          <el-form-item label="单位类型" prop="type">
            <el-input v-model="form.type"></el-input>
          </el-form-item>


          <el-form-item label="城乡类型" prop="cxType">
            <el-input v-model="form.cxType"></el-input>
          </el-form-item>


          <el-form-item label="联系人" prop="contact">
            <el-input v-model="form.contact"></el-input>
          </el-form-item>


          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>


        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update('form')">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect";
import yearSelect from "@/components/yearSelect";
import * as roleApi from "@/api/gen/roleApi"
import * as organizationApi from "@/api/gen/organizationApi"
import * as areaCodeApi from "@/api/gen/areaCodeApi"
import * as schoolStatisticApi from "@/api/gen/schoolStatisticApi"


export default {
  name: "edit",
  props: {
    row: {}
  },

  components: {BaseSelect,yearSelect},

  data() {
    return {
      roleApi,
      organizationApi,
      form: {
        "id": "",
        "year": "",
        "count": "",
        "schoolId": "",
        "name": "",
        "code": "",
        "province": "",
        "city": "",
        "county": "",
        "address": "",
        "type": "",
        "cxType": "",
        "contact": "",
        "phone": "",
        "pageNo": 0,
        "pageSize": 0,
        "sortKey": "",
        "sortDirection": "",
        "organizationId": "",
        "userId": ""
      },


      provinceList: [],
      model: ''
    }
  },
  watch: {

    'form.schoolId':{
      handler(n){
        let arr =  this.$refs.baseSelect.getItems()
        let obj =  arr.filter(x => x.value===n)[0]._source
        Object.assign(this.form, obj)


      }
    },

    'form.province': {
      handler(n, o) {
        if (!o) {
          return;
        }
        this.form.city = this.cityList?.[0]?.code
      }
    },
    'form.city': {
      handler(n, o) {
        if (!o) {
          return;
        }
        this.form.county = this.countryList?.[0]?.code
      }
    }
  },

  computed: {
    status() {

      return this.row.id ? "edit" : "add"
    },
    cityList() {
      for (const item of this.provinceList) {
        if (item.code === this.form.province) {

          return item.children


        }

      }
      return [];
    },
    countryList() {

      for (const item of this.cityList) {
        if (item.code === this.form.city) {
          if (item.children) {
            return item.children
          } else {
            return []
          }
        } else {
          // 没找到 下一个
          continue
        }
      }
    },
  },

  async mounted() {
    Object.assign(this.form, this.row)
    await this.loadCity()

  },
  methods: {
    close() {
      this.$emit("close")
    },
    async update(data) {
      let result
      if (this.status === 'add') {
        //添加
        result = await this.add()
      } else {
        //修改
        result = await this.edit()
      }
      if (result) {
        this.$emit("update")
        this.$emit("close");

      }



    },

    async loadCity() {
      const res = (await areaCodeApi.list({pageSize: 10000}))
      this.provinceList = res.data
    },

    async add() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        this.$message.success("提交成功")
        await schoolStatisticApi.create(this.form)
        return valid
      } else {
        return false
      }
    },
    async edit() {
      let valid = await this.$refs.form.validate()
      if (valid) {
        await schoolStatisticApi.update(this.form)
        this.$message.success("修改成功")
        return valid
      } else {
        return false
      }

    }
  }
}
</script>

<style scoped>


</style>
