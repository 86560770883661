<template>

  <div>
    <el-dialog title="代码生成" :visible="true" @close="close">
      <codemirror :code="code" :options="options"></codemirror>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="close">确 定</el-button>
      </div>
    </el-dialog>


  </div>

</template>

<script>
export default {
  name: 'codeGen',

  props: {
    tree: {
      type: Array,
      default: []

    }
  },
  computed: {
    code() {
      let ans = ''
      this.$dfs(this.tree, node => {
        console.log(node)
        if (!node.children || node.children.length === 0) {

          ans += `export const ${this.voca.capitalize(
            this.voca.camelCase(node.code.replace('.', ' ')))} = "${node.code}"; \n`

        }

        return node

      })

      return ans
    },
    options() {

      return {

        mode: "text/javascript",
        lineNumber: true,
        theme:"tomorrow-night-bright",
        extraKeys: {"Ctrl": "autocomplete"}, //自动提示

      }
    }
  }

  ,
  methods: {
    close() {
      this.$emit('close')
    }
  }

}
</script>

<style scoped>

</style>
