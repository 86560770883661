<template>
  <el-dialog
      title="切换用户"
      :visible="true"
      width="30%"
      :show-close="false"
  >
    <el-form ref="form" label-width="auto">
      <el-form-item label="单位" prop="organizationId">
        <organization-select v-model="form.organizationId"  :is-filter-school="true" filterable @change="changeOrganization"
                             @clear='clear'></organization-select>
      </el-form-item>

      <div style="display: flex; align-items: center;">
        <div v-if="users.length">用户</div>
        <div style=" margin-left: 10px">
          <el-button v-for="item in users"
                     :key="item.id"
                     type="primary"
                     plain
                     @click="changeUser(item.id)"
                     style="margin-right: 10px"
                     size="mini"
                     :loading=item.btnLoading
          >{{ item.name +' ' +item.username }}
          </el-button>

        </div>
      </div>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="exitSwitch" :loading=loading>退出切换</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as userApi from "@/api/gen/userApi"
import * as authApi from "@/api/gen/authApi"
import OrganizationSelect from "@/components/OrganizationSelect";
import BaseSelect from "@/components/BaseSelect";

export default {
  name: "switchUser",
  components: {OrganizationSelect, BaseSelect},
  data() {
    return {
      userApi,
      users: [],
      form: {
        organizationId: '',
        userId: ''
      },
      loading: false,
      switchIng: ''


    }
  },
  computed: {
    username() {
      return this.users?.filter(item => item.id === this.form.userId).pop()?.username
    }
  },
  mounted() {
    this.switchIng = localStorage.getItem('switchIng')
  },
  methods: {
    close() {
      this.$emit('close')
    },
    //切换单位
    async changeOrganization() {
      if (this.form.organizationId) {
        const res = (await userApi.list({pageSize: 1000, organizationId: this.form.organizationId})).data
        console.log(res)
        res.forEach(item => item.btnLoading = false)
        this.users = res
        this.form.userId = ''
      }


    },
    //切换用户
    async changeUser(id) {
      this.users.filter(item => item.id === id).pop().btnLoading = true
      const res = await authApi.loginByUserId({id: id})
      this.$store.commit("switchUser", res)
      this.$router.push({path:'/'})
      this.$emit('close')
    },
    //退出切换
    async exitSwitch() {
      this.loading = true
      await this.$store.commit("exitSwitch")
      this.$emit('close')

    },
    clear() {
      if (this.users.length) {
        this.users = ""
      }
    }
  },
}
</script>

<style scoped>

</style>
