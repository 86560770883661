<template>
  <el-card style="margin: 20px; font-size: 14px">
    <div style="font-size: 14px; font-weight: bold">体重指数（BMI）计算公式：BMI=体重（kg）÷[身高（m）]²，BMI保留一位小数点来比较。</div>
    <div style="font-size: 14px; margin: 10px 0 20px 20px ;">
      <div>6岁~18岁男女学龄儿童青少年风年龄身高筛查神生长迟缓界值范围，见附录A的表A.1。</div>
      <div style="margin: 20px 0 ;">
        使用表B.1界值范围进行消瘦判断。凡BMI小于或等于相信性别、年龄组“中重度消廋”界值范围者为中度消瘦；凡NMI处于相应性别、年龄组“轻度消瘦”界值范围者额外轻度消瘦。
      </div>
      <div>5.5 报告结果分为“生长迟缓”“轻度消瘦”“中重读消瘦”，分别计算检出率（%）；三者合计得“营养不良”率（%）。在每个群体中，“营养不良”率和“非营养不良”率得比例合计应为100%</div>
    </div>
  <div style="color: red">注意：营养不良的判断必须具备生长迟缓和消瘦两个条件</div>
    <div style="display: flex; justify-content: center">
      <div style="width: 60%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
        <div style="margin: 40px 0 20px 0 ;">表 A.1 6岁~18岁男女学龄儿童青少年分年龄身高筛查生长迟缓界值范围</div>
        <div style="align-self: flex-end; margin-bottom: 10px">单位为厘米</div>
        <el-table
            :data="List"
            style="width: 100%"
            :header-cell-style="{background:'#fafafa'}">
          <el-table-column
              label="年龄/岁"
              prop="age"
          >
          </el-table-column>
          <el-table-column
              label="男生"
              prop="manP1"
          >
          </el-table-column>
          <el-table-column
              label="女生"
              prop="girlP1">
          </el-table-column>
        </el-table>

        <div style="margin: 40px 0 20px 0 ;">表 B.1 6岁~18岁男女学龄儿童青少年分年龄BMI筛查消瘦界值范围</div>
        <div style="align-self: flex-end; margin-bottom: 10px">单位为千克每平方米</div>
        <el-table
            :data="BMIList"
            style="width: 100%"
        >
          <el-table-column
              label="年龄/岁"
              prop="age"
          >
          </el-table-column>
          <el-table-column
              label="男生"
          >
            <el-table-column
                label="中重度消瘦"
                prop="manP1"
            >
            </el-table-column>
            <el-table-column
                label="轻度消瘦"
                prop="manP2"
            >
            </el-table-column>

          </el-table-column>
          <el-table-column
              label="女生">
            <el-table-column
                label="中重度消瘦"
                prop="girlP1"
            >
            </el-table-column>
            <el-table-column
                label="轻度消瘦"
                prop="girlP2"
            >
            </el-table-column>
          </el-table-column>
        </el-table>

      </div>
    </div>
  </el-card>
</template>

<script>
import * as baseDataZXXBMISZCHApi from "@/api/gen/baseDataZXXBMISZCHApi";
import * as baseDataZXXBMIXSApi from "@/api/gen/baseDataZXXBMIXSApi";

export default {
  name: "nutritionBase",
  data() {
    return {
      form1: [],
      form2: [],
    }
  },
  async mounted() {
    this.form1 = (await baseDataZXXBMISZCHApi.list({pageSize: 100000})).data;
    this.form2 = (await baseDataZXXBMIXSApi.list({pageSize: 100000})).data;


  },
  computed: {
    List() {
      return this._
          .chain(this.form1)
          .groupBy('age')
          .mapValues(item => {

            if (item.length === 1) {
              let current = item[0].sex;
              return [
                item[0],
                {
                  sex: current === 0 ? 1 : 0
                }
              ]

            }

            return item;


          })
          .mapValues(item => this._.sortBy(item, x => x.sex))

          .map((value, key) => {
            return {
              age: key,
              manP1: value[0].p1,
              girlP1: value[1].p1
            }
          })
          .sortBy(i => parseFloat(i.age))
          .value();
    },
    BMIList() {
      return this._
          .chain(this.form2)
          .groupBy('age') // {'12岁': [{}]}
          .mapValues(item => {

            if (item.length === 1) {
              let current = item[0].sex;
              return [
                item[0],
                {
                  sex: current === 0 ? 1 : 0
                }
              ]

            }

            return item;


          })
          .mapValues(item => this._.sortBy(item, x => x.sex))

          .map((value, key) => {

            return {
              age: key,
              manP1: value[0].p1,
              manP2: value[0].p2,
              girlP2: value[1].p2,
              girlP1: value[1].p1

            }
          })
          .sortBy(x => parseFloat(x.age))
          .value()

    }

  },
  methods: {}
}


</script>

<style scoped>
/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
