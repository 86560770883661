<template>
  <div>
    <el-card style=" margin: 20px 20px">

      <el-form ref="form" label-width="80px">
        <el-form-item label="用户代码:">
          <el-input v-model="form.code"></el-input>
        </el-form-item>

        <el-form-item style="margin-left: -60px">
          <el-button v-show="$hasPermission('projectsetting.list')" type="primary" @click="getList()">搜索</el-button>
        </el-form-item>

      </el-form>
      <el-button v-show="$hasPermission('projectsetting.create')" type="primary" @click="addDialogShow">+ 新增</el-button>
      <el-radio-group v-model="active" style="display: flex; justify-content: center; margin-bottom: 20px">
        <el-radio-button label="1">中小学</el-radio-button>
        <el-radio-button label="0">幼儿园</el-radio-button>
      </el-radio-group>


      <el-table
          :data="checkList"
          :header-cell-style="{background:'#fafafa'}"
      >
        <el-table-column
            align="left"
            label="序号"
            prop="code"
        >
        </el-table-column>
        <el-table-column
            label="项目名称"
            prop="name"
        >
        </el-table-column>
        <el-table-column
            label="类型"
            prop="types">
          <template slot-scope="scope">
            <span v-for="item in scope.row.types" style="margin-right: 10px">{{ menu.SchoolTypeEnum[item] }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="填写类型"
            prop="inputType">
          <template slot-scope="scope">
            <div>{{ menu.InputTypeEnum[scope.row.inputType + ""] }}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="选择细项"
            prop="options"
        >
        <template slot-scope="scope">
          {{scope.row.options.toString()}}
        </template>
        </el-table-column>

        <el-table-column
            label="排序"
        >
          <template slot-scope="scope">
            <el-input-number
                :key="scope.row.id"
                v-model="scope.row.sortKey"
                @change="sortKeyChange(scope.row)"
                size="small"
                :min="1"
                :max="100"
                controls-position="right"
            >
            </el-input-number>
          </template>
        </el-table-column>

        <el-table-column
            align="left"
            label="操作"
        >
          <template slot-scope="scope">
            <el-button v-show="$hasPermission('projectsetting.update')" @click="updateDialog(scope.row)">修改</el-button>
            <el-button v-show="$hasPermission('projectsetting.delete')" @click="remove(scope.row.id)"
                       style="color: red">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>





    </el-card>
    <edit v-if="isEditShow" :row="current" @close="()=>this.isEditShow = !this.isEditShow" @update="getList"></edit>
  </div>
</template>

<script>
import edit from "./edit"
import * as checkItemApi from "@/api/gen/checkItemApi";
import Pagination from "@/components/Pagination"
import * as dataMenu from "@/assets/js/data"

export default {
  components: {
    edit,
    Pagination
  },
  name: "projectSettings",
  data() {
    return {
      schools:[{id:0,type:'幼儿园',primary:''}, {id:1,type:'中小学',primary:''}],
      primary:'',
      menu: dataMenu,
      form: {
        code: '',
        pageNo: 1,
        pageSize: 500
      },
      list: [],
      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      num: 1,
      active: '1'
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.handList()
      }
    }
  },
  computed: {
    checkList(){
      if(this.active === '0') {
        return this.list?.filter(item=> item?.types?.includes(0))
      }
      if(this.active === '1') {
        return this.list?.filter(item=> item?.types?.includes(1))
      }
    },
    total(){
      return this.checkList?.length
    },


  },

  created() {
    this.handList = this._.debounce(this.getList, 600, false)
  },

  async mounted() {
    await this.getList()



  },
  methods: {

    addDialogShow() {
      this.isEditShow = true
      this.current = {};

    },
    updateDialog(data) {
      this.isEditShow = true
      this.current = data;
      console.log(this.current)
    },

    //列表
    async getList() {
      const res = (await checkItemApi.list(this.form));
      this.list = this._.chain(res.data).sortBy(x => parseFloat(x.sortKey)).value()
    },
    //删除
    async remove(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await checkItemApi.deleteById({id: id})
        this.$message.success('删除成功')
        await this.getList()
      }).catch(() => {
      })

    },

//排序
    async sortKeyChange(row) {
      await checkItemApi.update({id: row.id, sortKey: row.sortKey})
      this.getList()
    },

  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
}

.el-table .el-button {
  border: 0;
  color: #3C90F7;
  background-color: transparent;
  padding: 12px 0;
}

.el-pagination {
  margin-top: 20px;
}
</style>