<template>

  <div style="padding: 20px;">

    <el-form :inline="true" :model="form" style="margin: 20px 0 0 20px">

      <el-form-item>

        <el-button type="primary" @click="list" btn-id="list">搜索</el-button>
        <el-button type="primary" @click="add" btn-id="create">添加</el-button>
      </el-form-item>
      <el-form-item>

        <el-button type="primary" @click="exportCurrent" btn-id="export">导出当前页</el-button>
      </el-form-item>
      <!--      <el-button type="primary" @click="exportAll">导出全部 {{ form.total }} 条</el-button>-->
    </el-form>


    <el-table
        id="exportTab"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="id"
          label="ID"
      >
      </el-table-column>
      <el-table-column
          label="名称"
          prop="name"
      >
      </el-table-column>
      <el-table-column
          label="cron表达式"
          prop="cron"
      >
      </el-table-column>

      <el-table-column
          prop="scriptVO.name"
          label="脚本"
      >
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态">
        <template slot-scope="scope">

          <el-tag v-if="scope.row.status === 1" type="success">启用</el-tag>
          <el-tag v-if="scope.row.status === 0" type="danger">禁用</el-tag>

        </template>
      </el-table-column>



      <el-table-column
          prop="email"
          label="操作" width="100px">
        <template slot-scope="scope">

          <div style="display: flex; justify-content: space-between">


            <el-link type="primary" @click="edit(scope.row)" btn-id="update">编辑</el-link>
            <el-link type="danger" @click="deleteById(scope.row)" btn-id="delete">删除</el-link>

          </div>

        </template>
      </el-table-column>
    </el-table>


    <pager :pageNo.syncc="form.pageNo" :pageSize.sync="form.pageSize" :total="form.total"></pager>

    <edit :status="status" :row="{}" @close="list" v-if="status === 'add'"></edit>
    <edit :status="status" :row="row" @close="list" v-if="status === 'edit'"></edit>


  </div>


</template>

<script>
import * as api from "@/api/gen/cronJobApi"
import Pager from "@/components/Pagination";
import Edit from "@/views/cronJob/Edit";
import OrganizationSelect from "@/components/OrganizationSelect";

export default {
  name: "cronJob",
  components: {OrganizationSelect, Edit, Pager},
  data() {
    return {
      tableData: [],
      status: "list",
      row: {},
      form: {
        username: "",
        name: "",
        phone: "",
        email: "",
        pageNo: 1,
        pageSize: 20,
        total: 0,


      },

    }
  },
  beforeMount() {
    this.list();
  },
  methods: {
    async list() {
      this.status = 'list';
      let data = await api.list({...this.form});
      this.tableData = data.data;
      this.form.total = data.count;


    },

    async deleteById(obj1) {
      await api.deleteById({id: obj1.id});
      await this.list();

    },


    async edit(obj1) {
      this.status = 'edit';
      this.row = obj1;

    },


    async add() {
      this.status = 'add';

    },
    exportCurrent() {
      console.log("exportCurrent")
      this.$exportToExcel("table", "#exportTab")

    }
  },


  watch: {
    form: {
      deep: true,
      handler: 'list'


    }
  },
}
</script>

<style scoped>

</style>
