<template>
  <div class="container">
    <div class="login-box">
      <div class="logo"></div>
      <div class="title">学生体检评价管理系统</div>
      <div class="login">
        <el-form ref="loginRef"
                 :model="loginForm"
                 :rules="rules"
                 label-width="0px">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="账号">
              <i slot="prefix" class="iconfont icon-zhanghao"></i>
            </el-input>

          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" placeholder="密码" show-password>
              <i slot="prefix" class="iconfont icon-mima"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="captchaCode" style="position: relative">
            <el-input v-model="loginForm.captchaCode" placeholder="输入验证码" style="width: 264px;"></el-input>
            <img :src=this.captchaBase64 style="width: 98px; height: 40px; position: absolute; right: 0"
                 @click="getAuthCode()">
          </el-form-item>
<!--          <el-form-item>-->
<!--            <el-checkbox v-model="radio">自动登录</el-checkbox>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button class="login-button" type="primary" @click="onSubmit('loginForm')">登录</el-button>
          </el-form-item>
        </el-form>

      </div>


    </div>
  </div>
</template>

<script>
import * as authApi from "@/api/gen/authApi.js"
import * as nationalityCodeApi from "@/api/gen/nationalityCodeApi"
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  name: 'Login',
  components: {},
  props: {},

  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        captchaCode: '',
        captchaId: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},

        ],
        password: {required: true, message: '请输入密码', trigger: 'blur'},
        captchaCode: {required: true, message: '请输入验证码', trigger: 'blur'},
      },
      captchaBase64: "",
      radio: ''
    };
  },

  computed: {},
  watch: {},
  methods: {

    //获取验证码
    async getAuthCode() {
      const res = (await authApi.captcha())
      this.captchaBase64 = "data:image/jpg;base64," + res.captchaBase64
      this.loginForm.captchaId = res.captchaId
    },
    //登录
    async onSubmit() {
      let valid = (await this.$refs.loginRef.validate())

      if (!valid) return
      const res = (await authApi.login(this.loginForm))
      this.token = res.token
      this.$store.commit('login', res)
      this.$store.commit("setNationalityList", (await nationalityCodeApi.list()).data)
      //
      // let organizationInfo = (await organizationApi.getById({id: this.$store.getters.organizationId}))
      // this.$store.commit('setOrganizationInfo', organizationInfo)
      this.$refs.loginRef.resetFields()
      await this.$router.push({name: 'home'})

    }
  },
  created() {
    this.getAuthCode()
    window.onkeydown = (e)=>{
      if(e.key === 'Enter'){
        this.onSubmit()
      }
    }
  },


  mounted() {

  },

};
</script>

<style scoped>
.container {
  height: 100vh;
  background: url('../../assets/images/bg-img.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

}

.login-box {
  width: 517px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.logo {
  width: 211px;
  height: 62px;
  background: url('../../assets/images/logo.png') no-repeat center center;
  background-size: contain;

}

.title {
  font-family: Microsoft YaHei sans-serif;
  color: rgba(1, 125, 239, 1);
  font-weight: bold;
  font-size: 32px;
  margin: 20px 0 60px 0;
}

.login {
  width: 517px;
  height: 394px;
  color: rgba(80, 80, 80, 1);
  background-color: rgba(255, 255, 255, 1);
  font-size: 14px;
  line-height: 150%;
  box-shadow: 0 25px 25px 0 rgba(74, 197, 255, 0.04285714285714292);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.el-input {
  width: 368px;
  height: 40px;
}


.el-button {
  width: 98px;
}

.login-button {
  width: 368px;
  height: 40px;
}

.iconfont {
  font-size: 20px;
}
</style>
