<template>
  <div>
    <!--  项目结果--修改-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :title="this.status === 'add'? '添加项目结果':'修改项目结果'"
        :visible="true"
        center
        width="40%"
        @close="close"
    >
      <el-form ref="form" :model="form" :rules="rules" align="center" label-width="100px">
        <el-form-item label="项目结果：" prop="result">
          <el-input v-model="form.result"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update('form')">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import * as testResultAndAdviceApi from "@/api/gen/testResultAndAdviceApi"

export default {
  name: "edit",
  props: {
    row: {}
  },


  data() {
    return {
      form: {
        result: '',
      },
      current: "",
      rules: {
        result: [
          {required: true, message: '请输入项目结果', trigger: 'blur'},
        ]
      }

    }
  },

  mounted() {
    Object.assign(this.form, this.row)
  },
  computed: {


    status() {
      return this.row.id ? "edit" : "add"
    }

  },
  methods: {
    close() {
      this.$emit("close")
    },
    async update(data) {
      let result;
      if (this.status === 'add') {
        result = await this.add();
      } else {
        result = await this.edit()
      }
      if (result) {
        this.$emit("update");
        this.$emit("close");
      }
    },

    async add() {
      let valid = await this.$refs.form.validate();
      if (valid) {

        await testResultAndAdviceApi.create(this.form)
        this.$message.success("添加成功");
        return valid
      }else {
        return  false
      }
    },
    async edit() {
      let valid =  await this.$refs.form.validate()
      if(valid){
        await testResultAndAdviceApi.update(this.form)
        this.$message.success("修改成功")
        return valid
      }else {
        return false
      }

    },


  }
}
</script>

<style scoped>

</style>