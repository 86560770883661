export let SchoolTypeEnum = {
    "0": "幼儿园",
    "1": "中小学",
}

export let CheckItemStatusEnum = {
    "0": "已启用",
    "1": "未启用"
}

export let CityEnum = {
    "0": "城市",
    "1": "农村"
}
export let GenderEnum = {
    "0": "男",
    "1": "女"
}

export let InputTypeEnum = {
    "0": "手输",
    "1": "选择",
    "2": "只读"
}
export let OrganizationTypeEnum = {
    "0": "体检机构",
    "1": "主管部门",
    "2": "平台运维",
    "3": "中小学",
    "4": "幼儿园"
}
export let StorageType = {
    "0": "本地磁盘",
    "1": "阿里云OSS",
    "2": "mongo"
}
export let hasSubEnum = {
    "0": "无",
    "1": "有",
}
export let statusType = {
    "0": "待发布",
    "1": "已发布",
    "2": "撤销",
    "3": "已过期"
}


