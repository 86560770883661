<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false
        :visible="true"
        center
        title="数据导入"
        width="45%"
        @close="close"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="学校:" prop="schoolId">
          <school-select v-model="form.schoolId"></school-select>
        </el-form-item>
        <el-form-item>
          <el-upload
              :action=actionPath

              :data="{organizationId:form.schoolId,filePath:'file'}"
              :limit='3'
              :on-error="handError"
              :on-success="handSuccess"
              accept=".xlsx,.xls"

              class="upload-demo"
              name="file">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xlsx/xls文件</div>
          </el-upload>
        </el-form-item>

      </el-form>


      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update('form')">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import SchoolSelect from "@/components/SchoolSelect";
import * as roleApi from "@/api/gen/roleApi"
import * as organizationApi from "@/api/gen/organizationApi"
import * as fileInfoApi from "@/api/gen/fileInfoApi"
import * as medicalInformationApi from "@/api/gen/medicalInformationApi"

export default {
  name: "importData",


  components: {SchoolSelect},

  data() {
    return {
      roleApi,
      organizationApi,
      form: {
        fileId: '',
        schoolId: '',
        organizationId: this.$store.getters.organizationId
      },

      visible:false,
      rules: {
        schoolId: [
          {required: true, message: '请选择学校', target: blur}
        ]
      }


    }
  },

  computed: {
    actionPath() {
      return process.env.VUE_APP_API + "/file/upload";
    },
  },
  mounted() {

  },
  methods: {
    close() {
      this.$emit("close")
    },
    async update() {

      await medicalInformationApi.importFromExcel(this.form)



      const res = await fileInfoApi.getById({id:this.form.fileId})
      console.log(res)

      this.$message.success('上传成功')
      this.$emit('close')
    },


    handSuccess(res, file) {
      this.form.fileId = res.data
    },


    handError() {
      this.$message.error('上传失败')
    }
  }
}
</script>

<style scoped>


</style>
