<template>
  <div>
    <el-dialog
        :show-close=true
        :visible="true"
        center
        width="70%"
        @close="close"
    >
      <div slot="title">
        <div>分配权限[{{ name }}]</div>
      </div>
      <el-form label-position="top left">
        <el-tabs v-model="activeIndex" @tab-click="tabClick">

          <el-tab-pane v-for="(item, index) in treeList" :key="item.id" :label="item.name" :name="index+''">
            <div style="display: flex; flex-wrap: wrap">
              <div v-for="(item2,index) in item.children"
                   :key="item2.id"
                   style="width: 33.3%; font-weight: 500; margin-bottom: 20px;"

              >
                <div style="margin-bottom: 10px">
                  <span style="margin: 0 20px 0 0;"> {{ item2.name }}</span>

                  <el-checkbox v-model="item2.checkAll"
                               :indeterminate="item2.isIndeterminate"
                               @change="handleCheckAllChange(item2.checkAll,index)">全选
                  </el-checkbox>
                </div>
                <el-checkbox-group v-model="item2.checked" @change="handleCheckItemsChange(item2.checked,index)">

                  <el-checkbox v-for="item3 in item2.children"
                               :key="item3.id"
                               :label="item3.code"

                               style="margin-right: 10px">
                    {{ item3.name }}
                  </el-checkbox>


                </el-checkbox-group>

              </div>
            </div>
          </el-tab-pane>


        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update">确 定</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as permissionApi from "@/api/gen/permissionApi";
import * as roleApi from "@/api/gen/roleApi";
import * as userApi from "@/api/gen/userApi";

export default {
  name: "edit",
  props: {
    row: {},
    roleDialog: {
      type: Boolean
    },


  },
  data() {
    return {
      name: this.$store.state.userInfo.user.name,
      form: {
        id: "",
        code: '',
        pageNo: 1,
        pageSize: 10000
      },
      activeIndex: '0',

      formList: [],

      model: [],
      treeList: [],

    }
  },
  computed: {
    allChecked() {
      let arr = [];
      this.$dfs(this.treeList, (node) => arr.push(...(node.checked || [])));
      return arr
    }
  },


  async mounted() {
    Object.assign(this.form, this.row)
    await this.list()
  },
  methods: {
    close() {
      this.$emit("close")
    },


    async list() {
      const res = (await permissionApi.list({pageSize: 1000000000}));


      this.formList = res.data
      let tmp = this.$buildTree(this.formList).reverse();


      this.$dfs(tmp, (v) => {
        v.checked = this._.intersection(this.form.dataPermissions || [], v.children.map(item => item.code))
        v.checkAll = false
        v.isIndeterminate = true


      });



      this.treeList = tmp.filter(x => x.code === 'dataPermission');

      console.log(this.treeList)


    },
    async update() {
      let obj = {
        id: this.form.id,
        dataPermissions: this.allChecked
      }
      await userApi.update(obj)
      this.$message.success("分配权限成功")
      this.$emit('close')
      this.$emit('update')
    },

    handleCheckAllChange(e, i) {
      let current = this.treeList[this.activeIndex].children[i]
      let arr = []
      let tmp = current.children

      if (e) {
        tmp.forEach((item, index) => {
          arr[index] = tmp[index].code
        })
      }
      current.checked = arr;
      current.isIndeterminate = false;


    },
    tabClick(e) {
      this.activeIndex = e.index
    },
    handleCheckItemsChange(e, i) {
      let current = this.treeList[this.activeIndex].children[i]
      let checkCount = e.length
      current.checkAll = checkCount === current.children.length
      current.isIndeterminate = checkCount > 0 && checkCount < current.children.length

    }
  }
}
</script>

<style scoped>

</style>